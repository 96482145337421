import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Class_Fee_Master from '../components/comp_class_fee_master';
import Comp_Edit_Student_Basic from '../components/comp_edit_student_basic';
import Comp_Edit_Student_Family from '../components/comp_edit_student_family';
import Comp_Edit_Student_Auth from '../components/comp_edit_student_auth';

import Comp_Search_Pan from '../components/comp_search_pan';
import Comp_Print_Report from '../components/comp_print_report';
import Comp_Edit_Account from '../ledger_component/comp_edit_account';
import Comp_View_Ent_Logs from '../components/comp_view_ent_logs';
import { Link } from 'react-router-dom';

let loginUser="";
let userObject="";
let companyName="";
let clsViewBillingTrukOut;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];
let _sumDebit=0;
let _sumCredit=0;
let _objAccess={};
class View_All_Payments extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          _objAccess=getAccessFunctions();
      }
      this.state={ stListTransactions:[],stEditAccount:{},stLogState:{}};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsViewBillingTrukOut=this;
      }

      setLogState(item){
        let map={"table_name":"account_inventory","table_id":item.account_inventory_id}
        clsViewBillingTrukOut.setState({stLogState:map});
      }


      deleteEntry(item){
        const isConfirmed = window.confirm("Are you sure you want to delete this item?");
        if(isConfirmed){

        
        let map=[];
        map.push({name:"curl_type",value:"delete_payments"});
        map.push({name:"res_func",value:"callBackrrr"});
        //map.push({name:"inventory_ledger_id",value:btoa("4")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"del_id",value:btoa(item["account_inventory_id"])});
        map["res_func"]="callBackrrr";
        map["curl_type"]="delete_payments";

        CallMapMethod_CBack(clsViewBillingTrukOut,map,function(data,msg){
          alert(msg);
          clsViewBillingTrukOut.getTruckProWise();

        })

      }

       

      }

      getTruckProWise(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"view_payment_entry"});
        map.push({name:"res_func",value:"callBackrrr"});
        //map.push({name:"inventory_ledger_id",value:btoa("4")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});

        if(document.getElementById("ser_party_info_id")!==null){
          map.push({name:"ser_party_info_id",value:$("#ser_party_info_id").val()});
        }
        if(document.getElementById("ser_item_id")!==null){
          map.push({name:"ser_item_id",value:$("#ser_item_id").val()});
        }
        if(document.getElementById("ser_inventory_ledger_id")!==null){
          map.push({name:"ser_inventory_ledger_id",value:$("#ser_inventory_ledger_id").val()});
        }
        if(document.getElementById("ser_transpaymentmode")!==null){
          map.push({name:"ser_transpaymentmode",value:$("#ser_transpaymentmode").val()});
        }
        if(document.getElementById("ser_from_date")!==null && document.getElementById("ser_to_date")){
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }


        
        // map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        // map.push({name:"ser_to_date",value:$("#ser_to_date").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_payment_entry";
        CallMapMethod_CBack(clsViewBillingTrukOut,map,function(data,msg){
          //alert(data);
          _sumCredit=0;
                      _sumDebit=0;
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
               
                let vNetDebit=0;
                let vNetCredit=0;
                for(let i=0;i<listData.length;i++){
                    // alert(listData[i]["paid_fee"]);
                    // break;
                  if(listData[i]["Debit"]!==null && listData[i]["Debit"]!=="null" && listData[i]["Debit"]!==""){
                    vNetDebit+=parseFloat(listData[i]["Debit"]);
                  }
                  
                  if(listData[i]["Credit"]!==null && listData[i]["Credit"]!=="null" && listData[i]["Credit"]!==""){
                    vNetCredit+=parseFloat(listData[i]["Credit"]);
                  }
                  
                }
                
                $(".outstanding_rpt").html(listData.length+" records found | Net Balance:<b>Rs. "+(vNetCredit-vNetDebit)+"-/</b> |");
                clsViewBillingTrukOut.setState({stListTransactions:listData},()=>{
                  //window.getPagination("#tbData");
                  clsViewBillingTrukOut.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
  
setEditData(item){
 // alert(JSON.stringify(item));
 //alert(item["reference_id"]);
clsViewBillingTrukOut.setState({stEditAccount:item},()=>{
  clsViewBillingTrukOut.forceUpdateHandler();
});
}


okSuccEntryLedg(data,msg){
  if(data==="001"){
    alert(msg);
    clsViewBillingTrukOut.getTruckProWise();
    //window.location.reload();

   
  }else{
    alert(msg);
    //Custom_Alert("Not Working",smg,false);
  }
  // alert(data);
}

      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getTruckProWise();
$('#divEditAccount').on('hidden.bs.modal', function () {
  clsViewBillingTrukOut.setState({stEditAccount:{"nn":""}});
})
//this.getMasters();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12 pb-2">
    <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        Search
      </button>
      <button class="btn btn-sm btn-danger ml-2"  type="button" data-bs-toggle="modal" data-bs-target="#divPrintReport">
        Export
      </button>
      <Link className="btn btn-sm btn-danger float-right" to={"../payment_entry"}>{"Add New Payment Entry"}</Link>
    </div>
  </div>

<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>View All Transaction</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Receipt/Trans. No.</th>
                      <th>Customer Name</th>
                      <th>Contact No</th>
                      <th>Ledger</th>
                      <th>Ref Inv No.</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Balance</th>
                      <th>Payment Mode</th>
                      <th>Narration</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{
                    if(index===0){
                      _sumCredit=0;
                      _sumDebit=0;
                    }
                    if(item["Credit"]!==null && item["Credit"]!=="null" && item["Credit"]!==""){
                      _sumCredit+=parseFloat(item["Credit"]);
                    }
                    if(item["Debit"]!==null && item["Debit"]!=="null" && item["Debit"]!==""){
                      _sumDebit+=parseFloat(item["Debit"]);
                    }
                    
                    
                      
                   
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item["Date"]}</td>
                        <td>{item["Receipt_No"]}</td>
                        <td>{item["Company Name"]}</td>
                        <td>{item["Contact No"]}</td>
                        <td>{item["Inventory Ledger"]}</td>
                        <td>{item["Invoice No"]}</td>
                        <td>{item["Credit"]}</td>
                        <td>{item["Debit"]}</td>
                        <td></td>
                        <td>{item["Payment_mode"]}</td>
                        <td>{item["Narration"]}</td>
                        
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    {/* <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divEditStudentBasic" href="javascript:void(0)">View Reference Invoice</a> */}
                    {(typeof _objAccess.can_edit!=="undefined") && (item["reference_id"]==="0" || item["reference_id"]===0)?<a class="dropdown-item" onClick={()=>this.setEditData(item)} data-bs-toggle="modal" data-bs-target="#divEditAccount" data-toggle="modal" data-target="#divEditAccount" href="javascript:void(0)">Edit Payment</a>:''}
                    {(typeof _objAccess.can_delete!=="undefined") && (item["reference_id"]==="0" || item["reference_id"]===0)?<a class="dropdown-item" onClick={()=>this.deleteEntry(item)} href="javascript:void(0)">Delete</a>:''}
                    {typeof _objAccess.access_log!=="undefined"?<a class="dropdown-item" onClick={()=>this.setLogState(item)} data-bs-toggle="modal" data-bs-target="#divViewEntryLogs" data-toggle="modal" data-target="#divViewEntryLogs" href="javascript:void(0)">View Logs</a>:''}
                    </div>
                  </div>
                       </td>
                    </tr>)
                  }))}
                  <tr>
                  <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>{_sumCredit}</b></td>
                        <td><b>{_sumDebit}</b></td>
                        <td><b>{_sumCredit-_sumDebit}</b></td>
                        <td></td>
                        <td></td>
                  </tr>
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Comp_View_Ent_Logs logUser={userObject} logState={this.state.stLogState} />

<Comp_Edit_Account logUser={userObject} 
dataAccounts={this.state.stEditAccount}
compTitle={"Edit Payment Entry"}
/>
<Comp_Print_Report ptrColumnHeader={["Date","Trans No.","Customer Name","Contact No","Ledger","Inv No","Credit","Debit","Balance","Payment Mode"]} 
  ptrColumnName={["Date","Receipt_No","Company Name","Contact No","Inventory Ledger","Invoice_No","Credit","Debit","","Payment_Mode"]} 
  lsTabelData={this.state.stListTransactions}
  
  totals={[{"col":"Credit","col_index":7,"total_value":0},
    {"col":"Debit","col_index":8,"total_value":0},
    {"col":"_substract","col_index":9,"total_value":0}
  ]}

  ptrTitle={"Transaction Summary"}
   logUser={userObject}
   
   />

<Alert_Content/>
<Footer/>
<Comp_Search_Pan onSearchFunc={this.getTruckProWise} 
searchTitle={"All Payment Ledger"}
searchOptions={{"company_name":"true",
 "inventory_ledger":"true",
//"item":"true",
//"site_name":"true",
//"vehicle_no":"true",
"trans_payment_mode":"true",
"payment_mode":"true",
"from_to_date":"true"}}
/>
   

          </div>)
      }
}
export default View_All_Payments;