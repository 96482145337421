import $, { extend } from 'jquery';
import React,{useEffect} from 'react';

function exportPdf() {
    var whe = window.innerHeight;
    var wwid = window.innerWidth;

    let data = document.getElementById("divPrintAllLedgerTransContent").innerHTML;
    /*
    alert(data);
    var doc = new DOMParser().parseFromString(data, "text/xml");

    alert(doc.getElementsByTagName("<div>")[1]);
    */
    var printWindow = window.open('', '', 'width=' + (wwid - 20) + ',height=' + (whe - 10) + '');
    printWindow.document.write('<html><head><title>Export Data</title>');
    //printWindow.document.write('<link rel="stylesheet" href="~/plugins/fontawesome-free/css/all.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css">');
    //printWindow.document.write('<link rel="stylesheet" href="~/dist/css/adminlte.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700">');
    printWindow.document.write('<style>table, th, td {border-bottom: 0.5px solid;}table{width:100%;border-spacing:0;}</style>');
    
    printWindow.document.write('</head><body >');
    printWindow.document.write(data);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    //alert(printWindow.document.getElementById("exp_body").innerHTML);
    //printWindow.document.getElementById("exp_body").removeAttribute("style");
    //delete cell from table
    // let tbList = printWindow.document.getElementById("ptrTable");
    // let tbRow = tbList.rows;
    // //alert(tbRow.length);
    // for (let i = 0; i < tbRow[0].cells.length; i++) {
    //     var str = tbRow[0].cells[i].innerHTML;
    //     //alert(str);
    //     if (str.search("Action") != -1) {
    //         for (var j = 0; j < tbRow.length; j++) {

    //             // Deleting the ith cell of each row
    //             tbRow[j].deleteCell(i);
    //         }
    //     }
    // }

    setTimeout(function () {
        printWindow.print();
    }, 500);
    return false;
}
export function Comp_Print_All_Ledger_Trans(props){
    let gbIndex=0;
    let gbBalance=0;
    //alert(JSON.stringify(props.lsOutsData));
    useEffect(()=>{
        // if(document.getElementById("exp_body")!==null){
        // let data = document.getElementById("exp_body").innerHTML;
        // $("#divPrintFContent").html(data);
        // }
        gbIndex=0;
        gbBalance=0;
    },[props.lsTabelData])
    return(<div className="modal fade bd-example-modal-xl"  id="divPrintAllLedgerTrans" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Export Report</h5>
          <a href={"javascript:void(0)"} onClick={()=>exportPdf()} className="btn btn-sm btn-danger float-right">Export/Print</a>

          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
     
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  
                      <div id="divPrintAllLedgerTransContent" className="card card-danger">
                            

                              <div id={"divPrintAllLedgerTransFContent"} className="card-body">
                                
                              <table id="ptrTable" class={"table"}>
                                <thead>
                                    <tr>
                                        <td colspan={props.ptrColumnHeader.length+1}>
                                            <center><b>{"Shree Vijay Construction"}</b>
                                            <br></br>
                                            {props.ptrTitle}
                                            <br></br>
                                            {"Date: "+$("#ser_from_date").val()+"-"+$("#ser_to_date").val()}
                                            
                                            </center>
                                            </td>
                                    </tr>
                                    <tr>
                                        <td  style={{"border-width":"0"}}>S.No.</td>
                                    {props.ptrColumnHeader.map((item,index)=>{
                                        return <td  style={{"border-width":"0"}}>{item}</td>
                                    })}
                                    </tr>
                                </thead>
                                <tbody>
                                    { 
                                    
                                    props.lsOutsData.map((item,index)=>{
                                        gbIndex+=index;
                                        gbBalance+=parseFloat(item["Credit"])-parseFloat(item["Debit"]);
                                        item["gb_balance"]=gbBalance;
                                        return <tr>
                                            <td  style={{"border-width":"0"}}>{gbIndex+1}</td>
                                            {props.ptrColumnName.map((col_item,col_index)=>{
                                                if(typeof props.openingDueCols[col_item]==="undefined"){
                                                    return <td  style={{"border-width":"0"}}></td>
                                                }else{
                                                    if(props.openingDueCols[col_item]!==""){
                                                        item[col_item]=props.openingDueCols[col_item];
                                                        //alert(props.openingDueCols[col_item]);
                                                    }
                                                    return <td  style={{"border-width":"0"}}>{item[col_item]}</td>
                                                }
                                                
                                            })}
                                        </tr>
                                    })}
                                    {props.lsTabelData.map((item,index)=>{
                                        //alert(item["children"])
                                        // if(index===0){
                                        //     gbBalance=0;
                                        // }
                                        gbBalance+=parseFloat(item["Credit"])-parseFloat(item["Debit"]);
                                        //item.push({"balance":gbBalance})
                                        item["gb_balance"]=gbBalance;
                                        //alert(JSON.stringify(item));
                                        return <><tr  style={{"border-width":"0"}}>
                                            <td  style={{"border-width":"0"}}>{index+1}</td>
                                            <td  style={{"border-width":"0"}}><b>{item["Date"]}</b></td>
                                            <td  style={{"border-width":"0"}}><b>{item["company_name"]}</b></td>
                                            <td  style={{"border-width":"0"}}><b>{item["contact_no"]}</b></td>
                                            <td  style={{"border-width":"0"}}><b>{item["invoice_no"]}</b></td>
                                            <td  style={{"border-width":"0"}}><b>{item["inventory_ledger"]}</b></td>
                                            {/* {props.ptrColumnName.map((col_name,col_index)=>{
                                                return <td>{item[col_name]}</td>
                                            })} */}
                                            
                                        </tr>
                                        {item["children"]!==null && item["children"]!=="null"?
                                        <tr>
                                            <td  style={{"border-width":"0"}}></td>
                                            <td  style={{"border-width":"0"}}></td>
                                            <td  style={{"border-width":"0"}} colspan={props.ptrColumnHeader.length-3}>
                                        <table  style={{"border-width":"0"}} class={"table"}>
                                            <tr  style={{"border-width":"0"}}>
                                                <td style={{"border-width":"0"}}>Tran.Date</td>
                                                <td style={{"border-width":"0"}}>Product</td>
                                                <td style={{"border-width":"0"}}>Unit</td>
                                                <td style={{"border-width":"0"}}>Qty</td>
                                                <td style={{"border-width":"0"}}>Rate</td>
                                                <td style={{"border-width":"0"}}>Amt</td>
                                            </tr>
                                        {JSON.parse(item["children"]).map((childItem,childIndex)=>{
                                            return <tr style={{"border-width":"0"}}>
                                                 <td style={{"border-width":"0"}}>
                                                    {childItem["date"]}
                                                </td>
                                                <td style={{"border-width":"0"}}>
                                                    {childItem["item_name"]}
                                                </td>
                                                <td style={{"border-width":"0px"}}>
                                                    {childItem["item_unit"]}
                                                </td>
                                                <td style={{"border-width":"0px"}}>
                                                    {childItem["quantity"]}
                                                </td>
                                                <td style={{"border-width":"0px"}}>
                                                    {childItem["rate"]}
                                                </td>
                                                <td style={{"border-width":"0px"}}>
                                                    {childItem["net_amount"]}
                                                </td>
                                            </tr>
                                        })}</table></td>
                                        <td  style={{"border-width":"0"}}></td>
                                        <td  style={{"border-width":"0"}}></td>
                                        <td  style={{"border-width":"0"}}></td>
                                        </tr>:''}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><b>{item["Credit"]}</b></td>
                                            <td><b>{item["Debit"]}</b></td>
                                            <td><b>{item["gb_balance"]}</b></td>

                                        </tr>
                                        </>
                                    })}
                                </tbody>
                             </table>
                                  
                                  
                              </div>
                      </div>
                  
              </div>
        </div>
       
        
      </div>
      </div>
      </div>)
}

export default Comp_Print_All_Ledger_Trans;