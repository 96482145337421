import $, { extend } from 'jquery';
import React,{useEffect} from 'react';

function exportPdf() {
    var whe = window.innerHeight;
    var wwid = window.innerWidth;

    let data = document.getElementById("divPrintInvoiceContent").innerHTML;
    /*
    alert(data);
    var doc = new DOMParser().parseFromString(data, "text/xml");

    alert(doc.getElementsByTagName("<div>")[1]);
    */
    var printWindow = window.open('', '', 'width=' + (wwid - 20) + ',height=' + (whe - 10) + '');
    printWindow.document.write('<html><head><title>Export Data</title>');
    //printWindow.document.write('<link rel="stylesheet" href="~/plugins/fontawesome-free/css/all.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700">');
    printWindow.document.write('<style>table, th, td {border: 0.5px solid;}table{width:100%;border-spacing:0,border:1px solid #000;}</style>');
    printWindow.document.write('<style>table{ border: 2px solid #000;}</style>');

    
    printWindow.document.write('</head><body >');
    printWindow.document.write(data);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    //alert(printWindow.document.getElementById("exp_body").innerHTML);
    //printWindow.document.getElementById("exp_body").removeAttribute("style");
    //delete cell from table
    // let tbList = printWindow.document.getElementById("ptrTable");
    // let tbRow = tbList.rows;
    // //alert(tbRow.length);
    // for (let i = 0; i < tbRow[0].cells.length; i++) {
    //     var str = tbRow[0].cells[i].innerHTML;
    //     //alert(str);
    //     if (str.search("Action") != -1) {
    //         for (var j = 0; j < tbRow.length; j++) {

    //             // Deleting the ith cell of each row
    //             tbRow[j].deleteCell(i);
    //         }
    //     }
    // }

    setTimeout(function () {
        printWindow.print();
    }, 500);
    return false;
}
export function Comp_Print_Invoice(props){
   // alert(JSON.stringify(props.lsOldDue));
    useEffect(()=>{
        // if(document.getElementById("exp_body")!==null){
        // let data = document.getElementById("exp_body").innerHTML;
        // $("#divPrintFContent").html(data);
        // }

    },[props.lsTabelData])
    return(<div className="modal fade bd-example-modal-xl"  id="divPrintInvoice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Export Report</h5>
          <a href={"javascript:void(0)"} onClick={()=>exportPdf()} className="btn btn-sm btn-danger float-right">Export/Print</a>

          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
     
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  
                      <div id="divPrintInvoiceContent" className="card card-danger">
                            

                              <div id={"divPrintInvoiceFContent"} className="card-body">
                                
                              <table id="ptrTable" class={"table"}>
                                <thead>
                                    <tr>
                                        <td colspan={(props.ptrColumnHeader.length+1)}>
                                            <div class="row">
                                            <div class="col-md-6" style={{"width":"50%"}}>
                                            <b>From:</b><br></br>
                                            <b>{"Shree Vijay Construction"}</b>
                                            <br></br>
                                            Ujjain -456010<br></br>
                                            Mob. 9174004521, 9174004527
                                            {props.ptrTitle}
                                            <br></br>
                                            </div>
                                           

                                            <div class="col-md-6" style={{"width":"50%","text-align":"right"}}>
                                            <b>To:</b><br></br>
                                                {props.lsInvoiceLable.map((item,index)=>{
                                                    return <>
                                                    {"M/s "+item["Company Name"]}<br></br>
                                                    {"Contact No:"+item["Contact No"]}<br></br>
                                                    {"Address:"+item["Address"]}
                                                    </>
                                                })}
                                            
                                            <br></br>
                                            {props.ptrTitle}
                                            </div>

                                            </div>
                                            {props.lsInvoiceLable.map((item,index)=>{
                                            return <div class="row" style={{"border-top":"1px solid #000","padding-top":""}}>
                                                <div class="col-md-6" style={{"width":"50%"}}><b>Invoice No:</b>{item["Invoice No"]+"/"+item["Invoice Type"]}</div>
                                                
                                                <div class="col-md-6" style={{"width":"50%","text-align":"right"}}><b>Invoice Date:{item["date"]}</b></div>
                                            </div>
                                            })}
                                        </td>
                                    </tr>
                                 
                                    <tr>
                                        <td>S.No.</td>
                                    {props.ptrColumnHeader.map((item,index)=>{
                                        return <td>{item}</td>
                                    })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.lsTabelData.map((item,index)=>{
                                        return <tr>
                                            <td>{index+1}</td>
                                            {props.ptrColumnName.map((col_name,col_index)=>{
                                                return <td>{item[col_name]}</td>
                                            })}
                                        </tr>
                                    })}
                                    {props.lsInvoiceLable.map((item,index)=>{
                                        let netPayable=parseFloat(item["Net Amount"]);
                                        return <>
                                        {/* <tr>
                                        <td style={{"border":"0px"}} colspan={props.ptrColumnHeader.length+1}></td>
                                        </tr> */}
                                        <tr>
                                            <td style={{"border":"0px"}} colspan={props.ptrColumnHeader.length-2}></td>
                                            <td colspan="2">Total Amount</td>
                                            <td>{item["Amount"]}</td>
                                        </tr>
                                        <tr>
                                            <td style={{"border":"0px"}} colspan={props.ptrColumnHeader.length-2}></td>
                                            <td colspan="2">Discount {item["discount_per"]}%</td>
                                            <td>{item["Discount"]}</td>
                                        </tr>
                                        <tr>
                                        <td style={{"border":"0px"}} colspan={props.ptrColumnHeader.length-2}></td>
                                            <td colspan="2">Net Invoice Amount</td>
                                            <td>{item["Net Amount"]}</td>
                                        </tr>
                                        {props.lsOldDue.length>0?<>
                                        <tr>
                                            <td style={{"border":"0px"}} colspan={props.ptrColumnHeader.length-2}></td>
                                            <td colspan="2">Old Dues</td>
                                            <td><b>{parseFloat(props.lsOldDue[0]["Credit"])-parseFloat(props.lsOldDue[0]["Debit"])}</b></td>
                                        </tr>
                                        
                                        </>:''
                                        }
                                        <tr>
                                        <td style={{"border":"0px"}} colspan={props.ptrColumnHeader.length-2}></td>
                                            <td colspan="2">Net Payable</td>
                                            <td><b>{props.lsOldDue.length>0?
                                            netPayable+(parseFloat(props.lsOldDue[0]["Credit"])-parseFloat(props.lsOldDue[0]["Debit"])):netPayable}</b></td>
                                        </tr>
                                        
                                        </>
                                    })}
                                </tbody>
                             </table>
                                  
                                  
                              </div>
                      </div>
                  
              </div>
        </div>
       
        
      </div>
      </div>
      </div>)
}

export default Comp_Print_Invoice;