import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Class_Fee_Master from '../components/comp_class_fee_master';
import Comp_Edit_Student_Basic from '../components/comp_edit_student_basic';
import Comp_Edit_Student_Family from '../components/comp_edit_student_family';
import Comp_Edit_Student_Auth from '../components/comp_edit_student_auth';

import Comp_Search_Pan from '../components/comp_search_pan';
import Comp_Print_Account_Trans from '../components/comp_print_account_trans';
import Comp_Print_All_Ledger_Trans from '../components/comp_print_all_ledger_trans';
import Comp_Print_Report from '../components/comp_print_report';
import { Link } from 'react-router-dom';

let loginUser="";
let userObject="";
let companyName="";
let clsViewBillingTrukOut;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];

let _sumCredit=0;
let _sumDebit=0;
let _sumBalance=0;
class View_PartyWise_OutStanding extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stListTransactions:[],stListPartyALedgerTrans:[],stListPartyTrans:[],stListPartyOldOuts:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsViewBillingTrukOut=this;
      }

      getAllLedgersAccountByPartyId(item){
        let map=[];

        map.push({name:"curl_type",value:"view_party_all_ledger"});
        map.push({name:"res_func",value:"callBackrrr"});
        //map.push({name:"inventory_ledger_id",value:btoa("4")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"party_info_id",value:btoa(item["party_info_id"])});
        map.push({name:"acc_inv_status",value:"allow"});
        if(document.getElementById("ser_from_date")!==null && document.getElementById("ser_to_date")){
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
          map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }
        if(document.getElementById("ser_inventory_ledger_id")!==null){
          map.push({name:"ser_inventory_ledger_id",value:$("#ser_inventory_ledger_id").val()});
        }
        
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_party_all_ledger";
        CallMapMethod_CBack(clsViewBillingTrukOut,map,function(data,msg){
          //alert("i min data"+data);
          if(data!=="0"){
            let lsData=JSON.parse(data);
            //alert(JSON.stringify(lsData["old_outs"]));
            clsViewBillingTrukOut.setState({stListPartyALedgerTrans:lsData["party_trans"],stListPartyOldOuts:lsData["old_outs"]});
          }
        })
      }

      getPaymentEntryByPartyId(item){
        // alert(JSON.stringify(item));
        // return;
        let map=[];

        map.push({name:"curl_type",value:"view_partywise_trans"});
        map.push({name:"res_func",value:"callBackrrr"});
        //map.push({name:"inventory_ledger_id",value:btoa("4")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"party_info_id",value:btoa(item["party_info_id"])});
        
        if(document.getElementById("ser_from_date")!==null && document.getElementById("ser_to_date")){
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
          map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }
        if(document.getElementById("ser_inventory_ledger_id")!==null){
          map.push({name:"ser_inventory_ledger_id",value:$("#ser_inventory_ledger_id").val()});
        }
        
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_partywise_trans";
        CallMapMethod_CBack(clsViewBillingTrukOut,map,function(data,msg){
          //alert(data);
          if(data!=="0"){
            let lsData=JSON.parse(data);
            //alert(JSON.stringify(lsData["old_outs"]));
            clsViewBillingTrukOut.setState({stListPartyTrans:lsData["party_trans"],stListPartyOldOuts:lsData["old_outs"]});
          }
        })

      }



      getTruckProWise(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"view_payment_entry"});
        map.push({name:"res_func",value:"callBackrrr"});
        //map.push({name:"inventory_ledger_id",value:btoa("4")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"outstanding_rpt", value:"1"});//for outstadning only
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});

        if(document.getElementById("ser_party_info_id")!==null){
          map.push({name:"ser_party_info_id",value:$("#ser_party_info_id").val()});
        }
        if(document.getElementById("ser_item_id")!==null){
          map.push({name:"ser_item_id",value:$("#ser_item_id").val()});
        }
        if(document.getElementById("ser_inventory_ledger_id")!==null){
          map.push({name:"ser_inventory_ledger_id",value:$("#ser_inventory_ledger_id").val()});
        }
        if(document.getElementById("ser_from_date")!==null && document.getElementById("ser_to_date")){
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }
        
        // map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        // map.push({name:"ser_to_date",value:$("#ser_to_date").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_payment_entry";
        CallMapMethod_CBack(clsViewBillingTrukOut,map,function(data,msg){
          //alert(data);
          _sumCredit=0;
          _sumDebit=0;
          _sumBalance=0;
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
               
                let vNetDebit=0;
                let vNetCredit=0;
                for(let i=0;i<listData.length;i++){
                    // alert(listData[i]["paid_fee"]);
                    // break;
                 
                  vNetDebit+=parseFloat(listData[i]["Debit"]);
                  vNetCredit+=parseFloat(listData[i]["Credit"]);
                }
                
                $(".outstanding_rpt").html(listData.length+" records found | Net Balance:<b>Rs. "+(vNetDebit-vNetCredit)+"-/</b> |");
                clsViewBillingTrukOut.setState({stListTransactions:listData},()=>{
                  //window.getPagination("#tbData");
                  clsViewBillingTrukOut.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
  


      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getTruckProWise();
//this.getMasters();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12 pb-2">
    <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        Search
      </button>
      <button class="btn btn-sm btn-danger ml-2"  type="button" data-bs-toggle="modal" data-bs-target="#divPrintReport">
        Export
      </button>

      <Link className="btn btn-sm btn-danger float-right" to={"../payment_entry"}>{"Add New Payment Entry"}</Link>
    </div>
  </div>

<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>View PartyWise Outstanding</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Trans. No.</th>
                      <th>Customer Name</th>
                      <th>Contact No</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Balance</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{
                      if(index===0){
                        _sumCredit=0;
                        _sumDebit=0;
                        _sumBalance=0;
                      }
                      _sumCredit+=parseFloat(item["Credit"]);
                      _sumDebit+=parseFloat(item["Debit"]);
                      _sumBalance=_sumCredit-_sumDebit;
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item["Date"]}</td>
                        <td>{item["Receipt_No"]}</td>
                        <td>{item["Company Name"]}</td>
                        <td>{item["Contact No"]}</td>
                        <td>{item["Credit"]}</td>
                        <td>{item["Debit"]}</td>
                        <td><b>{parseFloat(item["Credit"])-parseFloat(item["Debit"])}</b></td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    <a class="dropdown-item" onClick={()=>this.getPaymentEntryByPartyId(item)} data-bs-toggle="modal" data-bs-target="#divPrintAccTrans" data-toggle="modal" data-target="#divPrintAccTrans" href="javascript:void(0)">Check Transaction</a>
                    <a class="dropdown-item" onClick={()=>this.getAllLedgersAccountByPartyId(item)} data-bs-toggle="modal" data-bs-target="#divPrintAllLedgerTrans" data-toggle="modal" data-target="#divPrintAllLedgerTrans"   href="javascript:void(0)">All Ledgers Report</a>
                    </div>
                  </div>
                       </td>
                    </tr>)
                  }))}
                  <tr>
                    
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>{_sumCredit}</b></td>
                    <td><b>{_sumDebit}</b></td>
                    <td><b>{_sumBalance}</b></td>
                    <td></td>

                    </tr>
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_Print_Report ptrColumnHeader={["Date","Customer Name","Contact No","Credit","Debit","Balance"]} 
  ptrColumnName={["Date","Company Name","Contact No","Credit","Debit",""]} 
  lsTabelData={this.state.stListTransactions}
  
  totals={[{"col":"Credit","col_index":4,"total_value":0},
    {"col":"Debit","col_index":5,"total_value":0},
    {"col":"_substract","col_index":6,"total_value":0}
  ]}

  ptrTitle={"Transaction Summary"}
   logUser={userObject}
   
   />

<Comp_Search_Pan onSearchFunc={this.getTruckProWise} 
searchTitle={"Search PartyWise Outstanding"}
searchOptions={{"company_name":"true",
"inventory_ledger":"true",
//"item":"true",
//"site_name":"true",
//"vehicle_no":"true",
//"payment_mode":"true",
"from_to_date":"true"}}
/>
<Comp_Print_Account_Trans 
ptrColumnHeader={["Date","A/c No","A/c Name","Contact No","Ledger","Credit","Debit","Balance"]} 
ptrColumnName={["Date","A/c No","Company Name","Contact No","Inventory Ledger","Credit","Debit","gb_balance"]} 
lsTabelData={this.state.stListPartyTrans}
lsOutsData={this.state.stListPartyOldOuts}
openingDueCols={{"A/c No":"","Company Name":"","Contact No":"","Inventory Ledger":"Opening Balance","Credit":"","Debit":"","gb_balance":""}}
/>

<Comp_Print_All_Ledger_Trans
ptrColumnHeader={["Date","A/c Name","Contact No","Inv. No","Ledger","Credit","Debit","Balance"]} 
ptrColumnName={["Date","company_name","Contact No","invoice_no","inventory_ledger","Credit","Debit","gb_balance"]} 
lsTabelData={this.state.stListPartyALedgerTrans}
lsOutsData={this.state.stListPartyOldOuts}
openingDueCols={{"A/c No":"","Company Name":"","Contact No":"","Inventory Ledger":"Opening Balance","Credit":"","Debit":"","gb_balance":""}}
/>
   
    
          </div>)
      }
}
export default View_PartyWise_OutStanding;