import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import {Comp_Print_Invoice} from '../components/comp_print_invoice';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Search_Pan from '../components/comp_search_pan';
import Comp_Print_Report from '../components/comp_print_report';
import Comp_View_Ent_Logs from '../components/comp_view_ent_logs';

// edit compoent import
// import Comp_Edit_Truck_Out from '../ledger_component/comp_edit_truck_out';
// import Comp_Add_Trans_Item from '../components/comp_add_trans_item';


import { Link } from 'react-router-dom';


let loginUser="";
let userObject="";
let companyName="";
let clsViewBillingTrukOut;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];

let _sumAmount=0;
let _sumNetAmount=0;
let _sumDiscount=0;
let _objAccess={};
class View_Crushing_Bills extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          _objAccess=getAccessFunctions();
      }
      this.state={ stListTransactions:[],stPtrInvoice:[],strPtrProduct:[],strPtrOldDue:[],
        stInvoiceHeader:[],stInvoiceColumns:[],stLogState:{}
      };
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsViewBillingTrukOut=this;
      }
      setLogState(item){
        let map={"table_name":"invoice","table_id":item.invoice_id}
        clsViewBillingTrukOut.setState({stLogState:map});
      }


      setInvoiceHeaders(item){
        
        if(item["Invoice Type"]==="Truck Out"){
          clsViewBillingTrukOut.setState({stInvoiceHeader:["Date","RST No","Vehicle","Item","Unit","Quantity","Rate","Sub Total","Royalty No","Royalty Rate","Royalty Amount","Net Amount"],
            stInvoiceColumns:["Date","col1","exp","Item","Item Unit","Quantity","Rate","Sub Total","col2","bags","Pack","Amount"]
          })
        }else if(item["Invoice Type"]==="TruckOut Billing"){
          clsViewBillingTrukOut.setState({stInvoiceHeader:["Date","RST No","Vehicle","Item","Unit","Quantity","Rate","Sub Total","Royalty No","Royalty Rate","Royalty Amount","Net Amount"],
            stInvoiceColumns:["Date","col1","exp","Item","Item Unit","Quantity","Rate","Sub Total","col2","bags","Pack","Amount"]
          })
        }else if(item["Invoice Type"]==="Blasting"){
          clsViewBillingTrukOut.setState({stInvoiceHeader:["Item","Unit","Person Name","Contact No","No.of Holes","Size/Hole","Quantity","Rate","Sub Total","Net Amount"],
            stInvoiceColumns:["Item","Item Unit","make","batch","Bags","Pack","Quantity","Rate","Sub Total","Amount"]
          });
        }else if(item["Invoice Type"]==="Drilling"){
          clsViewBillingTrukOut.setState({stInvoiceHeader:["Item","Unit","Person Name","Contact No","No.of Holes","Size/Hole","Quantity","Rate","Sub Total","Net Amount"],
            stInvoiceColumns:["Item","Item Unit","make","batch","Bags","Pack","Quantity","Rate","Sub Total","Amount"]
          });
        }else if(item["Invoice Type"]==="Crushing"){
          clsViewBillingTrukOut.setState({stInvoiceHeader:["Item","Unit","RST No.","G.Weight(Ton)","Tare Wight(Ton)","Net Wight(Meter)","Net Wight(Ton)","Vehicle No","Vehicle Name"],
            stInvoiceColumns:["Item","Item Unit","col1","Bags","Pack","free_goods","Quantity","exp","mfg"]
          });
        }
        else if(item["Invoice Type"]==="Crushing Bill"){
            clsViewBillingTrukOut.setState({stInvoiceHeader:["Item","Unit","RST No.","Vehicle No","Vehicle Name","G.Weight(Ton)","Tare Wight(Ton)","Net Wight(Meter)","Net Wight(Ton)","Rate","Sub Total","Amount"],
              stInvoiceColumns:["Item","Item Unit","col1","exp","mfg","Bags","Pack","free_goods","Quantity","Rate","Sub Total","Amount"]
            });
        }else if(item["Invoice Type"]==="Fuel In"){
          clsViewBillingTrukOut.setState({stInvoiceHeader:["Item","Unit","Quantity","Rate","Amount"],
            stInvoiceColumns:["Item","Item Unit","Quantity","Rate","Amount"]
          });
        }else if(item["Invoice Type"]==="Fuel Out"){
          clsViewBillingTrukOut.setState({stInvoiceHeader:["Item","Unit","Vehicle","Vehicle No","Person Name","Person Contact","Quantity","Rate","Amount"],
            stInvoiceColumns:["Item","Item Unit","exp","mfg","make","batch","Quantity","Rate","Amount"]
          });
        }
      }



      getBillPrint(item){
        clsViewBillingTrukOut.setInvoiceHeaders(item);

        var map=[];
        map.push({name:"curl_type",value:"printInvoice"});
        map.push({name:"res_func",value:"callBackrrr"});
       
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"invoice_id",value:btoa(item.invoice_id)});

        
        
        
        map["res_func"]="callBackrrr";
        map["curl_type"]="printInvoice";
        CallMapMethod_CBack(clsViewBillingTrukOut,map,function(data,msg){
          //alert(data);
          if(data!=="0"){
            let lsData=JSON.parse(data);
            //alert(JSON.stringify(lsData["purchase_item"]));
            clsViewBillingTrukOut.setState({stPtrInvoice:lsData["invoice"],
              strPtrProduct:lsData["purchase_item"],strPtrOldDue:lsData["old_trans"]
            })
          }
        })

      }



      getTruckProWise(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"view_invoice"});
        map.push({name:"res_func",value:"callBackrrr"});
        //map.push({name:"inventory_ledger_id",value:btoa("4")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});
        map.push({name:"inventory_ledger_id",value:btoa("12")})

        if(document.getElementById("ser_party_info_id")!==null){
          map.push({name:"ser_party_info_id",value:$("#ser_party_info_id").val()});
        }
        if(document.getElementById("ser_item_id")!==null){
          map.push({name:"ser_item_id",value:$("#ser_item_id").val()});
        }
        if(document.getElementById("ser_from_date")!==null && document.getElementById("ser_to_date")){
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }
        if(document.getElementById("ser_inventory_ledger_id")!==null){
          map.push({name:"ser_inventory_ledger_id",value:$("#ser_inventory_ledger_id").val()});
        }
        
        if(document.getElementById("ser_payment_mode")!==null){
          map.push({name:"ser_payment_mode",value:$("#ser_payment_mode").val()});
        }
        
        // map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        // map.push({name:"ser_to_date",value:$("#ser_to_date").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_invoice";
        CallMapMethod_CBack(clsViewBillingTrukOut,map,function(data,msg){
          //alert(data);
          _sumAmount=0;
          _sumNetAmount=0;
          _sumDiscount=0;
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
               
                let vNetAmount=0;
                for(let i=0;i<listData.length;i++){
                  if(listData[i]["Net Amount"]!==null && listData[i]["Net Amount"]!=="null" && listData[i]["Net Amount"]!=="")
                  vNetAmount+=parseFloat(listData[i]["Net Amount"]);
                }
                
                $(".outstanding_rpt").html(listData.length+" records found | Net Total Amt:<b>"+vNetAmount+"</b> |");
                clsViewBillingTrukOut.setState({stListTransactions:listData},()=>{
                  //window.getPagination("#tbData");
                  clsViewBillingTrukOut.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
  


      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getTruckProWise();
//this.getMasters();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12 pb-2">
    <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        Search
      </button>
    <button class="btn btn-sm btn-danger ml-2"  type="button" data-bs-toggle="modal" data-bs-target="#divPrintReport">
        Export
      </button>
      <Link className="btn btn-sm btn-danger float-right" to={"../ledger/create_billing"}>{"Add New Billing"}</Link>
    </div>
  </div>

<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>View Billing/Receipt</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Receipt No.</th>
                      <th>Customer Name</th>
                      <th>Ledger</th>
                      <th>Amount</th>
                      <th>Discount(%)</th>
                      <th>Discount Amt</th>
                      <th>Net Amount</th>
                      <th>Payment Mode</th>
                      {/* <th>Payment Status</th> */}
                      {/* <th>Status</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{

if(index==0){
  _sumAmount=0;
  _sumNetAmount=0;
  _sumDiscount=0;
}
if(item["Amount"]!==null && item["Amount"]!=="null" && item["Amount"]!==""){
  _sumAmount+=parseFloat(item["Amount"]);
}
if(item["Discount"]!==null && item["Discount"]!=="null" && item["Discount"]!==""){
  _sumDiscount+=parseFloat(item["Discount"]);
}
if(item["Net Amount"]!==null && item["Net Amount"]!=="null" && item["Net Amount"]!==""){
  _sumNetAmount+=parseFloat(item["Net Amount"]);
}
  
                   
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item["date"]}</td>
                        <td>{item["Invoice No"]}</td>
                        <td>{item["Company Name"]}</td>
                        <td>{item["Invoice Type"]}</td>
                        <td>Rs.{item["Amount"]}</td>
                        <td>{item["discount_per"]}</td>
                        <td>Rs.{item["Discount"]}</td>
                        <td>Rs.<b>{item["Net Amount"]}</b></td>
                        <td>{item["payment_mode"]}</td>
                        {/* <td>{item["payment_status"]}</td> */}
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    {/* {typeof _objAccess.can_edit?<a class="dropdown-item" onClick={()=>this.getBillPrint(item)} data-bs-toggle="modal" data-bs-target="#divPrintInvoice" data-toggle="modal"  data-target="#divPrintInvoice" href="javascript:void(0)">View/Edit</a>:''} */}
                     <a class="dropdown-item" onClick={()=>this.getBillPrint(item)} data-bs-toggle="modal" data-bs-target="#divPrintInvoice" data-toggle="modal"  data-target="#divPrintInvoice" href="javascript:void(0)">Print</a>
                     {typeof _objAccess.access_log!=="undefined"?<a class="dropdown-item" onClick={()=>this.setLogState(item)} data-bs-toggle="modal" data-bs-target="#divViewEntryLogs" data-toggle="modal" data-target="#divViewEntryLogs" href="javascript:void(0)">View Logs</a>:''}
                    </div>
                  </div>
                       </td>
                    </tr>)
                  }))}
                  <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Rs.{_sumAmount}</td>
                        <td></td>
                        <td>Rs.{_sumDiscount}</td>
                        <td>Rs.{_sumNetAmount}</td>
                        <td></td>
                        <td></td>
                        {/* <td></td> */}
                        </tr>
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
{/* Import All Available Edit Component */}
<Comp_View_Ent_Logs logUser={userObject} logState={this.state.stLogState} />

<Comp_Print_Invoice ptrColumnHeader={this.state.stInvoiceHeader} 
  ptrColumnName={this.state.stInvoiceColumns}
  lsTabelData={this.state.strPtrProduct}
  lsInvoiceLable={this.state.stPtrInvoice}
  lsOldDue={this.state.strPtrOldDue}
  logUser={userObject}
 />

<Comp_Print_Report ptrColumnHeader={["Date","Invoice No.","Customer Name","Ledger","Amount","Disc(%)","Discount","Net Amount","Payment Mode"]} 
  ptrColumnName={["date","Invoice No","Company Name","Invoice Type","Amount","discount_per","Discount","Net Amount","payment_mode"]} 
  lsTabelData={this.state.stListTransactions}

  totals={[{"col":"Amount","col_index":5,"total_value":0},
    {"col":"Discount","col_index":7,"total_value":0},
    {"col":"Net Amount","col_index":8,"total_value":0}
  ]}

  ptrTitle={"Billing/Receipt"}
   logUser={userObject}/>
<Alert_Content/>
<Footer/>
<Comp_Search_Pan onSearchFunc={this.getTruckProWise} 
searchTitle={"Search Receipt/Billing"}
searchOptions={{"company_name":"true",
// "inventory_ledger":"true",
//"item":"true",
//"site_name":"true",
//"vehicle_no":"true",
"payment_mode":"true",
"from_to_date":"true"}}
/>


   
    
          </div>)
      }
}
export default View_Crushing_Bills;