import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Class_Fee_Master from '../components/comp_class_fee_master';
import Comp_Edit_Student_Basic from '../components/comp_edit_student_basic';
import Comp_Edit_Student_Family from '../components/comp_edit_student_family';
import Comp_Edit_Student_Auth from '../components/comp_edit_student_auth';

import Comp_Search_Pan from '../components/comp_search_pan';
import Comp_Edit_Crushing from '../ledger_component/comp_edit_crushing';
import { Link } from 'react-router-dom';
import listData from '../function_lib/customeData';

import Comp_Print_Report from '../components/comp_print_report';
import Comp_View_Ent_Logs from '../components/comp_view_ent_logs';

let loginUser="";
let userObject="";
let companyName="";
let clsViewCrushing;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];
let _objAccess={};
class View_Crushing extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          _objAccess=getAccessFunctions();
      }
      this.state={ stListTransactions:[],stInvoice:{},stPtrInvoice:[],stPtrProduct:[],stPtrPayment:[],
      stEditInvoice:[],stEditProduct:[],stEditPayment:[],stOutStanding:[],stLogState:{}};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsViewCrushing=this;
      }
      setLogState(item){
        let map={"table_name":"invoice","table_id":item.invoice_id}
        clsViewCrushing.setState({stLogState:map});
      }

      
      getEditInvoice(item){
        
        let map=[];
        map.push({name:"curl_type",value:"getInvoice"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"inventory_ledger_id",value:btoa("2")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"invoice_id",value:btoa(item.invoice_id)})
        map["res_func"]="callBackrrr";
        map["curl_type"]="getInvoice";
        
        CallMapMethod_CBack(clsViewCrushing,map,function(data,msg){
          //alert(data);
          if(data!=="0"){
            let lsData=JSON.parse(data);
            clsViewCrushing.setState({stEditInvoice:lsData["invoice"],stEditProduct:lsData["purchase_item"],
              stEditPayment:lsData["account_inventory"]
            })
          }
        })
      }




      getTruckProWise(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"view_itemwisereport_new"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"inventory_ledger_id",value:btoa("8")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});

        
        if(document.getElementById("ser_party_info_id")!==null){
          map.push({name:"ser_party_info_id",value:$("#ser_party_info_id").val()});
        }
        if(document.getElementById("ser_item_id")!==null){
          map.push({name:"ser_item_id",value:$("#ser_item_id").val()});
        }
        if(document.getElementById("ser_vehicle")!==null){
          //alert("i m in vehicle "+$("#ser_vehicle").val());
          map.push({name:"ser_vehicle",value:$("#ser_vehicle").val()});
        }
        if(document.getElementById("ser_from_date")!==null && document.getElementById("ser_to_date")){
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }
        
        // map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        // map.push({name:"ser_to_date",value:$("#ser_to_date").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_itemwisereport_new";
        CallMapMethod_CBack(clsViewCrushing,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
              let jsData=JSON.parse(data);
              let listData=jsData["DataList"]
              
                var strData="";
               
                let vNetAmount=0;
                for(let i=0;i<listData.length;i++){
                    // alert(listData[i]["paid_fee"]);
                    // break;
                 
                  vNetAmount+=parseFloat(listData[i]["Amount"]);
                }
                
                $(".outstanding_rpt").html(listData.length+" records found | Net Total Amt:<b>"+vNetAmount+"</b> |");
                clsViewCrushing.setState({stListTransactions:listData,stOutStanding:jsData["OutStanding"]},()=>{
                  //window.getPagination("#tbData");
                  clsViewCrushing.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
  

      okSuccEditInv(data,smg)
      {
        if(data==="001"){
            alert("Record Saved Successfully");
            window.location.reload();
            }else{
              alert(smg);
            }
      } 

      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);

this.clsBtnBreakUpsClick =new listData("_btnFeeBreakdown", "hellow");
this.clsBtnBreakUpsClick.canEdit=false;
this.clsBtnBreakUpsClick.setOnChangeEvent("fee_breakdown",this.clsBtnBreakUpsClick);


this.getTruckProWise();
//this.getMasters();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12 pb-2">
    <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        Search
      </button>
      <button class="btn btn-sm btn-danger ml-2"  type="button" data-bs-toggle="modal" data-bs-target="#divPrintReport">
        Export
      </button> 
      <Link className="btn btn-sm btn-danger float-right" to={"../ledger/create_crushing"}>{"Add New Crushing"}</Link>
      {/* <a className="btn btn-sm btn-danger float-right" href="../create_blasting">Add New Blasting</a> */}
    </div>
    
  </div>

<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>View Crushing(Product Wise)</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Receipt No.</th>
                      <th>Customer Name</th>
                      <th>RST No.</th>
                      <th>Product</th>
                      <th>Unit</th>
                      <th>Gross Weight</th>
                      <th>Tare Weight</th>
                      <th>Net Weight</th>
                      <th>Net Weight(Ton)</th>
                      <th>Vehicle No</th>
                      <th>Vehicle Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{
                   
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item["Date"]}</td>
                        <td>{item["Invoice No"]}</td>
                        <td>{item["Company Name"]}</td>
                        <td>{item["col1"]}</td>
                        <td>{item["Item"]}</td>  
                        <td>{item["Item Unit"]}</td>
                        <td>{item["Bags"]}</td>
                        <td>{item["Pack"]}</td>
                        <td>{item["free_goods"]}</td>
                        <td>{item["Quantity"]}</td>
                        <td>{item["exp"]}</td>
                        <td>{item["mfg"]}</td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    {typeof _objAccess.can_edit?<a class="dropdown-item" onClick={()=>this.getEditInvoice(item)} data-bs-toggle="modal" data-bs-target="#divEditCrushing" data-toggle="modal" data-target="#divEditCrushing" href="javascript:void(0)">View/Edit</a>:''} 
                    {/* <a class="dropdown-item" onClick={()=>this.getEditInvoice(item)} data-bs-toggle="modal" data-bs-target="#divEditCrushing" data-toggle="modal"  data-target="#divEditCrushing" href="javascript:void(0)">Print</a> */}
                    {typeof _objAccess.access_log!=="undefined"?<a class="dropdown-item" onClick={()=>this.setLogState(item)} data-bs-toggle="modal" data-bs-target="#divViewEntryLogs" data-toggle="modal" data-target="#divViewEntryLogs" href="javascript:void(0)">View Logs</a>:''}
                    </div>
                  </div>
                       </td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr>
                      <td><b>Product</b></td>
                      <td><b>Unit</b></td>
                      <td><b>Net Quantity</b></td>
                      {/* <td><b>Net Amount</b></td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.stOutStanding.map((item,index)=>{
                      return <tr>
                        <td>{item["Item"]}</td>
                        <td>{item["Item Unit"]}</td>
                        <td>{item["Quantity"]}</td>
                        {/* <td>{item["Sub Total"]}</td> */}
                      </tr>
                    })}
                  </tbody>


                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

<Comp_View_Ent_Logs logUser={userObject} logState={this.state.stLogState} />
<Comp_Print_Report ptrColumnHeader={["Date","Receipt No.","Customer Name","RST No.","Product","Unit","Gross Weight","Tare Weight","Net Weight",
  "Net Weight(Ton)","Vehicle No","Vehicle Type"
]} 
  ptrColumnName={["Date","Invoice No","Company Name","col1","Item","Item Unit","Bags","Pack","free_goods","Quantity","exp","mfg"
  ]}
  // totals={[{"col":"Sub Total","col_index":11,"total_value":0},
  //   {"col":"Amount","col_index":12,"total_value":0}
  // ]}

  
  ptrOutStandingCols={{"Item":"Product","Item Unit":"Unit","Quantity":"Net Quantity"}}
  lsOutStandingData={this.state.stOutStanding}
  ptrOutStandingTitle={"Product/Unit Wise Outstanding"}
  
  lsTabelData={this.state.stListTransactions}
  ptrTitle={"Crushing Product Wise"}
  pageType="0"
  logUser={userObject}/>

<Comp_Edit_Crushing lsInvoice={this.state.stEditInvoice} lsPurchaseProduct={this.state.stEditProduct}
 lsPayment={this.state.stEditPayment} editUser={""}
 logUser={userObject} 
 clsParentListData={this.clsBtnBreakUpsClick}
 compTitle={"Edit Crushing"}
 />
<Comp_Search_Pan onSearchFunc={this.getTruckProWise} 
searchTitle={"Search Crushing"}
searchOptions={{"company_name":"true",
// "inventory_ledger":"true",
"item":"true",
// "site_name":"true",
"vehicle_no":"true",
//"payment_mode":"true",
"from_to_date":"true"}}
/>
          </div>)
      }
}
export default View_Crushing;