import {React,useEffect} from 'react'

export function Comp_Edit_Student_Auth(props){
    return(<div className="modal fade bd-example-modal-xl"  id="divEditStudentAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Student Authorization Details</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        {/* form */}
        <div class="">
    
    {/* <style>
        .ui-menu{
            z-index:9999 !important;
        }
    </style> */}
    <section class="content">
        <div class="container-fluid">
        {props.lsStudentAuth.length>0?
            <form class="form-group" id="frmEditStudentAuth" name="frmEditStudentAuth">
                <input type="hidden" name="res_func" id="res_func" value="fee_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="updateStudentAuth" />
                <input type="hidden" name="m_type" id="m_type" value="updateStudentAuth" />
                <input type="hidden" name="stu_auth_id" id="stu_auth_id" value={btoa(props.lsStudentAuth[0]["id"]) }/>
                <input type="hidden" name="fam_student_id" id="fam_student_id" value={btoa(props.lsStudentAuth[0]["student_id"])}/>
               
                <div class="row">
                    {/* <!-- left column --> */}
                    <div class="col-md-12">
                        <div class="card card-danger">

                            <div class="card-body">
                           
                                {/* other details  */}
                            
  <div class="card card-default">
          <div class="card-header">
            <h3 class="card-title"><b>Authorization Detail</b></h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
            </div>
          </div>
         
          <div class="card-body">
            <div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">SSSMID</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" defaultValue={props.lsStudentAuth[0]["stu_ssmid"]} id="stu_ssmid" name="stu_ssmid" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Family ID</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" defaultValue={props.lsStudentAuth[0]["stu_family_id"]} id="stu_family_id" name="stu_family_id" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Aadhaar No</label>
                                            <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={props.lsStudentAuth[0]["stu_aadhaar_no"]} id="stu_aadhaar_no" name="stu_aadhaar_no" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
             
            </div>

            <div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Account</label>
                                            <div className="input-group input-group-sm">
                                                <input defaultValue={props.lsStudentAuth[0]["stu_bank"]} type="text" id="stu_bank" name="stu_bank" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Branch</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" defaultValue={props.lsStudentAuth[0]["stu_bank_branch"]} id="stu_bank_branch" name="stu_bank_branch" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">IFSC Code</label>
                                            <div className="input-group input-group-sm">
                                               <input type="text" defaultValue={props.lsStudentAuth[0]["stu_ifsc_code"]} id="stu_ifsc_code" name="stu_ifsc_code" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
             
            </div>
            {/* address */}
            <div class="row">
            <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Previous School Attend if Any</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" defaultValue={props.lsStudentAuth[0]["pre_school_name"]} id="pre_school_name" name="pre_school_name" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Last Examination Passed Class</label>
                                            <div className="input-group input-group-sm">
                                              <select id="last_exam_class_id" name="last_exam_class_id" className="from-control form-control-sm required">
                                                <option>-select-</option>
                                                {props.lsClassMaster.map((item,index)=>{
                                                  return <option selected={props.lsStudentAuth[0]["last_exam_class_id"]===item["id"]} value={item["id"]}>{item["class_name"]}</option>
                                                })}

                                              </select>
                                                
                                            </div>
                                        </div>
                          </div> 
                          
             
            </div>
            {/* address */}
            
          </div>

        </div>
        
                            {/* end of other details */}  
                                
                              


                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" onClick={()=>props.editStudentAuth("frmEditStudentAuth")} class="btn btn-primary _btnSetColor1234">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
                {/* <!-- /.card-body --> */}
            </form>:<form class="form-group" id="frmEditStudentAuth" name="frmEditStudentAuth">
                <input type="hidden" name="res_func" id="res_func" value="fee_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="updateStudentAuth" />
                <input type="hidden" name="m_type" id="m_type" value="updateStudentAuth" />
                <input type="hidden" name="stu_auth_id" id="stu_auth_id" value={btoa("0") }/>
                <input type="hidden" name="fam_student_id" id="fam_student_id" value={btoa(props.studentId)}/>

               
                <div class="row">
                    {/* <!-- left column --> */}
                    <div class="col-md-12">
                        <div class="card card-danger">

                            <div class="card-body">
                           
                                {/* other details  */}
                            
  <div class="card card-default">
          <div class="card-header">
            <h3 class="card-title"><b>Authorization Detail</b></h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
            </div>
          </div>
         
          <div class="card-body">
            <div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">SSSMID</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_ssmid" name="stu_ssmid" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Family ID</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_family_id" name="stu_family_id" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Aadhaar No</label>
                                            <div className="input-group input-group-sm">
                                              <input type="text" id="stu_aadhaar_no" name="stu_aadhaar_no" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
             
            </div>

            <div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Account</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_bank" name="stu_bank" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Branch</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_bank_branch" name="stu_bank_branch" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">IFSC Code</label>
                                            <div className="input-group input-group-sm">
                                               <input type="text" id="stu_ifsc_code" name="stu_ifsc_code" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
             
            </div>
            {/* address */}
            <div class="row">
            <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Previous School Attend if Any</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="pre_school_name" name="pre_school_name" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Last Examination Passed Class</label>
                                            <div className="input-group input-group-sm">
                                              <select id="last_exam_class_id" name="last_exam_class_id" className="from-control form-control-sm required">
                                                <option>-select-</option>
                                                {props.lsClassMaster.map((item,index)=>{
                                                  return <option value={item["id"]}>{item["class_name"]}</option>
                                                })}

                                              </select>
                                                
                                            </div>
                                        </div>
                          </div> 
                          
             
            </div>
            {/* address */}
            
          </div>

        </div>
        
                            {/* end of other details */}  
                                
                              


                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" onClick={()=>props.editStudentAuth("frmEditStudentAuth")} class="btn btn-primary _btnSetColor1234">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
                {/* <!-- /.card-body --> */}
            </form>}
        </div>
    </section>
</div>

        {/* end of form */}
      </div>
      </div>
      </div>)
}

export default Comp_Edit_Student_Auth;