import $, { extend } from 'jquery';
import React from 'react';
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,
  TableDatePicker,setKeyUpMapp,setKeyUpMapp_CBack,isCheckFloat} from '../function_lib/common_lib';
import {tmpAutoList} from '../function_lib/city_india';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
//import listData from '../function_lib/customeData';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import defaultPic from '../assets/images/9.png';
import Comp_View_Payment_Breakup from '../components/comp_view_payment_breakup';
import Comp_View_Invoice_Breakup from '../components/comp_view_invoice_breakup';
import Pay_Old_Dues from '../components/pay_old_dues';
import {Comp_Auto_Complete,CompItem_Auto_Complete,Comp_Inventory_Ledger} from '../components/comp_auto_complete';
import Comp_Import_Challan from '../components/comp_import_challan';
import {Link} from 'react-router-dom';
//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';
//import 'select2/dist/js/select2.full.min.js';
//import 'select2/dist/css/select2.min.css';

let loginUser="";
let userObject="";
let companyName="";
let clsCreateBilling;

let rf="0";
class Payment_Entry extends React.Component{
 
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={stListClassFee:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[],stListAccLedger:[],stListStudent:[],
      stStudentInvoice:[],stStuFeeSubmitted:[],stStuOldDues:[],
    stStuPaymentBreakdown:[],stStuInvoiceBreakdown:[],stStudentId:0,
  stListParty:[],stListProduct:[]};

      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      clsCreateBilling=this;
      }
    


      
  okSuccEntryLedg(data,smg){
        if(data==="001"){
          alert("Record Saved Successfully");
          window.location.reload();
          //Custom_Alert("Successful","Record Added Successfully",true);
          //clsCategoryMaster.getSessionYear();
          //$("#frmCreate").find("input[type=text]").val("");
        }else{
          alert(smg);
          //Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }

      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
//this.getMasters();
}
oldDues_okSucc(data,msg){
  
  if(data==="001"){
    clsCreateBilling.getStudentFeenDues();
    alert("Old Due Submitted Successfully");
  }
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-1">
  
<div className="row">
    
      <div className="col-md-12 col-sm-12">
         
      <div class="card card-secondary">
              {/* <!-- /.card-header --> */}
           <div class="card-header">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>{"Payment Entry"}</b></h3>
                
                <Link className="btn btn-sm btn-danger float-right" to={"../view_all_payments"}>{"View Transaction"}</Link>
              </div>
            {/* form data  */}
            <form class="form-group" id="frmFeeSubmission" name="frmFeeSubmission" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                <input type="hidden" name="res_func" id="res_func" value={btoa("okSuccEntryLedg")} />
                <input type="hidden" name="curl_type" id="curl_type" value={btoa("ledger_payment_entry")} />
                <input type="hidden" name="m_type" id="m_type" value={btoa("ledger_payment_entry")} />
                <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                <input type="hidden" name="year_id" id="year_id" value={btoa("1")}/>
                
            <div class="card-body">           
            <div className="row">

            <div className="col-md-12">
                <div className="row">

                <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <TableDatePicker refName="date" />
                                            </div>
                                        </div>
                </div>
                <div className="col-lg-2 col-md-2">
                <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">A/c Ledger</label>
                                            <div className="input-group input-group-sm">
                                            <Comp_Inventory_Ledger comp_id={"inventory_ledger_id"} comp_name={"inventory_ledger_id"} target_obj={"inventory_ledger_id"} autoList={[]} placeHolder={"Customer Name"} propClass={"form-control form-control-sm required"}/>
                                            </div>
                                        </div>

                </div>
                <div className="col-lg-3 col-md-3">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Customer Name</label>
                                            <div className="input-group input-group-sm">
                                            <Comp_Auto_Complete comp_id={"ser_party_info"} comp_name={"ser_party_info"} target_obj={"party_info_id"} autoList={[]} placeHolder={"Customer Name"} propClass={"form-control form-control-sm required"}/>
                                             
                                              <input type="hidden" id="party_info_id" name="party_info_id" value=""/>
                                            
                                            </div>
                                        </div>
                                  </div>
                <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Contact No</label>
                                            <div className="input-group input-group-sm">
                                             <input readOnly={"true"} type="text" name="contact_no" id="contact_no" class="form-control"/>
                                            </div>
                                        </div>
                </div>
                <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Trans.Type</label>
                                            <div className="input-group input-group-sm">
                                             <select id="type" name="type" class="form-control form-control-sm">
                                                <option value={"Credit"}>Credit</option>
                                                <option value={"Debit"}>Debit</option>
                   </select>
                                            </div>
                                        </div>
                </div>


                <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Tans. Amount</label>
                                            <div className="input-group input-group-sm">
                                            <input className="form-control required" type="number" id="value" name="value"/>
                                            </div>
                                        </div>
                                  </div>
                        <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Tans. Mode</label>
                                            <div className="input-group input-group-sm">
                                            <select id="payment_mode" name="payment_mode" class="form-control form-control-sm">
                                                <option value={"0"}>Select</option>
                                                <option value={"Cash"}>Cash</option>
                                                <option value={"Card"}>Card</option>
                                                <option value={"Cheque"}>Cheque</option>
                                                <option value={"Netbanking"}>Netbanking</option>
                                                <option value={"UPI"}>UPI</option>

                                            </select>
                                            </div>
                                        </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                        <div className="form-group form-group-sm row">
                        <label for="exampleInputEmail1">Narration</label>
                        <div className="input-group input-group-sm">
                            <textarea id="narration" name="narration" class="form-control form-control-sm">

                            </textarea>
                            </div>
                            </div>
                        </div>                 

                        <div class="col-3 float-right">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" class="btn btn-sm btn-danger _btnSetColor">Save Transaction</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>                 
            
{/* end of form fields */}

                </div>
                {/* end of col  */}
            </div>
            {/* end of row */}
            
                                 
           
                                  
                                  

            </div>
{/* end of first row */}
{/* table fee Breakdown */}
                              

                              
            </div>
            <div class="row">
                <div class="col-9"></div>
                    

                </div>
            </form>
            {/* end of form data */}
                
              {/* <!-- /.card-body --> */}
            </div>
      </div>
      
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_Import_Challan logUser={userObject} importItemToBill={this.setImportItemToBill}/>
<Comp_View_Payment_Breakup paymentBreakdown={this.state.stStuPaymentBreakdown}/>
<Comp_View_Invoice_Breakup invoiceBreakdown={this.state.stStuInvoiceBreakdown} />
<Pay_Old_Dues studentId={this.state.stStudentId} stuOldDues={this.state.stStuOldDues}/>
    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Payment_Entry;