import $, { extend } from 'jquery';
import React,{useEffect} from 'react';
import {GetAccessRolesChecks} from '../function_lib/common_lib';
export function Comp_Add_Accessibility(props){
    useEffect(()=>{
       let strAccessibility=GetAccessRolesChecks(props.listAccessibility);
       //alert(strAccessibility);
       $("#divAccessChecks").html(strAccessibility);
       //assign checked if already assigned
       if (typeof props.editUser.access_location!=="undefined"){
        let strUserLocatios=props.editUser.access_location;
        if(strUserLocatios.trim()!==""){
            let arrData = strUserLocatios.split(',');
            for (let a = 0; a < arrData.length; a++) {
                $("#" + arrData[a]).prop("checked", true);
            }
        }
       }

       if(typeof props.editUser.authority!=="undefined"){
        let strUserAccess=props.editUser.authority;
        if(strUserAccess.trim()!==""){
            let arrData=strUserAccess.split(',');
            for(let a=0;a<arrData.length;a++){
               
                if(arrData[a]==="edit"){
                    $("#chk_can_edit").prop("checked",true);
                }
                if(arrData[a]==="delete"){
                    $("#chk_can_delete").prop("checked",true);
                }
                if(arrData[a]==="user_access"){
                    $("#chk_can_add").prop("checked",true);
                }
                if(arrData[a]==="accessibility"){
                    $("#chk_accessibility").prop("checked",true);
                }
                if(arrData[a]==="access_log"){
                    $("#chk_access_log").prop("checked",true);
                }
            }
        }
       }
    },[props.listAccessibility])
  
    return(<div className="modal fade bd-example-modal-xl"  id="divAddAccessibility" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Employee Accessibility</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmAccessibility" name="frmAccessibility">
       
        <div className="modal-body">
            <div class="row">
                <div class="col-md-2">
                <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Edit Days Limit</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="edit_day_limit" id="edit_day_limit" defaultValue={props.editUser.edit_day_limit} class="form-control required"/>
                                            </div>
                                        </div>
                </div>
                <div class="col-md-2">
                <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Delete Days Limit</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="delete_day_limit" id="delete_day_limit" defaultValue={props.editUser.delete_day_limit} class="form-control required"/>
                                            </div>
                                        </div>
                </div>
                

            </div>
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value={btoa("okRespCreateUser")} />
                              <input type="hidden" name="curl_type" id="curl_type" value={btoa("led_update_user_access")} />
                              <input type="hidden" name="m_type" id="m_type" value={btoa("led_update_user_access")} />
                              <input type="hidden" name="log_id" id="log_id" value={props.logUser[0]["id"]}/>
                              <input type="hidden" name="log_app_id" id="log_app_id" value={props.logUser[0]["app_user_id"]}/>
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                              <input type="hidden" name="category_id" id="category_id" value={btoa("4")}/>
                              <input type="hidden" name="user_info_id" id="user_info_id" value={typeof props.editUser.id !=="undefined"?btoa(props.editUser.id):"0"}/>
                              
                              <div className="card-body" id="divAccCheck">
                                <div className="row mt-2 mb-2 pt-2 pb-2" style={{"background-color":"#179ab85e"}}>
                                  
                                    <div className="col-md-2">
                                        <input type="checkbox" id="chk_can_edit" name="chk_can_edit" />
                                        <label for="chk_can_edit">Can Edit</label>
                                    </div>
                                    <div className="col-md-2">
                                        <input type="checkbox" id="chk_can_delete" name="chk_can_delete" />
                                        <label for="chk_can_delete">Can Delete</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="checkbox" id="chk_accessibility" name="chk_accessibility" />
                                        <label for="chk_accessibility">Can Set User Accessibility</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="checkbox" id="chk_access_log" name="chk_access_log" />
                                        <label for="chk_access_log">Can Access Logs</label>
                                    </div>

                                </div>
                                <div id="divAccessChecks">

                                </div>
                                
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Comp_Add_Accessibility;