import $, { extend } from 'jquery';
import React,{useEffect,useState} from 'react';
import {TableDatePicker,ParseDate,GetDaysFromTo,setKeyUpMapp_CBack} from '../function_lib/common_lib';
import {Comp_Auto_Complete,CompItem_Auto_Complete} from '../components/comp_auto_complete';

import listData from '../function_lib/customeData';
import DatePicker from "react-datepicker";

//import {Comp_Auto_Complete,CompSite_Auto_Complete} from '../components/comp_auto_complete';

export function Comp_Add_Salary(props){
  //alert(JSON.stringify(props.lsAdvanceOuts));
  
  // const [date, setDate] = useState(new Date());
  // const []
  // const [pItemList,setPItemList]=useState("");
  // const [pPaymentList,setPPaymentList]=useState("");
 //alert(JSON.stringify(props.getSalaryEmp));
 setKeyUpMapp_CBack(this,"_keyupcustom",function cBack(event){
  // calculate salary 
  let masterSalary=0;
  let totalDays=0;
  let workingDays=0;
  let paidLeaves=0;
  let fromToDays=0;
  if(typeof props.getSalaryEmp["master_net_salary"]!=="undefined"){
    if(props.getSalaryEmp["master_net_salary"]!==null){
      if(props.getSalaryEmp["master_net_salary"]!==0){
         masterSalary=parseFloat(props.getSalaryEmp["master_net_salary"]);
      }
    }

    if($("#from_date").val()!=="" && $("#to_date").val()!==""){
      fromToDays=GetDaysFromTo($("#from_date").val(),$("#to_date").val());
      $("#inv_invoice_reference").val(fromToDays);
    }

    if($("#inv_invoice_reference").val()!==""){
      totalDays=parseFloat($("#inv_invoice_reference").val());
    }
    if($("#inv_reference_no").val()!==""){
      workingDays=parseFloat($("#inv_reference_no").val());
    }
    if($("#inv_extra_charges").val()!==""){
      paidLeaves=parseFloat($("#inv_extra_charges").val());
    }
    //alert("i m in calculation "+masterSalary);
    if(masterSalary!==0){
      let perDaySalary=masterSalary/30;
      if(workingDays!==0){
        let netCalcSalary=(workingDays-paidLeaves)*perDaySalary;
        $("#inv_amount").val(netCalcSalary);
      }
      
    }


  }
  
})
  
  useEffect(()=>{
    if(typeof props.clsParentListData==="undefined"){
      return;
  }
 
    
  },[props.getSalaryEmp])
  //alert(JSON.stringify(props.lsInvoice));
    return(<div className="modal fade bd-example-modal-xl"  id="divAddSalary" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">{props.compTitle}</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmAddSalary" name="frmAddSalary">
       
        <div className="modal-body">
        
                  {/* <!-- left column --> */}
                      <div className="card card-danger">
                      <input type="hidden" name="res_func" id="res_func" value={btoa("okSuccSalaryInv")} />
                <input type="hidden" name="curl_type" id="curl_type" value={btoa("generate_salary")} />
                <input type="hidden" name="m_type" id="m_type" value={btoa("generate_salary")} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.logUser[0].app_user_id}/>
                <input type="hidden" name="year_id" id="year_id" value={btoa("1")}/>
                <input type="hidden" name="invoice_ledger_id" id="invoice_ledger_id" value={btoa("11")}/>
               
                              <div className="card-body">
                               
                               <div class="row">
                                {/* actutal fields */}
                                <>

                                <div className="col-lg-4 col-md-4">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Emp Id</label>
                                            <div className="input-group input-group-sm">
                                            <input type="text" name="sal_emp_no" id="sal_emp_no" defaultValue={props.getSalaryEmp["A/c No"]} readOnly={"true"} class="form-control"/>
                                            </div>
                                        </div>
                                  </div>
                               
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Customer Name</label>
                                            <div className="input-group input-group-sm">
                                            <input type="text" name="sal_emp_name" id="sal_emp_name" defaultValue={props.getSalaryEmp["Emp Name"]} readOnly={"true"} class="form-control"/>
                                             
                                              <input class="required" type="hidden" id="sal_emp_id" name="sal_emp_id" defaultValue={typeof props.getSalaryEmp["user_info_id"]!=="undefined"?btoa(props.getSalaryEmp["user_info_id"]):'0'}  />
                                            
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Contact No</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="sal_contact_no" id="sal_contact_no" defaultValue={props.getSalaryEmp["Contact No"]} readOnly={"true"} class="form-control"/>
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Salary(Header)</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="master_net_salary" id="master_net_salary" defaultValue={props.getSalaryEmp["master_net_salary"]} readOnly={"true"} class="form-control"/>
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Salary Date</label>
                                            <div className="input-group input-group-sm">
                                            <TableDatePicker refName="inv_date" />
                                            {/* <TableDatePicker className="form-control form-control-sm" dateFormat="dd/MM/yyyy" id={"inv_date"} name={"inv_date"}   format='dd/MM/yyyy' selected={date} /> */}
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Salary From</label>
                                            <div className="input-group input-group-sm">
                                            <TableDatePicker refName="from_date" />
                                            {/* <DatePicker className="form-control form-control-sm" dateFormat="dd/MM/yyyy" id={"from_date"} name={"from_date"}   format='dd/MM/yyyy' selected={date} onChange={date =>setDate(date)} /> */}
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Salary To</label>
                                            <div className="input-group input-group-sm">
                                            <TableDatePicker refName="to_date" />
                                            {/* <DatePicker className="form-control form-control-sm" dateFormat="dd/MM/yyyy" id={"to_date"} name={"to_date"}   format='dd/MM/yyyy' selected={date} onChange={date =>setDate(date)} /> */}
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Paid Leave</label>
                                            <div className="input-group input-group-sm">
                                            <input type="number" className="form-control form-control-sm _keyupcustom" id="inv_extra_charges" name="inv_extra_charges"  />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">UnPaid Leave</label>
                                            <div className="input-group input-group-sm">
                                            <input type="number" className="form-control form-control-sm _keyupcustom" id="inv_no_of_items" name="inv_no_of_items"  />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Total Days(Inc Holidays)</label>
                                            <div className="input-group input-group-sm">
                                            <input type="number" className="form-control form-control-sm _keyupcustom" id="inv_invoice_reference" name="inv_invoice_reference"  />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Working Days</label>
                                            <div className="input-group input-group-sm">
                                            <input type="number" className="form-control form-control-sm _keyupcustom" id="inv_reference_no" name="inv_reference_no"  />
                                            </div>
                                        </div>
                                  </div>
                                  
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Total Salary</label>
                                            <div className="input-group input-group-sm">
                                            <input type="number" className="form-control form-control-sm" id="inv_amount" name="inv_amount"  />
                                            </div>
                                        </div>
                                  </div>

                                  </>

{/* end of actual fields */}
                               </div>
                               {/* payment entry */}
                               <div class="row">
                               <div class="col-md-5">
                                {props.lsAdvanceOuts.map((out_item,out_index)=>{
                                  let outCredit=0;
                                  let outDebit=0;
                                  if(out_item["Credit"]!==null && out_item["Credit"]!=="null"){
                                    outCredit=parseFloat(out_item["Credit"]);
                                  }
                                  if(out_item["Debit"]!==null && out_item["Debit"]){
                                    outDebit=parseFloat(out_item["Debit"]);
                                  }
                                  
                                  return <>
                                  <div style={{"color":"red"}}><b>Advance Paid Balance:{outCredit-outDebit}</b></div>
                                  </>
                                })
                                
                                }
                                    </div>
                                    <div class="col-md-7">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                    <th>Payment Mode</th>
                                                    <th>Transaction Amount</th>
                                                    <th>Narration</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm">
                                                      <select id="payment_mode" name="payment_mode" className="form-control piPaymentMember miPaymentMember">
                                                        <option value="0">-Payment Mode-</option>
                                                        <option value={"Cash"}>Cash</option>
                                                        <option value={"Card"}>Card</option>
                                                        <option value={"Cheque"}>Cheque</option>
                                                        <option value={"Netbanking"}>Netbanking</option>
                                                        <option value={"upi"}>UPI</option>
                                                        <option value={"Advance"}>Advance</option>
                                                      </select>
                                                      </div></td>
                                                    <td>
                                                    <div class="input-group input-group-sm">
                                                    <input id="pay_value" name="pay_value" type="text" class="form-control piPaymentMember miPaymentMember" />
                                                    <input id="edit_pay_id" name="edit_pay_id" type="hidden" value={"0"}/>

                                                    </div></td>
                                                    <td><div class="input-group input-group-sm"><input id="pay_narration" name="pay_narration" type="text" class="form-control piPaymentMember miPaymentMember" /></div></td>
                                                    <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miPaymentMember" cldname="piPaymentMember" cldhidname="piHidPaymentMember" resValue="p_paymentBreakdown" resname="tbPaymentBreakdown" id="_btnPaymentBreakdown" value="add" /></td>
                                                </tr>
                                            </thead>
                                            <tbody id="tbPaymentBreakdown">
                                            </tbody>
                                        </table>
                                        <input type="hidden" id="p_paymentBreakdown" name="p_paymentBreakdown" />
                                        
                                    </div>
                                    </div>
                                    {/* end of payment entry */}
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                                  
                              </div>
                      </div>
                  
              
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_Add_Salary;