import React from 'react'
export function Comp_Add_Party_Info(props){
  
    return(<div className="modal fade bd-example-modal-xl"  id="divAddPartyInfo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xlll" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Add New Customer</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmAddPartyInfo" name="frmAddPartyInfo">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value={btoa("okRespCreateParty")} />
                              <input type="hidden" name="curl_type" id="curl_type" value={btoa("addPartyInfo")} />
                              <input type="hidden" name="m_type" id="m_type" value={btoa("addPartyInfo")} />
                              <input type="hidden" name="log_id" id="log_id" value={props.logUser[0]["id"]}/>
                              <input type="hidden" name="log_app_id" id="log_app_id" value={props.logUser[0]["app_user_id"]}/>
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                              <input type="hidden" name="category_id" id="category_id" value={typeof props.pCategory!=="undefined"?btoa(props.pCategory):btoa("1")}/>
                              <input type="hidden" name="edit_party_info_id" id="edit_party_info_id" value={typeof props.editPartyInfo.id!=="undefined"?btoa(props.editPartyInfo.id):'0'} />
                              
                              <div className="card-body">
                                <table>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Company/Person Name</label></td>
                                        <td> <input type="text" id="company_name" name="company_name" className="form-control required" placeholder="" defaultValue={props.editPartyInfo.company_name} /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Person Name</label></td>
                                        <td> <input type="text" id="person_name" name="person_name" className="form-control required" placeholder="" defaultValue={props.editPartyInfo.person_name} /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Contact No</label></td>
                                        <td><input type="text" id="contact_no" name="contact_no" className="form-control required" placeholder="" defaultValue={props.editPartyInfo.contact_no} /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Address</label></td>
                                        <td><input type="text" id="address" name="address" className="form-control required" placeholder="" defaultValue={props.editPartyInfo.address} /></td>
                                    </tr>
                                    
                                  
                                </table>
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Comp_Add_Party_Info;