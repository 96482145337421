import $ from 'jquery';
import React from 'react';
import Chart from "react-google-charts";
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod, GetDateDifferecec,CallMapMethod_CBack} from '../function_lib/common_lib';
// import {GlobalContext} from '../context';
import {GbSetGetPartyInfo,GbSetGetItemInfo} from '../cGlobal/cls_global';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo.png';
import defaultPic from '../assets/images/9.png';

//import Upcoming_Events from '../components/upcoming_events';
//import Emp_On_Leave from '../components/emp_on_leave';
//import Broadcast from '../components/broadcast';
//import Mobile_Widgets from '../components/mobile_widgets';
import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import './dashboard_new.css';

//import UserManual from '../assets/Operational_Instructions.pdf';

let loginUser="";
let userObject="";
let companyName="";
let userType="";
let clsDashboardNew;

let _chkOrderCall=false;
let _chkAnalyticsCall=false;
let _chkCmsCall=false;
class Comp_Dashboard extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        //alert(JSON.stringify(this.props));
        clsDashboardNew=this;
        this.state={stGbPartyInfo:[]};
        
        //this.state={no_entities:0,no_clients:0,no_vendors:0}
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }

     
      //alert(ret);
     
    }
     
    getLicenseInfo(){
      var map=[];
      map.push({name:"curl_type",value:"getLicense"});
      map.push({name:"res_func",value:"callBackrrr"});
      map.push({name:"local_token",value:userObject[0].app_user_id});
      //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
      
      map["res_func"]="callBackrrr";
      map["curl_type"]="getLicense";
      CallMapMethod_CBack(clsDashboardNew,map,function(data,msg){
        //alert(data);
          if(data==="001" && msg==="True"){
              
            }else{
              //alert("check");
              Custom_Alert("Not Valid",msg,true,"../");
              localStorage.clear();
            }
      });
}

      componentDidMount(){
        this.getLicenseInfo();
        //this.getMasters();
        $("#divEmployee").show();
        //this.forceUpdateHandler();
      }
   //Get no of Entities/Client/Vendos
     
   
     

    render(){
      
        return(<div className="wrapper">
            <Header/>
            {/* <GbSetGetPartyInfo getSet="set" lsPartyInfo={this.state.stGbPartyInfo}/> */}
            {/* <Mobile_Widgets/> */}
            <div class="content" style={{"min-height":"150px"}}>
            <div class="container-fluid mt-4">
                   {/* new acta component */}
                   <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                    {/* user compoentnet  */}
            {/* component type */}
          
          {/* invoice order view */}
            <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-warning">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Recent Activities"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Number of Truck Out <span className="float-right badge bg-primary">{"10"}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                    Number of Fuel In <span className="float-right badge bg-danger">{"8"}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Number of Fuel Out <span className="float-right badge bg-success">{"9"}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Total Order(till date) <span className="float-right badge bg-success">{"27"}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* end of invoice order view */}
            
            {/* dau view */}
            <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-warning">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red bg-info1 elevation-11"><i className="fa fa-cogs fa-3x" aria-hidden="true"></i></span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Reports"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Product Wise Outstanding <span className="float-right badge bg-primary">{""}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Party Wise Outstanding <span className="float-right badge bg-danger">{""}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Party Wise TruckOut <span className="float-right badge bg-success">{""}</span>
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      Total Order(till date) <span className="float-right badge bg-success">{"27"}</span>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
{/*end dau view */}
            {/* end of component tyep */}
</div>
</div>
{/* end of compaign */}
</div>
                {/* end of new acta component */}

{/* <!-- /.content --> */}
</div>
      </div> 
      <Alert_Content/>
        <Footer/>
        
      </div>)
    }
}
export default Comp_Dashboard