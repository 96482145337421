import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Popper from 'popper.js';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';

import DashboardNew from './user_pages/dashboard_new';
import Student_Admission from './user_pages/student_admission';
import Session_Year from './user_pages/session_year';
import Fee_Categories from './user_pages/fee_categories';
import Class_Master from './user_pages/class_master';
import Class_Fee_Master from './user_pages/class_fee_master';
import View_Class_Fee_Master from './user_pages/view_class_fee_master';
import View_Student_Admission from './user_pages/view_student_admission';
import View_Fee_Transactions from './user_pages/view_fee_transactions';
import View_Student_Wise_Due from './user_pages/view_student_wise_due';
import Student_Fee_Allotment from './user_pages/student_fee_allotment';
import Submit_Fee from './user_pages/submit_fee';
import Student_Bulk_Import from './user_pages/student_bulk_import';


// Building Related Components
import Create_Billing from './building/create_billing';
import View_Product_Master from './building/view_product_master';
import View_Product_Category_Master from './building/view_product_category_master';
import View_Party_Ledger from './building/view_party_ledger';
import View_Service_Provider from './building/view_service_provider';
import Truck_Out from './building/truck_out';
import Create_Blasting from './building/create_blasting';
import Create_Drilling from './building/create_drilling';
import Create_Crushing from './building/create_crushing';
import Create_Fuel_In from './building/create_fuel_in';
import Create_Fuel_Out from './building/create_fuel_out';
import Create_Truck_Out_Billing from './building/create_truck_out_billing';
import Create_Crushing_Bill from './building/create_crushing_bill';
import View_Invoice_Rpt from './user_pages/view_invoice_rpt';
import View_Truck_Out from './user_pages/view_truck_out';
import View_Billing_Truck_Out from './user_pages/view_billing_truck_out';
import View_Crushing_Bills from './user_pages/view_crushing_bills';
import View_Billing_Receipt from './user_pages/view_billing_receipt';
import View_Fuel_In from './user_pages/view_fuel_in';
import View_Fuel_Out from './user_pages/view_fuel_out';
import View_Blasting from './user_pages/view_blasting';
import View_Drilling from './user_pages/view_drilling';
import View_All_Payments from './user_pages/view_all_payments';
import View_PartyWise_OutStanding from './user_pages/view_partywise_outstanding';
import View_ProductWise_OutStanding from './user_pages/view_productwise_outstandig';
import View_Product_Stock from './user_pages/view_product_stock';

import View_Crushing from './user_pages/view_crushing';
import View_Site_Master from './building/view_site_master';
import View_Vehicle_Master from './building/view_vehicle_master';
import Payment_Entry from './building/payment_entry';

import View_Employee from './building/view_employee';
import Salary_Mgmt from './user_pages/salary_mgmt';





class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
  render(){
   
    return <Router>
      <Route exact path="/" component={Pages} />
     
      <Route exact path="/Dashboard" component={DashboardNew}/>
      <Route exact path="/student_admission" component={Student_Admission} />
      {/* <Route exact path="/opd_reports" component={Opd_Reports} /> */}
      {/* <Route exact path="/ipd_reports" component={Ipd_Reports} /> */}
      <Route exact path="/session_year" component={Session_Year} />
      <Route exact path="/fee_categories" component={Fee_Categories} />
      <Route exact path="/class_master" component={Class_Master} />
      <Route exact path="/class_fee_master" component={Class_Fee_Master} />
      <Route exact path="/view_class_fee_master" component={View_Class_Fee_Master} />
      <Route exact path="/view_student_admission" component={View_Student_Admission}/>
      <Route exact path="/student_fee_allotment" component={Student_Fee_Allotment}/>
      <Route exact path="/Submit_Fee" component={Submit_Fee}/>
      <Route exact path="/student_bulk_import" component={Student_Bulk_Import}/>
      <Route exact path="/view_fee_transactions" component={View_Fee_Transactions} />
      <Route exact path="/view_student_wise_due" component={View_Student_Wise_Due}/>

      {/* building related components */}
      <Route exact path="/ledger/create_truck_out_billing" component={Create_Truck_Out_Billing}/>
      <Route exact path="/ledger/create_crushing_bill" component={Create_Crushing_Bill}/>
      <Route exact path="/ledger/truck_out" component={Truck_Out}/>
      <Route exact path="/ledger/create_blasting" component={Create_Blasting} />
      <Route exact path="/ledger/create_drilling" component={Create_Drilling}/>
      <Route exact path="/ledger/create_crushing" component={Create_Crushing}/>
      <Route exact path="/ledger/fuel_in" component={Create_Fuel_In} />
      <Route exact path="/ledger/fuel_out" component={Create_Fuel_Out} />

      <Route exact path="/view_product_master" component={View_Product_Master}/>
      <Route exact path="/view_product_category" component={View_Product_Category_Master}/>
      <Route exact path="/view_party_ledger" component={View_Party_Ledger}/>
      <Route exact path="/view_service_provider" component={View_Service_Provider}/>
      
      <Route exact path="/view_invoice_rpt" component={View_Invoice_Rpt}/>
      <Route exact path="/view_truck_out" component={View_Truck_Out}/>
      <Route exact path="/view_billing_truck_out" component={View_Billing_Truck_Out}/>
      <Route exact path="/view_crushing_bills" component={View_Crushing_Bills}/>
      <Route exact path="/view_billing_receipt" component={View_Billing_Receipt}/>
      <Route exact path="/view_fuel_in" component={View_Fuel_In}/>
      <Route exact path="/view_fuel_out" component={View_Fuel_Out}/>
      <Route exact path="/view_blasting" component={View_Blasting}/>
      <Route exact path="/view_crushing" component={View_Crushing}/>
      <Route exact path="/view_drilling" component={View_Drilling}/>
      <Route exact path="/view_site_master" component={View_Site_Master}/>
      <Route exact path="/view_vehicle_master" component={View_Vehicle_Master}/>
      <Route exact path="/payment_entry" component={Payment_Entry}/>
      <Route exact path="/view_all_payments" component={View_All_Payments}/>
      <Route exact path="/view_partywise_outstanding" component={View_PartyWise_OutStanding}/>
      <Route exact path="/view_productwise_outstandig" component={View_ProductWise_OutStanding}/>
      <Route exact path="/view_product_stock" component={View_Product_Stock}/>
      <Route exact path="/view_employee" component={View_Employee}/>
      <Route exact path="/salary_mgmt" component={Salary_Mgmt}/>

    
    </Router>
  }
  
}
export default App;