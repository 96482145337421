import $, { extend } from 'jquery';
import React,{useEffect,useState} from 'react';
import {TableDatePicker,ParseDate} from '../function_lib/common_lib';
import {Comp_Auto_Complete,CompItem_Auto_Complete,Comp_Inventory_Ledger,
    Comp_Inventory_Ledger_New,CompEmployee_Auto_Complete_New
} from '../components/comp_auto_complete';
import listData from '../function_lib/customeData';
import DatePicker from "react-datepicker";

//import {Comp_Auto_Complete,CompSite_Auto_Complete} from '../components/comp_auto_complete';

export function Comp_Emp_Trans(props){
  
  const [date, setDate] = useState(new Date());

  useEffect(()=>{
   
  // if (typeof props.dataAccounts["Date"]!=="undefined"){
  //   //alert("i m in data");
  //   setDate(ParseDate(props.dataAccounts["Date"]));
  // }
  },[])
  //alert(JSON.stringify(props.dataAccounts));
    return(<div className="modal fade bd-example-modal-xl"  id="divEmpTrans" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">{props.compTitle}</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmEmpTrans" name="frmEmpTrans">
       
        <div className="modal-body">
        
                  {/* <!-- left column --> */}
                      <div className="card card-danger">
                      <input type="hidden" name="res_func" id="res_func" value={btoa("okSuccTransEnt")} />
                <input type="hidden" name="curl_type" id="curl_type" value={btoa("ledger_emp_trans_entry")} />
                <input type="hidden" name="m_type" id="m_type" value={btoa("ledger_emp_trans_entry")} />
                <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.logUser[0].app_user_id}/>
                <input type="hidden" name="year_id" id="year_id" value={btoa("1")}/>
                <input type="hidden" name="edit_id" id="edit_id" value={typeof props.dataAccounts["account_inventory_id"]!=="undefined"?btoa(props.dataAccounts["account_inventory_id"]):"0"} />

                              <div className="card-body">
                               
                               <div class="row">
                                {/* actutal fields */}
                               <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <DatePicker className="form-control form-control-sm" dateFormat="dd/MM/yyyy" id={"trans_date"} name={"trans_date"}   format='dd/MM/yyyy' selected={date} onChange={date =>setDate(date)} />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">A/c Ledger</label>
                                            <div className="input-group1 input-group-sm">
                                            <Comp_Inventory_Ledger_New comp_id={"trans_inventory_ledger"} comp_name={"trans_inventory_ledger"} target_obj={"trans_inventory_ledger_id"} placeHolder={"A/c Ledger"} defaultSelected={{id:props.dataAccounts["inventory_ledger_id"],name:props.dataAccounts["Inventory Ledger"]}} propClass={"form-control1 form-control-sm1 required"}/>
                                            </div>
                                        </div>

                </div>

                <div className="col-lg-3 col-md-3">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Emp Name</label>
                                            <div className="input-group1 input-group-sm">
                                            <CompEmployee_Auto_Complete_New comp_id={"trans_user_info"} comp_name={"trans_user_info"} target_obj={"trans_user_info_id"} placeHolder={"Emp Name"} propClass={"form-control1 form-control-sm1"}/>
                                            </div>
                                        </div>
                                  </div>
                                      

                <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Trans.Type</label>
                                            <div className="input-group input-group-sm">
                                             <select id="trans_type" name="trans_type" class="form-control form-control-sm">
                                                <option selected={props.dataAccounts["Type"]==="Credit"} value={"Credit"}>Credit</option>
                                                <option selected={props.dataAccounts["Type"]==="Debit"} value={"Debit"}>Debit</option>
                   </select>
                                            </div>
                                        </div>
                </div>
                <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Tans. Amount</label>
                                            <div className="input-group input-group-sm">
                                            <input className="form-control required" type="number" id="trans_value" name="trans_value" defaultValue={props.dataAccounts["Value"]}/>
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Tans. Mode</label>
                                            <div className="input-group input-group-sm">
                                            <select id="trans_payment_mode" name="trans_payment_mode" class="form-control form-control-sm">
                                                <option  value={"0"}>Select</option>
                                                <option selected={props.dataAccounts["Payment_mode"]==="Cash"} value={"Cash"}>Cash</option>
                                                <option selected={props.dataAccounts["Payment_mode"]==="Card"} value={"Card"}>Card</option>
                                                <option selected={props.dataAccounts["Payment_mode"]==="Cheque"} value={"Cheque"}>Cheque</option>
                                                <option selected={props.dataAccounts["Payment_mode"]==="Netbanking"} value={"Netbanking"}>Netbanking</option>
                                                <option selected={props.dataAccounts["Payment_mode"]==="UPI"} value={"UPI"}>UPI</option>
                                                <option selected={props.dataAccounts["Payment_mode"]==="Advance"} value={"Advance"}>Advance</option>

                                            </select>
                                            </div>
                                        </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                        <div className="form-group form-group-sm row">
                        <label for="exampleInputEmail1">Narration</label>
                        <div className="input-group input-group-sm">
                            <textarea id="trans_narration" name="trans_narration" class="form-control form-control-sm">
                                    {props.dataAccounts["Narration"]}
                            </textarea>
                            </div>
                            </div>
                        </div>              
                                

                               </div>
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                                  
                              </div>
                      </div>
                  
              
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_Emp_Trans;