import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Class_Fee_Master from '../components/comp_class_fee_master';
import listData from '../function_lib/customeData';
// import Comp_Edit_Cls_Fee_Master from '../components/comp_edit_cls_fee_master';




let loginUser="";
let userObject="";
let companyName="";
let clsClassFeeMaster;

let rf="0";
class Class_Fee_Master extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stListClassFee:[],stListSessionYear:[],stListClassMaster:[],stListFeeCategory:[],
      stListFeeBreakups:[],stSelectItem:[],stInserFeeMode:true};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsClassFeeMaster=this;
      }

      getClassFeeMaster(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getClassFeeMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getClassFeeMaster";
        CallMapMethod_CBack(clsClassFeeMaster,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsClassFeeMaster.setState({stListClassFee:listData},()=>{
                  //window.getPagination("#tbData");
                  clsClassFeeMaster.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsClassFeeMaster.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
              }
        });
      }
      okSucc(data,smg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful","Record Added Successfully",true);
          clsClassFeeMaster.getClassMaster();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }
fee_okSucc(data,msg){
  if(data==="001"){
    alert("Record Added Successfully");
    window.location.reload();
  }
  
}
feeEdit_okSucc(data,msg){
  alert(data);
}      
okSuccCreditB(data,msg){
  //alert(data);
  if(data===-10){
    Custom_Alert("Validation","Fill Required Fileds",false);
    
    return;
  }else{
    //alert(data+msg);
    Custom_Alert("Completed",msg,true);
    
  }
    
}


getMasters(){
  var map=[];
  map.push({name:"curl_type",value:"getMastersRequest"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="getMastersRequest";
  CallMapMethod_CBack(clsClassFeeMaster,map,function(data,msg){
    //alert(data);
      if(data!=="0" && data!==""){
          let listData=JSON.parse(data);
          var strData="";
          //alert(JSON.stringify(listData.master_balance));
          clsClassFeeMaster.setState({
            stListSessionYear:listData.ls_session_year,
            stListClassMaster:listData.ls_class_master,
          stListFeeCategory:listData.ls_fee_category},()=>{
            clsClassFeeMaster.forceUpdateHandler();
          })
        }
  });
}
onAddNewFeeBreak(){
  clsClassFeeMaster.setState({stSelectItem:[],stListFeeBreakups:[],stInserFeeMode:true},()=>{
    clsClassFeeMaster.forceUpdateHandler();
  })
}
onClassFeeEdit(item){
  let map=[];
  map.push({name:"curl_type",value:"getClassFeeBreakdown"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"user_type",value:userObject[0].type});
  map.push({name:"fee_master_id",value:btoa(item.id)});
  //alert($("#ser_session_year").val());
  //map.push({name:"upper_id",value:userObject[0].upper_id});
  map["res_func"]="callBackrrr";
  map["curl_type"]="getClassFeeBreakdown";
  CallMapMethod_CBack(clsClassFeeMaster,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let lsData=JSON.parse(data);
      clsClassFeeMaster.setState({stSelectItem:item,stListFeeBreakups:lsData,stInserFeeMode:false},()=>{
        clsClassFeeMaster.forceUpdateHandler();
      })
    }
  })
}

componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.clsBtnFeeBreakups =new listData("_btnFeeBreakdown", "hellow")
this.getClassFeeMaster();
this.getMasters();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">
{/* for now [row]it is unused */}
{/* starting of cols */}

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_session_year" name="ser_session_year" className="form-control form-control-sm">
                <option value={"0"}>-Session Year-</option>
                {this.state.stListSessionYear.map((item,index)=>{
                  return <option value={item["id"]}>{item["session_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>


<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_class_name" name="ser_class_name" className="form-control form-control-sm">
                <option value={"0"}>-Classes-</option>
                {this.state.stListClassMaster.map((item,index)=>{
                  return <option value={item["id"]}>{item["class_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>

{/* 
<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div> */}

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getClassFeeMaster()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>
</div>
{/* end of cols */}

    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Class Fees</b></h3>
                
                <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divClassFeeMaster" onClick={()=>this.onAddNewFeeBreak()} href="javascript:void(0)">Add New Class Fees</a>
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Session Year</th>
                      <th>Class Name</th>
                      <th>Total Fee</th>
                      <th>Creation Date</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListClassFee.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item.session_name}</td>  
                        <td>{item.class_name}</td>  
                  <td>{item.net_amount}</td>
                  <td>{item.entry_date}</td>
                  <td>{item.is_active}</td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                     <a class="dropdown-item" onClick={()=>this.onClassFeeEdit(item)} data-toggle="modal" data-target="#divClassFeeMaster" href="javascript:void(0)">View/Edit Fees</a>
                    </div>
                  </div>
                  </td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divClassMaster" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Add New Class</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmClassMaster" name="frmClassMaster">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createClassMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createClassMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="upper_id" id="upper_id" value={userObject[0].app_user_id} />
                            <div className="card-body">
                                <div className="row">
                                
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Class Name *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="class_name" name="class_name" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Priority Sequence *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="priority_index" name="priority_index" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{"color":"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Add New</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    <Comp_Class_Fee_Master 
    insertFeeMode={this.state.stInserFeeMode} 
    lsFeeBreakups={this.state.stListFeeBreakups} 
    lsSessionYear={this.state.stListSessionYear} 
    lsClassMaster={this.state.stListClassMaster} 
    lsFeeCategory={this.state.stListFeeCategory} 
    selectedFeeItem={this.state.stSelectItem}
    app_user_id={userObject[0].app_user_id} 
    childBtnFeeBreakups={this.clsBtnFeeBreakups}
    />
    {/* <Comp_Edit_Cls_Fee_Master lsFeeBreakups={this.state.stListFeeBreakups} lsSessionYear={this.state.stListSessionYear} lsClassMaster={this.state.stListClassMaster} lsFeeCategory={this.state.stListFeeCategory} app_user_id={userObject[0].app_user_id}/> */}
          </div>)
      }
}
export default Class_Fee_Master;