import React from 'react'
export function Comp_Add_Product_Category(props){
    return(<div className="modal fade bd-example-modal-xl"  id="divAddProductCategory" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xlll" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Product Category</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmAddProductCategory" name="frmAddProductCategory">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value={btoa("okResProCate")} />
                              <input type="hidden" name="curl_type" id="curl_type" value={btoa("addProCategory")} />
                              <input type="hidden" name="m_type" id="m_type" value={btoa("addProCategory")} />
                              <input type="hidden" name="log_id" id="log_id" value={props.logUser[0]["id"]}/>
                              <input type="hidden" name="log_app_id" id="log_app_id" value={props.logUser[0]["app_user_id"]}/>
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                              
                              <div className="card-body">
                                <table>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Top Category(if Any)</label></td>
                                        <td> <select id="parent_id" name="parent_id" className="form-control required12">
                                                  <option value="0">-Select Top Category-</option>
                                                  {props.lsProCatMaster.map((item,index)=>{
                                                    return <option value={btoa(item["id"])}>{item["category_name"]}</option>
                                                  })}
                                                    
                                                  </select></td>
                                    </tr>
                                    
                                    <tr>
                                        <td><label for="exampleInputEmail1">Category Name*</label></td>
                                        <td> <input type="text" id="category_name" name="category_name" className="form-control required" placeholder="" /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Category Code*</label></td>
                                        <td> <input type="text" id="category_code" name="category_code" className="form-control required" placeholder="" /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Description(if Any)</label></td>
                                        <td><input type="text" id="description" name="description" className="form-control required12" placeholder="" /></td>
                                    </tr>
                                </table>
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Comp_Add_Product_Category;