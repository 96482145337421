import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import listData from '../function_lib/customeData';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';
import Comp_View_Payment_Breakup from '../components/comp_view_payment_breakup';
import Comp_View_Invoice_Breakup from '../components/comp_view_invoice_breakup';
import Pay_Old_Dues from '../components/pay_old_dues';
//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';
import 'select2/dist/js/select2.full.min.js';
import 'select2/dist/css/select2.min.css';

let loginUser="";
let userObject="";
let companyName="";
let clsSubmitFee;

let rf="0";
class Submit_Fee extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stListClassFee:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[],stListAccLedger:[],stListStudent:[],
      stStudentInvoice:[],stStuFeeSubmitted:[],stStuOldDues:[],
    stStuPaymentBreakdown:[],stStuInvoiceBreakdown:[],stStudentId:0};

      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      clsSubmitFee=this;
      }
      
getMasters(){
    var map=[];
    map.push({name:"curl_type",value:"getMastersRequest"});
    map.push({name:"res_func",value:"callBackrrr"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    map["res_func"]="callBackrrr";
    map["curl_type"]="getMastersRequest";
    CallMapMethod_CBack(clsSubmitFee,map,function(data,msg){
      //alert(data);
        if(data!=="0" && data!==""){
            let listData=JSON.parse(data);
            var strData="";
            //alert(JSON.stringify(listData.master_balance));
            clsSubmitFee.setState({stListSessionYear:listData.ls_session_year,
              stListClassMaster:listData.ls_class_master,
            stListFeeCategory:listData.ls_fee_category,
            stListAccLedger:listData.ls_acc_ledger},()=>{
                clsSubmitFee.forceUpdateHandler();
            })
          }
    });
  }

      
  okSuccStuFee(data,smg){
        if(data==="001"){
          alert("Fee submitted successfully");
          window.location.reload();
          //Custom_Alert("Successful","Record Added Successfully",true);
          //clsCategoryMaster.getSessionYear();
          //$("#frmCreate").find("input[type=text]").val("");
        }else{
          alert(smg);
          //Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }
getStudentByAutoComplete(){
  
  let map=[];
  map.push({name:"curl_type",value:"getStudentDataAutoComp"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"session_year_id",value:$("#session_year_id").val()});
  map.push({name:"class_id",value:$("#class_id").val()});
  map.push({name:"ser_stu_text",value:""});

  map["res_func"]="callBackrrr";
  map["curl_type"]="getStudentDataAutoComp";
  CallMapMethod_CBack(clsSubmitFee,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let listData=JSON.parse(data);
      clsSubmitFee.setState({stListStudent:listData});
    }
  })

}
getStudentFeenDues(){
  let map=[];
  map.push({name:"curl_type",value:"getAllPaymentsandFeeStu"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"session_year_id",value:$("#session_year_id").val()});
  map.push({name:"class_id",value:$("#class_id").val()});
  map.push({name:"stu_id",value:$("#ser_student_name").val()});


  map["res_func"]="callBackrrr";
  map["curl_type"]="getAllPaymentsandFeeStu";
  CallMapMethod_CBack(clsSubmitFee,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let listData=JSON.parse(data);
      //alert(JSON.stringify(listData.student_invoice));
      clsSubmitFee.setState({stStudentInvoice:listData.student_invoice,
      stStuFeeSubmitted:listData.payment_entry,stStuOldDues:listData.old_dues});
    }
  })
}

getInvoiceBreakups(item){
 
  let map=[];
  map.push({name:"curl_type",value:"getStudentInvoice"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"session_year_id",value:$("#session_year_id").val()});
  map.push({name:"class_id",value:$("#class_id").val()});
  map.push({name:"stu_id",value:$("#ser_student_name").val()});
  map.push({name:"stu_invoice_id",value:btoa(item.id)});

  map["res_func"]="callBackrrr";
  map["curl_type"]="getStudentInvoice";
  CallMapMethod_CBack(clsSubmitFee,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let listData=JSON.parse(data);
      //alert(JSON.stringify(listData.payment_entry));
      clsSubmitFee.setState({stStuInvoiceBreakdown:listData.invoice_breakups});
    }
  })
}
getPaymentEntryBreakups(item){
  //alert(item.id);
  let map=[];
  map.push({name:"curl_type",value:"getStudentFeeBreakdown"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"session_year_id",value:$("#session_year_id").val()});
  map.push({name:"class_id",value:$("#class_id").val()});
  map.push({name:"stu_id",value:$("#ser_student_name").val()});
  map.push({name:"payment_entry_id",value:btoa(item.id)});

  map["res_func"]="callBackrrr";
  map["curl_type"]="getStudentFeeBreakdown";
  CallMapMethod_CBack(clsSubmitFee,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let listData=JSON.parse(data);
      //alert(JSON.stringify(listData.payment_entry));
      clsSubmitFee.setState({stStuPaymentBreakdown:listData.payment_breakdown});
    }
  })
}

      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getMasters();

// fee Submission 
this.clsBtnBreakUpsClick =new listData("_btnFeeBreakdown", "hellow");
this.clsBtnBreakUpsClick.setOnChangeEvent("fee_breakdown",this.clsBtnBreakUpsClick);
this.clsBtnBreakUpsClick.preEventSetData=function setTotalAmount(){
  let totalAmount=0;
  for(let i=0;i<clsSubmitFee.clsBtnBreakUpsClick.purchaseItem.tt.length;i++){
    totalAmount+=parseFloat(clsSubmitFee.clsBtnBreakUpsClick.purchaseItem.tt[i]["total_amount"]);
  }
  document.getElementById("net_amount").value=totalAmount;
}
//End of fee Submission 
$(".select2").select2();
$("#ser_student_name").on("change",function () { 
  clsSubmitFee.getStudentFeenDues();
  clsSubmitFee.setState({stStudentId:$("#ser_student_name").val()}); 
});
}
oldDues_okSucc(data,msg){
  
  if(data==="001"){
    clsSubmitFee.getStudentFeenDues();
    alert("Old Due Submitted Successfully");
  }
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  
<div className="row">
    <div className="col-md-4 col-sm-12">
    <div class="card card-secondary">
    <div class="card-header">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Student Fee Details</b></h3>
                
                {/* <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divSessionYear" href="javascript:void(0)">Add New</a> */}
              </div>
              <div class="card-body">
              <table className="table table-bordered table-sm">
                <tr class="white" style={{"background-color":"#FC766AFF"}}>
                  <td>Year Session</td>
                  <td>Net Payable</td>
                  <td>Fee Paid</td>
                  <td>Fee Due</td>
                  <td></td>
                </tr>
                {this.state.stStudentInvoice.map((item,index)=>{
                  let vFeePaid=0;
                  if(item["paid_fee"]!==null && item["paid_fee"]!==""){
                    vFeePaid=parseFloat(item["paid_fee"]);
                  }
                  return <tr>
                  <td>{item["session_name"]}</td>
                  <td>Rs.{item["net_amount"]}</td>
                  <td>Rs.{vFeePaid}</td>
                  <td>Rs.<b>{parseFloat(item["net_amount"]-vFeePaid)}</b></td>
                  <td><a onClick={()=>this.getInvoiceBreakups(item)} data-toggle="modal" data-target="#divViewInvoiceBreakup" href={"javascript:void(0)"}>View Details</a></td>
                </tr>
                })}
                
              </table>
                </div>          
     </div> 

     <div class="card card-secondary">
    <div class="card-header">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Payment Submitted for Session Year</b></h3>
                
                {/* <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divSessionYear" href="javascript:void(0)">Add New</a> */}
              </div>
              <div class="card-body">
              <table className="table table-bordered table-sm">
                <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                  <td>Date</td>
                  <td>Paid Amount</td>
                  <td></td>
                </tr>
                {this.state.stStuFeeSubmitted.map((item,index)=>{
                  //if item[""]
                  return <tr>
                  <td>{item["fmt_date"]}</td>
                  <td>{item["value"]}</td>
                  <td><a onClick={()=>this.getPaymentEntryBreakups(item)} data-toggle="modal" data-target="#divViewPayBreakup" href={"javascript:void(0)"}>{"View Details"}</a></td>
                </tr>
                })}
                
              </table>
                </div>          
     </div> 

     <div class="card card-secondary">
    <div class="card-header">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Old Dues</b></h3>
                
               <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divPayOldDues" href="javascript:void(0)">Pay Old Dues</a> 
              </div>
              <div class="card-body">
              <table className="table table-bordered table-sm">
                <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                  <td>Date</td>
                  <td>Trans</td>
                  <td>Amount</td>
                  {/* <td></td> */}
                </tr>
                {this.state.stStuOldDues.map((item,index)=>{
                  //if item[""]
                  return <tr>
                  <td>{item["fmt_date"]}</td>
                  <td>{item["type"]}</td>
                  <td>{item["value"]}</td>
                  {/* <td><a onClick={()=>this.getPaymentEntryBreakups(item)} data-toggle="modal" data-target="#divViewPayBreakup" href={"javascript:void(0)"}>{"View Details"}</a></td> */}
                </tr>
                })}
                
              </table>
                </div>          
     </div> 


    </div>
      <div className="col-md-8 col-sm-12">
         
      <div class="card card-secondary">
              {/* <!-- /.card-header --> */}
           <div class="card-header">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Fee Submission</b></h3>
                
                {/* <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divSessionYear" href="javascript:void(0)">Add New</a> */}
              </div>
            {/* form data  */}
            <form class="form-group" id="frmFeeSubmission" name="frmFeeSubmission" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                <input type="hidden" name="res_func" id="res_func" value="okSuccStuFee" />
                <input type="hidden" name="curl_type" id="curl_type" value="submitStuFee" />
                <input type="hidden" name="m_type" id="m_type" value="submitStuFee" />
                <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
            <div class="card-body">           
            <div className="row">
            

            <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Session Year</label>
                                            <div className="input-group input-group-sm">
                                            <select onChange={()=>this.getStudentByAutoComplete()} id="session_year_id" name="session_year_id" className="form-control required">
                                                  <option value={btoa("0")}>-Select Year-</option>
                                                  {this.state.stListSessionYear.map((item,index)=>{
                                                    return <option value={btoa(item.id)}>{item.session_name}</option>
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Class</label>
                                            <div className="input-group input-group-sm">
                                            <select onChange={()=>this.getStudentByAutoComplete()} id="class_id" name="class_id" className="form-control required">
                                                  <option value={btoa("0")}>-Select Class-</option>
                                                  {this.state.stListClassMaster.map((item,index)=>{
                                                    return <option value={btoa(item.id)}>{item.class_name}</option>
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Student Name</label>
                                            <div className="input-group input-group-sm">
                                              <select onChange={()=>this.getStudentFeenDues()} id="ser_student_name" name="ser_student_name" className="form-control select2 form-control-sm">
                                                  <option value={"0"}>-Select Student</option>
                                                  {this.state.stListStudent.map((item,index)=>{
                                                    return <option value={btoa(item.id)}>{item.student_name}</option>
                                                  })}
                                              </select>
                                            
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <TableDatePicker refName="date" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Receipt No.</label>
                                            <div className="input-group input-group-sm">
                                            <input className="form-control required" type="number" id="receipt_no" name="receipt_no"/>
                                            </div>
                                        </div>
                                  </div>

            </div>
{/* end of first row */}
{/* table fee Breakdown */}
<div class="row">
<table class="table table-bordered table-sm">
                                        <thead>
                                            <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                <th>Fee Category</th>
                                                <th>Installment Amount</th>
                                                <th>No of Installment</th>
                                                <th>Total Amount</th>
                                                <th style={{"width":"40px"}}></th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <select id="fee_category_name" name="fee_category_name" val_memeber="fee_category_id" class="form-control piMember miMember">
                                                            <option value="">Select</option>
                                                          {this.state.stListFeeCategory.map((item,index)=>{
                                                            return <option value={item["id"]}>{item["category_name"]}</option>
                                                          })}
                                                        </select>
                                                    </div>
                                                </td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="install_amount" name="install_amount" required_mg="Installment Amount Required" relate_control="no_installment" output_to="total_amount"  class="form-control cd_input_change piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="no_installment" name="no_installment" required_mg="No of Installment Required" relate_control="install_amount" output_to="total_amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                
                                                
                                                
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <input type="text" readOnly={"true"} id="total_amount" name="total_amount" class="form-control piMember miMember" />
                                                        <input type="hidden" id="fee_category_id" name="fee_category_id" class="piHidMember miMember" value="0" />
                                                    </div>
                                                </td>
                                                <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miMember" cldname="piMember" cldhidname="piHidMember" resValue="p_feeCategoryList" resname="tbFeeCatList" id="_btnFeeBreakdown" value="add" /></td>
                                            </tr>
                                        </thead>
                                        <tbody id="tbFeeCatList">
                                        </tbody>
                                    </table>
                                    <input type="hidden" id="fctmpt" name="fctmpt" value="0" />
                                    <input type="hidden" id="p_feeCategoryList" name="p_feeCategoryList" value="" />
                                </div>
                                <div class="row">
                                    <div class="col-md-9">
                                    </div>
                                    <div class="col-md-3">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr style={{"background-color":"#FC766AFF"}}>
                                                    
                                                    <th>Total Payable</th>
                                                    
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm"><input id="net_amount" readOnly={"true"} rfnode="value" name="net_amount" type="text" class="form-control" /></div></td>
                                                </tr>
                                            </thead>
                                        
                                        </table>
                                        
                                    </div>
                                </div>

            </div>
            <div class="row">
                <div class="col-9"></div>
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" class="btn btn-sm btn-danger _btnSetColor">Submit Fee</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
            </form>
            {/* end of form data */}
                
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_View_Payment_Breakup paymentBreakdown={this.state.stStuPaymentBreakdown}/>
<Comp_View_Invoice_Breakup invoiceBreakdown={this.state.stStuInvoiceBreakdown} />
<Pay_Old_Dues studentId={this.state.stStudentId} stuOldDues={this.state.stStuOldDues}/>
    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Submit_Fee;