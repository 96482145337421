import $, { extend } from 'jquery';
import React,{useEffect,useState} from 'react';
import {TableDatePicker,ParseDate} from '../function_lib/common_lib';
import {Comp_Auto_Complete,CompItem_Auto_Complete} from '../components/comp_auto_complete';
import listData from '../function_lib/customeData';
import DatePicker from "react-datepicker";

//import {Comp_Auto_Complete,CompSite_Auto_Complete} from '../components/comp_auto_complete';

export function Comp_Edit_Fuel_In(props){
  
  const [date, setDate] = useState(null);
  // const [pItemList,setPItemList]=useState("");
  // const [pPaymentList,setPPaymentList]=useState("");
 
  
  useEffect(()=>{
    if(typeof props.clsParentListData==="undefined"){
      return;
  }
  if(props.lsInvoice.length>0){
    setDate(ParseDate(props.lsInvoice[0]["date"]));

    $("#edit_inv_amount").val(props.lsInvoice[0]["Amount"]);
    $("#edit_inv_net_amount").val(props.lsInvoice[0]["Net Amount"]);

    $("#edit_inv_discount_per").val(props.lsInvoice[0]["discount_per"]);
    $("#edit_inv_discount").val(props.lsInvoice[0]["Discount"]);
  }
 
  //$("#date").val(props.lsInvoice[0]["date"]);
  
  // alert("i m in use effect");
  const clsEditProdList =props.clsParentListData;
  /* Set Invoice */

    //const clsEditProdList =new listData("_btnFeeBreakdown", "hellow");;
    
    clsEditProdList.purchaseItem.tt=[];
    for(let i=0;i<props.lsPurchaseProduct.length;i++){
      let exListItems={};
      //alert(JSON.stringify(props.lsPurchaseProduct));
      exListItems["ex_date"]=props.lsPurchaseProduct[i]["Date"];
      exListItems["ser_sitename"]=props.lsPurchaseProduct[i]["site_name"]
      exListItems["col1"]=props.lsPurchaseProduct[i]["col1"];
      exListItems["auto_item_name"]=props.lsPurchaseProduct[i]["Item"];
      exListItems["ser_unit_id"]=props.lsPurchaseProduct[i]["Item Unit"];
      exListItems["quantity"]=props.lsPurchaseProduct[i]["Quantity"];
      exListItems["rate"]=props.lsPurchaseProduct[i]["Rate"];
      exListItems["amount"]=props.lsPurchaseProduct[i]["Sub Total"];
      exListItems["net_amount"]=props.lsPurchaseProduct[i]["Amount"];
      exListItems["bags"]=props.lsPurchaseProduct[i]["Bags"];
      exListItems["package"]=props.lsPurchaseProduct[i]["Pack"];
      exListItems["col2"]=props.lsPurchaseProduct[i]["col2"]; //royalty no
      
      exListItems["exp"]=props.lsPurchaseProduct[i]["exp"];
      exListItems["mfg"]=props.lsPurchaseProduct[i]["mfg"];
      exListItems["make"]=props.lsPurchaseProduct[i]["make"];
      exListItems["batch"]=props.lsPurchaseProduct[i]["batch"];
      
      //hidden fields
      exListItems["item_id"]=btoa(props.lsPurchaseProduct[i]["item_id"]);
      exListItems["unit_id"]=btoa(props.lsPurchaseProduct[i]["unit_id"]);
      exListItems["site_id"]=btoa(props.lsPurchaseProduct[i]["site_id"]);
      exListItems["reference_invoice_id"]=btoa(props.lsPurchaseProduct[i]["purchase_item_id"]);
      //exListItems["ex_invoice_id"]=btoa(props.lsPurchaseProduct[i]["invoice_id"]);

      clsEditProdList.purchaseItem.tt.push(exListItems);
    }
    //setPItemList(JSON.stringify(clsEditProdList.purchaseItem));
    //alert(JSON.stringify(pItemList));
    clsEditProdList.setExistingData();

    clsEditProdList.preEventSetData=function setTotalAmount(){
      //return;
      let totalAmount=0;
      for(let i=0;i<clsEditProdList.purchaseItem.tt.length;i++){
        totalAmount+=parseFloat(clsEditProdList.purchaseItem.tt[i]["amount"]);
      }
      //alert(totalAmount);
      document.getElementById("edit_inv_amount").value=totalAmount;
      let vDiscountPer=0;
      let vDiscountAmt=0;
      if(document.getElementById("edit_inv_discount_per")!==null){
        if(document.getElementById("edit_inv_discount_per").value!==""){
            vDiscountPer=parseFloat(document.getElementById("edit_inv_discount_per").value);
        }
      }
      if(vDiscountPer!==0){
        vDiscountAmt=(totalAmount*vDiscountPer)/100;
        document.getElementById("edit_inv_discount").value=vDiscountAmt;
      } 
      document.getElementById("edit_inv_net_amount").value=totalAmount-vDiscountAmt;
  }

    clsEditProdList.preEventSetData();  


    // check payment Entries
    if(typeof props.clsParentPaymentButton==="undefined"){
      return;
    }

    const clsPaymentEntry=props.clsParentPaymentButton;
    //const clsPaymentEntry=new listData("_btnPaymentBreakdown","hellow");
    clsPaymentEntry.purchaseItem.tt=[];
    for(let p=0;p<props.lsPayment.length;p++){
      //alert(JSON.stringify(props.lsPayment));
      let exPayItems={};
      exPayItems["payment_mode"]=props.lsPayment[p]["payment_mode"];
      exPayItems["pay_value"]=props.lsPayment[p]["value"];
      exPayItems["pay_narration"]=props.lsPayment[p]["narration"];
      exPayItems["edit_pay_id"]=btoa(props.lsPayment[p]["id"]);
      clsPaymentEntry.purchaseItem.tt.push(exPayItems);
    }
    //setPPaymentList(JSON.stringify(clsPaymentEntry.purchaseItem));
    clsPaymentEntry.setExistingData();

    
  },[props.lsInvoice])
  //alert(JSON.stringify(props.lsInvoice));
    return(<div className="modal fade bd-example-modal-xl"  id="divEditFuelIn" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">{props.compTitle}</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmEditFuelIn" name="frmEditFuelIn">
       
        <div className="modal-body">
        
                  {/* <!-- left column --> */}
                      <div className="card card-danger">
                      <input type="hidden" name="res_func" id="res_func" value={btoa("okSuccEditInv")} />
                <input type="hidden" name="curl_type" id="curl_type" value={btoa("edit_invoice_led")} />
                <input type="hidden" name="m_type" id="m_type" value={btoa("edit_invoice_led")} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.logUser[0].app_user_id}/>
                <input type="hidden" name="year_id" id="year_id" value={btoa("1")}/>
                <input type="hidden" name="edit_invoice_id" id="edit_invoice_id" value={props.lsInvoice.length>0?btoa(props.lsInvoice[0]["invoice_id"]):'0'}/>
                <input type="hidden" name="edit_invoice_ledger_id" id="edit_invoice_ledger_id" value={props.lsInvoice.length>0?btoa(props.lsInvoice[0]["invoice_type_id"]):'0'}/>

                              <div className="card-body">
                               
                               <div class="row">
                                {/* actutal fields */}
                                {props.lsInvoice.length>0?<>
                               <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <DatePicker className="form-control form-control-sm" dateFormat="dd/MM/yyyy" id={"edit_date"} name={"edit_date"}   format='dd/MM/yyyy' selected={date} onChange={date =>setDate(date)} />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Customer Name</label>
                                            <div className="input-group input-group-sm">
                                            <input type="text" name="ser_edit_party_info" id="ser_edit_party_info" value={props.lsInvoice[0]["Company Name"]} readOnly={"true"} class="form-control"/>
                                              {/* <Comp_Auto_Complete comp_id={"ser_edit_party_info"} comp_name={"ser_edit_party_info"} target_obj={"ser_party_info_id"} propDefaultValue={props.lsInvoice[0]["Company Name"]} autoList={[]} placeHolder={"Customer Name"} propClass={"form-control form-control-sm required"} /> */}
                                              {/* <input type="text" id="ser_party_info" name="ser_party_info" 
                                              class="form-control _setSearchKey" target_obj="party_info_id"  placeholder="Customer Name" autoComplete='off' 
                                              /> */}
                                              <input class="required" type="hidden" id="ser_party_info_id" name="ser_party_info_id" defaultValue={btoa(props.lsInvoice[0]["party_info_id"])}  />
                                            
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Contact No</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="edit_contact_no" id="edit_contact_no" value={props.lsInvoice[0]["Contact No"]} readOnly={"true"} class="form-control"/>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Receipt/Challan No</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="edit_invoice_no" id="edit_invoice_no" value={props.lsInvoice[0]["Invoice No"]} readOnly={"true"} class="form-control"/>
                                            </div>
                                        </div>
                                  </div>
                                  </>:''}

                        <div className="col-md-12">
                        <table class="table table-bordered table-sm">
                                        <thead>
                                        <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                <th>Item</th>
                                                <th>Unit</th>
                                                <th>Quantity</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                                {/* <th>Disc(%)</th> */}
                                                {/* <th>Net Amount</th> */}
                                                <th style={{"width":"40px"}}></th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                    <CompItem_Auto_Complete  comp_id={"auto_item_name"} comp_name={"auto_item_name"} target_obj={"item_id"} autoList={[]} placeHolder={"Product/Item"} propClass={"form-control form-control-sm piMember miMember"} />
                                                    </div>
                                                </td>
                                                <td><div class="input-group input-group-sm">
                                                  <select id="ser_unit_id" name="ser_unit_id" val_memeber="unit_id" class="form-control piMember miMember">
                                                    <option value={btoa("1")}>LETER(Ltr)</option>
                                                  </select>
                                                  </div>
                                                  </td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="quantity" name="quantity" required_mg="Quantity Required" relate_control="rate" output_to="amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="rate" name="rate" required_mg="Rate Required" relate_control="quantity" output_to="amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <input type="text" readOnly={"true"} id="amount" name="amount" class="form-control piMember miMember" />
                                                        <input type="hidden" id="item_id" name="item_id" required_mg={"Select Item from list properly"} set_required_focus={"auto_item_name"} class="piHidMember miMember required" value="0" />
                                                        <input type="hidden" id="unit_id" name="unit_id" class="piHidMember miMember" value="0" />
                                                    </div>
                                                </td>
                                                {/* <td><div class="input-group input-group-sm"><input type="text" id="discount_per" name="discount_per" required_mg="Quantity Required" relate_control="amount" output_to="net_amount" class="form-control cd_input_change_perc piMember miMember" /></div></td> */}
                                                {/* <td><div class="input-group input-group-sm"><input readOnly={"true"} type="text" id="net_amount" name="net_amount" required_mg="Quantity Required" relate_control="install_amount" output_to="total_amount" class="form-control cd_input_change piMember miMember" /></div></td> */}

                                                <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miMember" cldname="piMember" cldhidname="piHidMember" resValue="p_ItemList" resname="tbItemList" id="_btnFeeBreakdown" value="add" /></td>
                                            </tr>
                                        </thead>
                                        <tbody id="tbItemList">
                                        </tbody>
                                    </table>
                                    <input type="hidden" id="fctmpt" name="fctmpt" value="0" />
                                    <input type="hidden" id="p_ItemList" name="p_ItemList" />
                        </div>

                        <div class="col-md-2">
                                    </div>
                                    <div class="col-md-10">
                                        <table class="table table-bordered table-sm">
                                        <thead>
                                                <tr style={{"background-color":"#FC766AFF"}}>
                                                    <th>Amount</th>
                                                    <th>Invoice Discount(%)</th>
                                                    <th>Discount Amount</th>
                                                    <th>Invoice Amount</th>
                                                    {/* <th>Amount Received</th> */}
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm"><input id="edit_inv_amount" readOnly={"true"} rfnode="value" name="edit_inv_amount" type="text" class="form-control" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="edit_inv_discount_per" rfnode="value" name="edit_inv_discount_per" tag_jobject={"{\"t_type\":\"discount\",\"t_source\":\"edit_inv_amount\",\"t_per_val\":\"edit_inv_discount\",\"t_result\":\"edit_inv_net_amount\"}"} type="text" class="form-control _keyupcustom" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="edit_inv_discount" readOnly={"true"} rfnode="value" name="edit_inv_discount" type="text" class="form-control" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="edit_inv_net_amount" readOnly={"true"} rfnode="value" name="edit_inv_net_amount" type="text" class="form-control" /></div></td>
                                                    {/* <td><div class="input-group input-group-sm"><input id="inv_amount_received" rfnode="value" name="inv_amount_received" type="text" class="form-control" /></div></td> */}
                                                </tr>
                                            </thead>
                                        
                                        </table>
                                        
                                    </div>



                                    

{/* end of actual fields */}
                               </div>
                               {/* payment entry */}
                               <div class="row">
                               <div class="col-md-5">
                                    </div>
                                    <div class="col-md-7">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                    <th>Payment Mode</th>
                                                    <th>Transaction Amount</th>
                                                    <th>Narration</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm">
                                                      <select id="payment_mode" name="payment_mode" className="form-control piPaymentMember miPaymentMember">
                                                        <option value="0">-Payment Mode-</option>
                                                        <option value={"Cash"}>Cash</option>
                                                        <option value={"Card"}>Card</option>
                                                        <option value={"Cheque"}>Cheque</option>
                                                        <option value={"Netbanking"}>Netbanking</option>
                                                        <option value={"upi"}>UPI</option>
                                                      </select>
                                                      </div></td>
                                                    <td>
                                                    <div class="input-group input-group-sm">
                                                    <input id="pay_value" name="pay_value" type="text" class="form-control piPaymentMember miPaymentMember" />
                                                    <input id="edit_pay_id" name="edit_pay_id" type="hidden" value={"0"}/>

                                                    </div></td>
                                                    <td><div class="input-group input-group-sm"><input id="pay_narration" name="pay_narration" type="text" class="form-control piPaymentMember miPaymentMember" /></div></td>
                                                    <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miPaymentMember" cldname="piPaymentMember" cldhidname="piHidPaymentMember" resValue="p_paymentBreakdown" resname="tbPaymentBreakdown" id="_btnPaymentBreakdown" value="add" /></td>
                                                </tr>
                                            </thead>
                                            <tbody id="tbPaymentBreakdown">
                                            </tbody>
                                        </table>
                                        <input type="hidden" id="p_paymentBreakdown" name="p_paymentBreakdown" />
                                        
                                    </div>
                                    </div>
                                    {/* end of payment entry */}
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                                  
                              </div>
                      </div>
                  
              
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_Edit_Fuel_In;