import $, { extend } from 'jquery';
import React,{useEffect} from 'react';

function exportPdf(pageType) {
    var whe = window.innerHeight;
    var wwid = window.innerWidth;

    let data = document.getElementById("divPrintContent").innerHTML;
    /*
    alert(data);
    var doc = new DOMParser().parseFromString(data, "text/xml");

    alert(doc.getElementsByTagName("<div>")[1]);
    */
    var printWindow = window.open('', '', 'width=' + (wwid - 20) + ',height=' + (whe - 10) + '');
    printWindow.document.write('<html><head><title>Export Data</title>');
    //printWindow.document.write('<link rel="stylesheet" href="~/plugins/fontawesome-free/css/all.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css">');
    //printWindow.document.write('<link rel="stylesheet" href="~/dist/css/adminlte.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700">');
    if(pageType==="1"){
        printWindow.document.write('<style>@page { size: landscape; }table, th, td {border: 0.5px solid;}table{width:100%;border-spacing:0;}</style>');
    }else{
        printWindow.document.write('<style>table, th, td {border: 0.5px solid;}table{width:100%;border-spacing:0;}</style>');
    }
    
    
    printWindow.document.write('</head><body >');
    printWindow.document.write(data);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    //alert(printWindow.document.getElementById("exp_body").innerHTML);
    //printWindow.document.getElementById("exp_body").removeAttribute("style");
    //delete cell from table
    // let tbList = printWindow.document.getElementById("ptrTable");
    // let tbRow = tbList.rows;
    // //alert(tbRow.length);
    // for (let i = 0; i < tbRow[0].cells.length; i++) {
    //     var str = tbRow[0].cells[i].innerHTML;
    //     //alert(str);
    //     if (str.search("Action") != -1) {
    //         for (var j = 0; j < tbRow.length; j++) {

    //             // Deleting the ith cell of each row
    //             tbRow[j].deleteCell(i);
    //         }
    //     }
    // }

    setTimeout(function () {
        printWindow.print();
    }, 500);
    return false;
}
export function Comp_Print_Report(props){
    
    useEffect(()=>{
        // if(document.getElementById("exp_body")!==null){
        // let data = document.getElementById("exp_body").innerHTML;
        // $("#divPrintFContent").html(data);
        // }

    },[props.lsTabelData])
    return(<div className="modal fade bd-example-modal-xl"  id="divPrintReport" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Export Report</h5>
          <a href={"javascript:void(0)"} onClick={()=>exportPdf(props.pageType)} className="btn btn-sm btn-danger float-right">Export/Print</a>

          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
     
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  
                      <div id="divPrintContent" className="card card-danger">
                            

                              <div id={"divPrintFContent"} className="card-body">
                                
                              <table id="ptrTable" class={"table"}>
                                <thead>
                                    <tr>
                                        <td colspan={props.ptrColumnHeader.length+1}>
                                            <center><b>{"Shree Vijay Construction"}</b>
                                            <br></br>
                                            {props.ptrTitle}
                                            <br></br>
                                            {document.getElementById("ser_from_date")!==null?<>
                                            {"Date: "+$("#ser_from_date").val()+"-"+$("#ser_to_date").val()}
                                            </>:''}
                                            
                                            </center>
                                            </td>
                                    </tr>
                                    <tr>
                                        <td>S.No.</td>
                                    {props.ptrColumnHeader.map((item,index)=>{
                                        return <td>{item}</td>
                                    })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.lsTabelData.map((item,index)=>{
                                        return <tr>
                                            <td>{index+1}</td>
                                            {props.ptrColumnName.map((col_name,col_index)=>{
                                                if (typeof props.totals!=="undefined"){
                                                    //alert(props.totals.length);
                                                    for(let t=0;t<props.totals.length;t++){
                                                        //alert(props.totals[t]["col"]);
                                                        if(props.totals[t]["col"]===col_name){
                                                            if(item[col_name]!==""){
                                                                props.totals[t]["total_value"]=parseFloat(props.totals[t]["total_value"])+parseFloat(item[col_name]);
                                                                //alert(props.totals[t]["total_value"]);
                                                            }
                                                            
                                                        }
                                                        
                                                    }
                                                }
                                                return <td>{item[col_name]}</td>
                                            })}
                                        </tr>
                                       
                                    })}
                                    {typeof props.totals!=="undefined"?
                                     <tr>
                                        <td></td>
                                            {props.ptrColumnName.map((t_colname,t_colIndex)=>{
                                                let setP=false;
                                                let setValue="";
                                                
                                                for(let t=0;t<props.totals.length;t++){
                                                    if(props.totals[t]["col"]===t_colname){
                                                        setValue=props.totals[t]["total_value"];
                                                        //alert("in conftiop "+props.totals[t]["col"]);
                                                    }else if(props.totals[t]["col"]==="_substract" && (t_colIndex+1)===parseInt(props.totals[t]["col_index"])){
                                                        //alert(props.totals[t]["col"]+"==="+t_colname);
                                                        setValue=parseFloat(props.totals[t-2]["total_value"])-parseFloat(props.totals[t-1]["total_value"]);
                                                    }
                                                }
                                                return <td><b>{setValue}</b></td>
                                            })}
                                        </tr>:''}
                                </tbody>
                             </table>
                             
{typeof props.lsOutStandingData!=="undefined"?<>
    <div><b>{props.ptrOutStandingTitle}</b></div>
<table  class={"table"}>
    <tr>
    {Object.keys(props.ptrOutStandingCols).map((item,index)=>{
        return <td>{props.ptrOutStandingCols[item]}</td>
    })}
    </tr>
    {props.lsOutStandingData.map((item,index)=>{
        return <tr>
            {Object.keys(props.ptrOutStandingCols).map((col_item,col_index)=>{
                return <td>{item[col_item]}</td>
            })}
        </tr>
    })}
</table>
</>
:''}                                            
                             
                                  
                                  
                              </div>
                      </div>
                  
              </div>
        </div>
       
        
      </div>
      </div>
      </div>)
}

export default Comp_Print_Report;