import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GlobalProvider } from './context';
import './index.css';


ReactDOM.render(
  <GlobalProvider>
    <App /></GlobalProvider>,
  document.getElementById('root')
);