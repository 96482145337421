import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Class_Fee_Master from '../components/comp_class_fee_master';
import Comp_Edit_Student_Basic from '../components/comp_edit_student_basic';
import Comp_Edit_Student_Family from '../components/comp_edit_student_family';
import Comp_Edit_Student_Auth from '../components/comp_edit_student_auth';
import Comp_Print_Report from '../components/comp_print_report';
import Comp_Search_Pan from '../components/comp_search_pan';
import { Link } from 'react-router-dom';

let loginUser="";
let userObject="";
let companyName="";
let clsStudentAdmission;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];
let _sumNetAmount=0;
class View_Product_Stock extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stListTransactions:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsStudentAdmission=this;
      }
      getTruckProWise(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"view_product_stock"});
        map.push({name:"res_func",value:"callBackrrr"});
        //map.push({name:"inventory_ledger_id",value:btoa("2")});
        map.push({name:"outstanding_rpt",value:"1"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});
        //alert("i m in inventory");
        if(document.getElementById("ser_inventory_ledger_id")!==null){
          //alert($("#ser_inventory_ledger_id").val());
          map.push({name:"ser_inventory_ledger_id",value:$("#ser_inventory_ledger_id").val()});
        }

        if(document.getElementById("ser_party_info_id")!==null){
          map.push({name:"ser_party_info_id",value:$("#ser_party_info_id").val()});
        }
        if(document.getElementById("ser_item_id")!==null){
          map.push({name:"ser_item_id",value:$("#ser_item_id").val()});
        }
        if(document.getElementById("ser_from_date")!==null && document.getElementById("ser_to_date")){
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }
        
        // map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        // map.push({name:"ser_to_date",value:$("#ser_to_date").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_product_stock";
        CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
          //alert(data);
          _sumNetAmount=0;
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
               
               
                
                $(".outstanding_rpt").html(listData.length+" records found");
                clsStudentAdmission.setState({stListTransactions:listData},()=>{
                  //window.getPagination("#tbData");
                  clsStudentAdmission.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
  


      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getTruckProWise();
//this.getMasters();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12 pb-2">
    <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        Search
      </button>
    <button class="btn btn-sm btn-danger ml-2"  type="button" data-bs-toggle="modal" data-bs-target="#divPrintReport">
        Export
      </button>
      <Link className="btn btn-sm btn-danger float-right" to={"../ledger/truck_out"}>{"Add New TruckOut"}</Link>
    </div>
  </div>

<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>View Product Stock</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Product Name</th>
                      <th>Product Code</th>
                      <th>Available Stock</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{
                  
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item["item_name"]}</td>
                        <td>{item["item_code"]}</td>  
                        
                        <td><b>{item["full_availability"]}</b> {item["unit_name"]}</td>
                        {/* <td>Rs.{item["Amount"]}</td> */}
                        {/* <td>{""}</td>  */}
                    
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divEditStudentBasic" href="javascript:void(0)">View/Edit</a>
                     <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal"  data-target="#divEditStudentFamily" href="javascript:void(0)">Print</a>
                    </div>
                  </div>
                       </td>
                    </tr>)
                  }))}
                 
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Comp_Print_Report ptrColumnHeader={["Ledger","Product Name","Unit","Total Quantity","Net Amount"]} 
  ptrColumnName={["Item Ledger","Item","Item Unit","Quantity","Amount"]} 
  lsTabelData={this.state.stListTransactions}
  totals={[{"col":"Amount","col_index":5,"total_value":0}]}
  ptrTitle={"Product Wise Outstanding"}
   logUser={userObject}/>
<Alert_Content/>
<Footer/>
<Comp_Search_Pan onSearchFunc={this.getTruckProWise} 
searchTitle={"Search ProductWise Outstanding"}
searchOptions={{"company_name":"true",
 "inventory_ledger":"true",
"item":"true",
//"site_name":"true",
//"vehicle_no":"true",
"payment_mode":"true",
"from_to_date":"true"}}
/>
   
    {/* <Comp_Class_Fee_Master lsSessionYear={this.state.stListSessionYear} lsClassMaster={this.state.stListClassMaster} lsFeeCategory={this.state.stListFeeCategory} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default View_Product_Stock;