import $, { extend } from 'jquery';
import React,{useEffect,useState} from 'react'
import {TableDatePicker,getAppUrl,CallMapMethod_CBack} from '../function_lib/common_lib';
import {Comp_Auto_Complete,CompItem_Auto_Complete,Comp_Auto_Complete_New} from '../components/comp_auto_complete';
import axios from 'axios';
export function Comp_Import_Crushing(props){
    const [impoStList,setImpoStList]=useState([]);
    const [impoDefaultSum,setDefaultSum]=useState(0);
    const [isUseEffect,setUseEffect]=useState(true)

    let impoPartyInfo="0";
    let _impoList=[];
    let _impoSetAmount=0;
  
    useEffect(()=>{
        //alert("i m in use effect "+isUseEffect);
        if(isUseEffect===true){
            setDefaultSum(0);
            setImpoStList([]);
        }
       
    },[isUseEffect]);

    const handleClose=()=>{
        setUseEffect(true);
    }

    const handleClick = async () => {
       



        var map=[];
        map.push({name:"curl_type",value:"view_truck_out_items"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"inventory_ledger_id",value:btoa("8")});
        map.push({name:"local_token",value:props.logUser[0].app_user_id});
        map.push({name:"user_type",value:props.logUser[0].type});
        map.push({name:"ser_party_info_id",value:$("#impo_crush_party_info_id").val()});
        map.push({name:"ser_process_status",value:"done"});
        //map.push({name:"ser_payment_mode",value:"Credit"});
        //map.push({name:"ser_payment_status",value:"due"});
        map.push({name:"ser_ref_invoice_id",value:btoa("0")});
        map.push({name:"ser_from_date",value:$("#import_frmDate").val()});
        map.push({name:"ser_to_date",value:$("#import_toDate").val()});

        if(document.getElementById("ser_item_id")!==null){
          map.push({name:"ser_item_id",value:$("#ser_item_id").val()});
        }
        
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_truck_out_items";

        CallMapMethod_CBack(this,map,function(checkData,msg){
            if(checkData!=="0"){
                //alert(checkData);
                let compListData=JSON.parse(checkData);
                let netAmount=0;
                let vRate=0;
                if($("#impo_rate").val()!==""){
                    vRate=parseFloat($("#impo_rate").val());
                }
                let vTonQty=0;
                
                for(let i=0;i<compListData.length;i++){
                    
                    if(compListData[i]["Rate"]===0){
                        compListData[i]["Rate"]=vRate;
                    }
                    vTonQty=parseFloat(compListData[i]["Quantity"]);
                    compListData[i]["Sub Total"]=(vRate*vTonQty).toFixed(2);
                    compListData[i]["Amount"]=(vRate*vTonQty).toFixed(2);

                    netAmount+=parseFloat(compListData[i]["Amount"]);
                }
                //alert(netAmount);
                //_impoSetAmount=netAmount;
                //_impoList=compListData;
                setUseEffect(false);
                setDefaultSum(netAmount.toFixed(2));
                setImpoStList(compListData);
            }
        })
     

      };
    return(<div className="modal fade bd-example-modal-xl"  id="divImportCrushing" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Transaction Entry</h5>
          <button type="button" className="close text-white" onClick={()=>handleClose()} data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmImportCrushing" name="frmImportCrushing">
       
        <div className="modal-body">
         <div className="row">
            <div class="col-md-3">
            <Comp_Auto_Complete_New comp_id={"impo_crush_party_info"} comp_name={"impo_crush_party_info"} target_obj={"impo_crush_party_info_id"} placeHolder={"Customer Name"} propClass={"form-control1 form-control-sm1"}/>
            </div>
            <div className="col-md-2"><TableDatePicker refName="import_frmDate" /></div>
            <div className="col-md-2"><TableDatePicker refName="import_toDate" /></div>  
            <div class="col-md-2"><input class="form-control form-control-sm" placeHolder={"Import Rate"} type="number" id="impo_rate" name="impo_rate"/> </div>
            <div className="col-md-2"><button type="button" onClick={()=>handleClick()} className="btn btn-sm btn-danger">Search</button></div>
            <div className="col-md-1">
                
                <button type="button" onClick={()=>props.importItemToBill(impoStList)} className="btn btn-sm btn-danger float-right">Import</button>
            </div>
        </div>
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                            
                              <div className="card-body">
                              <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Receipt No.</th>
                      <th>Customer Name</th>
                      <th>RST No</th>
                      <th>Product</th>
                      <th>Unit</th>
                      <th>Gross Wt.(Kg)</th>
                      <th>Tare Wt.(Kg)</th>
                      <th>Net Wt.(Kg)</th>
                      <th>Net Wt.(Ton)</th>
                      <th>Rate</th>
                      <th>Amount</th>
                      <th>Net Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(impoStList.map((item,index)=>{
                   
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item["Date"]}</td>
                        <td>{item["Invoice No"]}</td>
                        <td>{item["Company Name"]}</td>
                        <td>{item["col1"]}</td>
                        <td>{item["Item"]}</td>  
                        <td>{item["Item Unit"]}</td>
                        <td>{item["Bags"]}</td>
                        <td>{item["Pack"]}</td>
                        <td>{item["free_goods"]}</td>
                        <td>{item["Quantity"]}</td>
                        <td>Rs.{item["Rate"]}</td>
                        <td>Rs.{item["Sub Total"]}</td>
                        <td>Rs.{item["Amount"]}</td>
                    </tr>)
                  }))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>{impoDefaultSum}</b></td>
                  </tr>
                  </tbody>
                </table>
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" onClick={()=>props.importItemToBill(impoStList)} className="btn btn-sm btn-danger">Import</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Comp_Import_Crushing;