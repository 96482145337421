import React from 'react'
export function Comp_Add_Product_Master(props){
  //alert(JSON.stringify(props.editProduct));
    return(<div className="modal fade bd-example-modal-xl"  id="divAddProductMaster" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xlll" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Product Master</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmAddProductMaster" name="frmAddProductMaster">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value={btoa("okRespProductAdd")} />
                              <input type="hidden" name="curl_type" id="curl_type" value={btoa("addItemMaster")} />
                              <input type="hidden" name="m_type" id="m_type" value={btoa("addItemMaster")} />
                              <input type="hidden" name="log_id" id="log_id" value={props.logUser[0]["id"]}/>
                              <input type="hidden" name="log_app_id" id="log_app_id" value={props.logUser[0]["app_user_id"]}/>
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                              <input type="hidden" name="edit_product_id" id="edit_product_id" value={typeof props.editProduct["id"]!=="undefined"?btoa(props.editProduct["id"]):"0"}/>
                              <div className="card-body">
                                <table>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Category</label></td>
                                        <td> <select id="item_category_id" name="item_category_id" className="form-control required">
                                                  <option value="0">-Select-</option>
                                                  {props.lsProCategory.map((item,index)=>{
                                                    return <option selected={props.editProduct["item_category_id"]===item.id}  value={btoa(item["id"])}>{item["category_name"]}</option>
                                                  })}
                                                 
                                                    
                                                  </select></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Proudct Unit</label></td>
                                        <td> <select id="unit_id" name="unit_id" className="form-control required">
                                                  <option value="0">-Select-</option>
                                                  {props.lsUnit.map((item,index)=>{
                                                    return <option selected={props.editProduct["unit_id"]===item.id} value={btoa(item["id"])}>{item["name"]}</option>
                                                  })}
                                                  </select></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Proudct Name</label></td>
                                        <td> <input type="text" id="item_name" name="item_name" className="form-control required" placeholder="" defaultValue={props.editProduct["name"]} /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Proudct Code</label></td>
                                        <td> <input type="text" id="item_code" name="item_code" className="form-control required" placeholder="" defaultValue={props.editProduct["Item Code"]} /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Sales Rate</label></td>
                                        <td><input type="number" id="item_rate" name="item_rate" className="form-control required" placeholder="" defaultValue={props.editProduct["Sales Rate"]} /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">Purchase Rate</label></td>
                                        <td><input type="number" id="purchase_rate" name="purchase_rate" className="form-control required" placeholder="" defaultValue={props.editProduct["Purchase Rate"]} /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">GST</label></td>
                                        <td><input type="number" id="item_vat" name="item_vat" className="form-control required12" placeholder="" defaultValue={props.editProduct["GST"]} /></td>
                                    </tr>
                                    <tr>
                                        <td><label for="exampleInputEmail1">HSN</label></td>
                                        <td><input type="text" id="hsn" name="hsn" className="form-control required12" placeholder="" defaultValue={props.editProduct["hsn"]} /></td>
                                    </tr>
                                </table>
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Comp_Add_Product_Master;