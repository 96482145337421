import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,
  validateNumberLength,TableDatePicker,MonthYear} from '../function_lib/common_lib';

import listData from '../function_lib/customeData';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import {Comp_Add_Product_Master} from '../components/comp_add_product_master'
// import {Comp_Add_Party_Info} from '../components/comp_add_party_info';

import {Comp_Add_Salary} from '../ledger_component/comp_add_salary';
import {Comp_Emp_Trans} from '../ledger_component/comp_emp_trans';
import Comp_Print_Report from '../components/comp_print_report';
import {Comp_Salary_Slip} from '../components/comp_salary_slip';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import defaultPic from '../assets/images/9.png';

//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';


let loginUser="";
let userObject="";
let companyName="";
let clsViewEmployee;

let rf="0";

let _objAccess={};
class Salary_Mgmt extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          _objAccess=getAccessFunctions();
      }
      this.state={ listUsers:[],stSetSalaryEmp:{},stListTransactions:[],
      stSalaryInvoice:[],stSalaryTransaction:[],stAdvanceOuts:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsViewEmployee=this;
      }

      

      getSalaryInfo(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"view_emp_salary"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        //map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"ser_month",value:$("#ser_month").val()});
        map.push({name:"ser_year",value:$("#ser_year").val()});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_emp_salary";
        CallMapMethod_CBack(clsViewEmployee,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsViewEmployee.setState({listUsers:listData},()=>{
                  //window.getPagination("#tbData");
                  clsViewEmployee.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsViewEmployee.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
              }
        });
      }
   
      okSuccSalaryInv(data,msg){
        if(data==="001"){
          alert("Salary Generated Successfully");
        }else{
          alert("Unable to generate salary");
        }
      }
      okSuccTransEnt(data,msg){
        if(data==="001"){
          alert("Added Successfully");
        }else{
          alert("Unable to Add");
        }
      }
      okRespCreateUser(data,msg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful",msg,false);
          //clsViewEmployee.getUserInfo();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",msg,false);
        }
        // alert(data);
      }

setAddSalaryComp(item){
  var map=[];
  map.push({name:"curl_type",value:"view_salary_outstanding"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"user_type",value:userObject[0].type});
  //map.push({name:"ser_userType",value:$("#ser_userType").val()});
  map.push({name:"ser_text",value:$("#ser_text").val()});
  map.push({name:"ser_user_info_id",value:btoa(item.user_info_id)});
  map.push({name:"ser_payment_mode",value:"Advance"});
 
  map.push({name:"upper_id",value:userObject[0].upper_id});
  map["res_func"]="callBackrrr";
  map["curl_type"]="view_salary_outstanding";
  CallMapMethod_CBack(clsViewEmployee,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let jsData=JSON.parse(data);
      clsViewEmployee.setState({stAdvanceOuts:jsData});
    }
    clsViewEmployee.setState({stSetSalaryEmp:item});
  })


  
}
viewTransaction(item){
  // alert(JSON.stringify(item));
  // return;
  var map=[];
  map.push({name:"curl_type",value:"view_salary_trans"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"user_type",value:userObject[0].type});
  //map.push({name:"ser_userType",value:$("#ser_userType").val()});
  map.push({name:"ser_text",value:$("#ser_text").val()});
  map.push({name:"ser_user_info_id",value:btoa(item.user_info_id)});
 
  map.push({name:"upper_id",value:userObject[0].upper_id});
  map["res_func"]="callBackrrr";
  map["curl_type"]="view_salary_trans";
  CallMapMethod_CBack(clsViewEmployee,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let jsData=JSON.parse(data);
      clsViewEmployee.setState({stListTransactions:jsData});
    }
  })

}
getSalarySlip(item){
  //alert(item.invoice_id);
  if(item.invoice_id===null || item.invoice_id==="null"){
    alert("Salary not generated, kindly generate salary first to print receipt");
    return;
  }
  var map=[];
  map.push({name:"curl_type",value:"print_salary_slip"});
  map.push({name:"res_func",value:"callBackrrr"});
 
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"user_type",value:userObject[0].type});
  map.push({name:"invoice_id",value:btoa(item.invoice_id)})
  map["res_func"]="callBackrrr";
  map["curl_type"]="print_salary_slip";
  CallMapMethod_CBack(clsViewEmployee,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let jsData=JSON.parse(data);
      clsViewEmployee.setState({stSalaryInvoice:jsData["invoice"],stSalaryTransaction:jsData["salary_trans"]});
    }
  })
  
 }             
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getSalaryInfo();

this.clsBtnPaymentBreadownClick=new listData("_btnPaymentBreakdown","hellow");

$("#divEmpTrans").on('hidden.bs.modal',function(){
  clsViewEmployee.getSalaryInfo();
})
$('#divAddEmployee').on('hidden.bs.modal', function () {
  //alert("i m in hide");
  //clsViewEmployee.setState({stFeeCategory:{"nn":"00"}});
  //clsViewEmployee.forceUpdateHandler();
  clsViewEmployee.getSalaryInfo();
 
});
$('#divAddAccessibility').on('hidden.bs.modal', function () {
  //alert("i m in hide");
  //clsViewEmployee.setState({stFeeCategory:{"nn":"00"}});
  //clsViewEmployee.forceUpdateHandler();
  clsViewEmployee.getSalaryInfo();
 
});
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">

<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan1">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>View Employee Salary</b></h3>
                <div className="row content12 mt11-2 mb11-2">


<div className='col-lg-2 col-md-2 col-sm-2 float-right'>
<div className="form-group">
         <div className="input-group">
               <select className="form-control form-control-sm" id="ser_year" name="ser_year">
                <option value={"0"}>-Select Year-</option>
                <option value={"2024"}>2024</option>
               </select>
             </div>
         </div>

</div>


<div className='col-lg-2 col-md-2 col-sm-2 float-right'>
<div className="form-group">
         <div className="input-group">
               <select className="form-control form-control-sm" id="ser_month" name="ser_month">
                <option value={"0"}>-Select Month-</option>
                <option value={"01"}>Jan</option>
                <option value={"02"}>Feb</option>
                <option value={"03"}>March</option>
                <option value={"04"}>April</option>
                <option value={"05"}>May</option>
                <option value={"06"}>June</option>
                <option value={"07"}>July</option>
                <option value={"08"}>Aug</option>
                <option value={"09"}>Sept</option>
                <option value={"10"}>Oct</option>
                <option value={"11"}>Nov</option>
                <option value={"12"}>Dec</option>
               </select>
             </div>
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getSalaryInfo()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>window.location.reload()} className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
<div class="col-md-6">
  <a className="btn btn-sm btn-danger float-right"  data-bs-toggle="modal" data-bs-target="#divEmpTrans" data-toggle="modal" data-target="#divEmpTrans" href="javascript:void(0)">Create Emp Transactions</a>
</div>
    </div>    
                
                
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Emp Id</th>
                      <th>Emp Name</th>
                      <th>Emp Contact No</th>
                      <th>Month/Year</th>
                      <th>Gen.Date</th>
                      <th>Rec.No.</th>
                      <th>Tota Working Days</th>
                      <th>Unpaid Leave</th>
                      <th>Paid Leave</th>
                      <th>Net Payable Salary</th>
                      <th>Salary Status</th>
                      {/* <th>Trans.Date</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.listUsers.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                  <td>{item["A/c No"]}</td>      
                  <td>{item["Emp Name"]}</td>
                  <td>{item["Contact No"]}</td>
                  <td>{item["date"]!==null?MonthYear(item["date"]):''}</td>
                  <td>{item["date"]}</td>
                  <td>{item["Invoice No"]}</td>
                  <td>{item["Ref No"]!==null?item["Ref No"]+" Days":''}</td>
                  <td>{item["Extra Charge"]!==null?item["Extra Charge"]+" Days":''}</td>
                  <td>{item["No of Items"]!==null?item["No of Items"]+" Days":''}</td>
                  <td><b>Rs.{item["Amount"]}</b></td>
                  <td>{item["payment_status"]}</td>
                  {/* <td>{item.status}</td> */}
                  {/* <td>{item.is_active}</td> */}
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    {typeof _objAccess.can_edit!=="undefined" && item["Invoice No"]===null?<a class="dropdown-item" onClick={()=>this.setAddSalaryComp(item)} data-bs-toggle="modal" data-bs-target="#divAddSalary" data-toggle="modal" data-target="#divAddSalary" href="javascript:void(0)">Generate/Pay</a>:''}
                    {typeof _objAccess.can_user_access!=="undefined"?<a class="dropdown-item" onClick={()=>this.getSalarySlip(item)} data-bs-toggle="modal" data-bs-target="#divPrintSalarySlip" data-toggle="modal"  data-target="#divPrintSalarySlip" href="javascript:void(0)">Print Salary Slip</a>:''}
                    {typeof _objAccess.can_user_access!=="undefined"?<a class="dropdown-item" onClick={()=>this.viewTransaction(item)} data-bs-toggle="modal" data-bs-target="#divPrintReport" data-toggle="modal"  data-target="#divPrintReport" href="javascript:void(0)">View Transaction</a>:''}
                    
                    </div>
                  </div>
    </td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

<Comp_Salary_Slip ptrSalaryInvoice={this.state.stSalaryInvoice} 
  ptrSalaryTransaction={this.state.stSalaryTransaction}
  ptrTitle="PaySlip"

  logUser={userObject}
 />
<Comp_Emp_Trans
dataAccounts={{}}
compTitle={"Emp Transaction Entry"}
logUser={userObject}
/>


<Comp_Print_Report ptrColumnHeader={["EmpId","Emp Name","Sal.Date","Ledger","Receipt No","Credit","Debit","Balance","Status"]} 
  ptrColumnName={["A/c No","Emp Name","Date","Inventory Ledger","Invoice No","Credit","Debit","","payment_status"]} 
  lsTabelData={this.state.stListTransactions}
  
  totals={[{"col":"Credit","col_index":6,"total_value":0},
    {"col":"Debit","col_index":7,"total_value":0},
    {"col":"_substract","col_index":8,"total_value":0}
  ]}

  ptrTitle={"Employee Salary Transaction"}
   logUser={userObject}
   
   />
<Comp_Add_Salary 
getSalaryEmp={this.state.stSetSalaryEmp} 
compTitle={"Generate Salary"} 
logUser={userObject} 
lsInvoice={[]}
lsAdvanceOuts={this.state.stAdvanceOuts}
clsParentPaymentButton={this.clsBtnPaymentBreadownClick}
/>

    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Salary_Mgmt;