import $, { extend } from 'jquery';
import React,{useEffect} from 'react';

function exportPdf() {
    var whe = window.innerHeight;
    var wwid = window.innerWidth;

    let data = document.getElementById("divPrintSalaryContent").innerHTML;
    /*
    alert(data);
    var doc = new DOMParser().parseFromString(data, "text/xml");

    alert(doc.getElementsByTagName("<div>")[1]);
    */
    var printWindow = window.open('', '', 'width=' + (wwid - 20) + ',height=' + (whe - 10) + '');
    printWindow.document.write('<html><head><title>Export Data</title>');
    //printWindow.document.write('<link rel="stylesheet" href="~/plugins/fontawesome-free/css/all.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700">');
    printWindow.document.write('<style>table, th, td {border: 0.5px solid;}table{width:100%;border-spacing:0,border:1px solid #000;}</style>');
    printWindow.document.write('<style>table{ border: 2px solid #000;}</style>');

    
    printWindow.document.write('</head><body >');
    printWindow.document.write(data);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    //alert(printWindow.document.getElementById("exp_body").innerHTML);
    //printWindow.document.getElementById("exp_body").removeAttribute("style");
    //delete cell from table
    // let tbList = printWindow.document.getElementById("ptrTable");
    // let tbRow = tbList.rows;
    // //alert(tbRow.length);
    // for (let i = 0; i < tbRow[0].cells.length; i++) {
    //     var str = tbRow[0].cells[i].innerHTML;
    //     //alert(str);
    //     if (str.search("Action") != -1) {
    //         for (var j = 0; j < tbRow.length; j++) {

    //             // Deleting the ith cell of each row
    //             tbRow[j].deleteCell(i);
    //         }
    //     }
    // }

    setTimeout(function () {
        printWindow.print();
    }, 500);
    return false;
}
export function Comp_Salary_Slip(props){
   // alert(JSON.stringify(props.lsOldDue));
    useEffect(()=>{
        // if(document.getElementById("exp_body")!==null){
        // let data = document.getElementById("exp_body").innerHTML;
        // $("#divPrintFContent").html(data);
        // }

    },[props.lsTabelData])
    return(<div className="modal fade bd-example-modal-xl"  id="divPrintSalarySlip" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xlll" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel"></h5>
          <a href={"javascript:void(0)"} onClick={()=>exportPdf()} className="btn btn-sm btn-danger float-right">Print</a>

          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
     
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  
                      <div id="divPrintSalaryContent" className="card card-danger">
                            

                              <div id={"divPrintSalaryFContent"} className="card-body">
                                
                              <table id="ptrTable" class={"table"}>
                                <thead>
                                    <tr>
                                        <td colspan={"2"}>
                                            <div class="row">
                                                <center>
                                            <div class="col-md-12" style={{"width":"50%"}}>

                                            <b></b><br></br>
                                            <b>{"Shree Vijay Construction"}</b>
                                            <br></br>
                                            Ujjain -456010<br></br>
                                            Mob. 9174004521, 9174004527<br></br>
                                            <b>{props.ptrTitle}</b>
                                            <br></br>
                                            </div>
                                            </center>
                                           

                                           

                                            </div>
                                            {props.ptrSalaryInvoice.map((item,index)=>{
                                            return <div class="row" style={{"border-top":"1px solid #000","padding-top":""}}>
                                                <div class="col-md-6" style={{"width":"50%"}}><b>Receipt No:</b>{item["Invoice No"]+"/"+item["Invoice Type"]}</div>
                                                
                                                <div class="col-md-6" style={{"width":"50%","text-align":"right"}}><b>Receipt Date:{item["date"]}</b></div>
                                            </div>
                                            })}
                                        </td>
                                    </tr>
                                 
                                   
                                </thead>
                                <tbody>
                                
                                    {props.ptrSalaryInvoice.length>0?<>
                                        <tr>
                                        <td>Emp Id</td>
                                        <td>{props.ptrSalaryInvoice[0]["A/c No"]}</td>
                                        </tr>
                                        <tr>
                                        <td>Emp Name</td>
                                        <td>{props.ptrSalaryInvoice[0]["Emp Name"]}</td>
                                        </tr>
                                        <tr>
                                        <td>Total Working Days</td>
                                        <td>{props.ptrSalaryInvoice[0]["Ref No"]}</td>
                                        </tr>
                                        <tr>
                                        <td>Unpaid Leaves</td>
                                        <td>{props.ptrSalaryInvoice[0]["Extra Charge"]}</td>
                                        </tr>
                                        <tr>
                                        <td>Paid Leaves</td>
                                        <td>{props.ptrSalaryInvoice[0]["No of Items"]}</td>
                                        </tr>
                                        <tr>
                                        <td>Salary Amt.</td>
                                        <td>Rs.{props.ptrSalaryInvoice[0]["Amount"]}</td>
                                        </tr>
                                        <tr>
                                        <td>Salary Status.</td>
                                        <td>{props.ptrSalaryInvoice[0]["payment_status"]}</td>
                                        </tr>
                                        <tr><td colspan={"2"}>This is computer generated salary slip,seal and sign required if submitted for compliances</td></tr>
                                    </>:''}
                                </tbody>
                             </table>
                                  
                                  
                              </div>
                      </div>
                  
              </div>
        </div>
       
        
      </div>
      </div>
      </div>)
}

export default Comp_Salary_Slip;