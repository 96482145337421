import $, { extend } from 'jquery';
import React,{useEffect} from 'react'
import {CompItem_Auto_Complete,CompVehicle_Auto_Complete,CompSite_Auto_Complete,
    CompItem_Auto_Complete_New,CompSite_Auto_Complete_New
} from '../components/comp_auto_complete';
import {TableDatePicker,ParseDate} from '../function_lib/common_lib';
export function Comp_Add_Trans_Item(props){

    if(typeof props.editPopup!=="undefined"){
        //alert(JSON.stringify(props.editPopup));
    }
    useEffect(()=>{

    },[props.editPopup]);
    //alert("i m in compup trans");
    return(<div className="modal fade bd-example-modal-xl"  id="divAddTransItem" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xlll" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Transaction Entry</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmAddTransItem" name="frmAddTransItem">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value={btoa("okRespCreateParty")} />
                              <input type="hidden" name="curl_type" id="curl_type" value={btoa("addPartyInfo")} />
                              <input type="hidden" name="m_type" id="m_type" value={btoa("addPartyInfo")} />
                              <input type="hidden" name="log_id" id="log_id" value={props.logUser[0]["id"]}/>
                              <input type="hidden" name="log_app_id" id="log_app_id" value={props.logUser[0]["app_user_id"]}/>
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                              <input type="hidden" name="category_id" id="category_id" value={btoa("1")}/>
                              {typeof props.editPopup["ex_date"]==="undefined"?
                              <input type="hidden" id="ex_date" name="ex_date" class="piMember miMember" value={typeof props.editPopup["ex_date"]!=="undefined"?props.editPopup["ex_date"]:$("#date").val()}/>:''
}

                              
                              
                              <div className="card-body">
                              <table class="table table-bordered table-sm">
                                        <thead>
                                            <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                
                                                
                                            </tr>
                                         {typeof props.editPopup["ex_date"]!=="undefined"?
                                            <tr>
                                                <td>Trans. Date</td>
                                                <TableDatePicker refName={"ex_date"} propClass={"piMember miMember"} refDate={props.editPopup["ex_date"]}/>
                                            </tr>:''}
                                            <tr>
                                                <td>Site Name</td>
                                                <td><div class="input-group1 input-group-sm1">
                                                    <CompSite_Auto_Complete_New comp_sel_text={"auto_sitename"} comp_id={"sel_auto_sitename"} defaultLabel={props.editPopup["auto_sitename"]} defaultValue={props.editPopup["site_id"]} comp_name={"ser_auto_sitename"} target_obj={"site_id"} autoList={[]} placeHolder={"Site"} propClassHid={"piHidMember miMember"} propClass={"form-control1 form-control-sm1 piMember miMember"} />
                                                    </div>
                                             {/* <input type="hidden" id="site_id" name="site_id" class="form-control piHidMember miMember"/> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>RST No</td>
                                                <td><input className="form-control required piMember miMember" defaultValue={props.editPopup["col1"]} type="number" id="col1" name="col1"/></td>
                                            </tr>
                                            <tr>
                                            <td>Item</td>
                                                <td>
                                                    <div class="input-group1 input-group-sm1">
                                                    <CompItem_Auto_Complete_New comp_sel_text={"auto_item_name"}  comp_id={"sel_auto_item_name"} comp_name={"sel_auto_item_name"} defaultLabel={props.editPopup["auto_item_name"]} defaultValue={props.editPopup["item_id"]} target_obj={"item_id"} autoList={[]} placeHolder={"Product/Item"} propClassHid={"piHidMember miMember"} propClass={"form-control1 form-control-sm1 piMember miMember"} />
                                                    {/* <input type="text" id="ser_item_id" name="ser_item_id" target_obj="item_id"
                                              class="form-control _setSearchKey piMember miMember" placeholder="Products" autoComplete='off' 
                                              /> */}
                                                    </div>
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td>Unit</td>
                                                <td><div class="input-group input-group-sm">
                                                  <select id="ser_unit_id" name="ser_unit_id" val_memeber="unit_id" class="form-control-sm piMember miMember required">
                                                    <option selected={props.editPopup["unit_id"]===btoa("2")} value={btoa("2")}>METER</option>
                                                    <option selected={props.editPopup["unit_id"]===btoa("3")} value={btoa("3")}>TON</option>
                                                  </select>
                                                  </div>
                                                  </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Quantity</td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="quantity" defaultValue={props.editPopup["quantity"]} name="quantity" required_mg="Quantity Required" relate_control="rate" output_to="amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Rate</td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="rate" name="rate" defaultValue={props.editPopup["rate"]} required_mg="Rate Required" relate_control="quantity" output_to="amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Amount</td>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <input type="text" readOnly={"true"} id="amount" name="amount" defaultValue={props.editPopup["amount"]} class="form-control piMember miMember" />
                                                        {/* <input type="hidden" id="item_id" name="item_id" required_mg={"Select Item properly"} defaultValue={props.editPopup["item_id"]} set_required_focus={"auto_item_name"} class="piHidMember miMember required" value={""} /> */}
                                                        <input type="hidden" id="unit_id" name="unit_id" defaultValue={props.editPopup["unit_id"]} class="piHidMember miMember" />
                                                        <input type="hidden" id="reference_invoice_id" name="reference_invoice_id" class="piHidMember miMember" value={btoa("0")}/>
                                                        <input type="hidden" id="ex_purchase_item_id" name="ex_purchase_item_id" class="piHidMember miMember" defaultValue={props.editPopup["ex_purchase_item_id"]}/>
                                                        <input type="hidden" id="ex_invoice_id" name="ex_invoice_id" value={btoa("0")}/>
                                                    </div>
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td>Royalty No</td>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <input type="text" id="col2" name="col2" defaultValue={props.editPopup["col2"]} class="form-control piMember miMember" />
                                                    </div>
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td>Royalty Rate</td>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <input type="text" id="bags" name="bags" defaultValue={props.editPopup["bags"]} relate_control="quantity" output_to="package" class="form-control cd_input_change piMember miMember" />
                                                    </div>
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td>Royalty Amt</td>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <input type="text" id="package" name="package" defaultValue={props.editPopup["package"]} relate_control="amount" output_to="net_amount" class="form-control cd_input_change_sum piMember miMember" />
                                                    </div>
                                                </td>
                                                </tr>
                                                {/* <tr>
                                                    <td>Disc(%)</td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="discount_per" name="discount_per" required_mg="Quantity Required" relate_control="amount" output_to="net_amount" class="form-control cd_input_change_perc piMember miMember" /></div></td>
                                                </tr> */}
                                                <tr>
                                                    <td>Net Amount</td>
                                                <td><div class="input-group input-group-sm"><input readOnly={"true"} type="text" id="net_amount" name="net_amount" defaultValue={props.editPopup["net_amount"]} required_mg="Quantity Required" relate_control="install_amount" output_to="total_amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Vehicle No</td>
                                                    <td><div class="input-group input-group-sm">
                                                    <CompVehicle_Auto_Complete comp_id={"exp"} comp_name={"exp"} defaultValue={props.editPopup["exp"]} target_obj={"vehicle_id"} autoList={[]} placeHolder={"Vehicle No"} propClass={"form-control form-control-sm piMember miMember"} />
                                                        <input type="hidden" id="vehicle_id" name="vehicle_id" />
                                                    </div></td>
                                                </tr>
                                                <tr>
                                                    <td>Vehicle Type</td>
                                                    <td><div class="input-group input-group-sm"><input type="text" id="mfg" name="mfg" defaultValue={props.editPopup["mfg"]}  class="form-control piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Driver Name</td>
                                                    <td><div class="input-group input-group-sm"><input type="text" id="make" name="make" defaultValue={props.editPopup["make"]} required_mg1="Driver Name Required"  class="form-control piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Driver Contact</td>
                                                    <td><div class="input-group input-group-sm"><input type="text" id="batch" name="batch" defaultValue={props.editPopup["batch"]} required_mg1="Driver Contact Required"  class="form-control piMember miMember" /></div></td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miMember" cldname="piMember" cldhidname="piHidMember" resValue="p_ItemList" resname="tbItemList" id="_btnFeeBreakdown" value="add" /></td>
                                            </tr>
                                        </thead>
                                       
                                    </table>
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        {/* <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
                                            */}
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Comp_Add_Trans_Item;