import React,{useEffect} from 'react';
import {TableDatePicker} from '../function_lib/common_lib';
import {Comp_Auto_Complete,CompItem_Auto_Complete,
  CompSite_Auto_Complete,CompVehicle_Auto_Complete,
  Comp_Inventory_Ledger,
  Comp_Auto_Complete_New,CompItem_Auto_Complete_New,
  CompSite_Auto_Complete_New,
  Comp_Inventory_Ledger_New
} from '../components/comp_auto_complete';
// import { GlobalContext } from '../context';
// import { Offcanvas } from 'bootstrap';

export function Comp_Search_Pan(props){
  let searchOptions={};
  if (typeof props.searchOptions!=="undefined"){
    searchOptions=props.searchOptions;
  }
  useEffect(()=>{

  },[props.searchOptions])
  //props.searchOptions=searchOptions;
  
  //alert(JSON.stringify(gbStPartyInfo));
    return (
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">{props.searchTitle}</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
        <div className="row content12 mt-2 mb-2">
{/* for now [row]it is unused */}
{/* starting of cols */}

{typeof searchOptions["company_name"]!=="undefined" && searchOptions["company_name"]!=="false"?
<div className='col-md-12 float-right'>
<div className="form-group">
         <div className="input-group1">
          <Comp_Auto_Complete_New comp_id={"ser_party_info"} comp_name={"ser_party_info"} target_obj={"ser_party_info_id"} placeHolder={"Customer Name"} propClass={"form-control1 form-control-sm1"}/>
          {/* <Comp_Auto_Complete comp_id={"ser_party_info"} comp_name={"ser_party_info"} target_obj={"ser_party_info_id"} placeHolder={"Customer Name"} propClass={"form-control form-control-sm"} /> */}
                                              {/* <input type="text" id="ser_party_info" name="ser_party_info" 
                                              class="form-control _setSearchKey" target_obj="party_info_id"  placeholder="Customer Name" autoComplete='off' 
                                              /> */}
                                              {/* <input type="hidden" id="ser_party_info_id" name="ser_party_info_id" value="" /> */}
               
             </div>
         
         </div>
</div>
:""
}
{typeof searchOptions["inventory_ledger"]!=="undefined" && searchOptions["inventory_ledger"]!=="false"?
<div className='col-md-12 float-right'>
<div className="form-group">
         <div className="input-group1">
          {/* <label>Customer</label> */}
          <Comp_Inventory_Ledger_New comp_id={"ser_inventory_ledger"} comp_name={"ser_inventory_ledger"} target_obj={"ser_inventory_ledger_id"} placeHolder={"Ledger"} propClass={"form-control1 form-control-sm1"} />
                                              {/* <input type="text" id="ser_party_info" name="ser_party_info" 
                                              class="form-control _setSearchKey" target_obj="party_info_id"  placeholder="Customer Name" autoComplete='off' 
                                              /> */}
                                              {/* <input type="hidden" id="ser_party_info_id" name="ser_party_info_id" value="" /> */}
               
             </div>
         
         </div>
</div>
:''
}

{typeof searchOptions["item"]!=="undefined" && searchOptions["item"]!=="false"?
<div className='col-md-12 float-right'>
<div className="form-group">
         <div className="input-group1">
         {/* <label>Product</label> */}
         <CompItem_Auto_Complete_New  comp_id={"ser_item_name"} comp_name={"ser_item_name"} target_obj={"ser_item_id"} autoList={[]} placeHolder={"Product/Item"} propClass={"form-control1 form-control-sm1"} />
               {/* <input type="hidden" class="form-control" id="ser_item_id" name="ser_item_id"/> */}
             </div>
         </div>
         
</div>
:''
}
{typeof searchOptions["site_name"]!=="undefined" && searchOptions["site_name"]!=="false"?
<div className='col-md-12 float-right'>
<div className="form-group">
         <div className="input-group1">
         {/* <label>Site</label> */}
         <CompSite_Auto_Complete_New comp_id={"ser_sitename"} comp_name={"ser_sitename"} target_obj={"ser_site_id"} placeHolder={"Site Name"} propClass={"form-control1 form-control-sm1"} />
                                              {/* <input type="hidden" id="ser_site_id" name="ser_site_id" value="" /> */}
               
             </div>
         </div>
</div>
:""
}
{typeof searchOptions["vehicle_no"]!=="undefined" && searchOptions["vehicle_no"]!=="false"?
<div className='col-md-12 float-right'>
<div className="form-group">
         <div className="input-group">
         {/* <label>Vehicle</label> */}
         <CompVehicle_Auto_Complete comp_id={"ser_vehicle"} comp_name={"ser_vehicle"} target_obj={"ser_vehicle_id"} placeHolder={"Vehicle No"} propClass={"form-control form-control-sm"} />
              <input type="hidden" id="ser_vehicle_id" name="ser_vehicle_id" value="" />
             </div>
         </div>
</div>
:""
}

{typeof searchOptions["payment_mode"]!=="undefined" && searchOptions["payment_mode"]!=="false"?
<div className='col-md-12 float-right'>
<div className="form-group">
         <div className="input-group">
         {/* <label>Vehicle</label> */}
               <select id="ser_payment_mode" name="ser_payment_mode" class="form-control">
                <option value={"0"}>-Trans Type-</option>
                <option value={"Credit"}>-Credit-</option>
                <option value={"Cash"}>-Cash-</option>
               </select>
             </div>
         
         </div>
</div>
:""
}
{typeof searchOptions["bill_nonbill"]!=="undefined" && searchOptions["bill_nonbll"]!=="false"?
  <div className='col-md-12 float-right'>
<div className="form-group">
         <div className="input-group">
         {/* <label>Vehicle</label> */}
               <select id="ser_bill_nonbill" name="ser_bill_nonbill" class="form-control">
                <option value={"0"}>-Bill/NonBill-</option>
                <option value={"billed"}>-Billed-</option>
                <option value={"nonbilled"}>-Non Billed-</option>
               </select>
             </div>
         
         </div>
</div>:''
}
{typeof searchOptions["trans_payment_mode"]!=="undefined" && searchOptions["trans_payment_mode"]!=="false"?
  <div className='col-md-12 float-right'>
<div className="form-group">
         <div className="input-group">
         {/* <label>Vehicle</label> */}
               <select id="ser_transpaymentmode" name="ser_transpaymentmode" class="form-control">
                <option value={"0"}>-Payment Mode-</option>
                <option value={"Cash"}>Cash</option>
                                                <option value={"Card"}>Card</option>
                                                <option value={"Cheque"}>Cheque</option>
                                                <option value={"Netbanking"}>Netbanking</option>
                                                <option value={"UPI"}>UPI</option>
               </select>
             </div>
         
         </div>
</div>:''
}

{typeof searchOptions["from_to_date"]!=="undefined" && searchOptions["from_to_date"]!=="false"?
<div className='col-md-12 float-right'>
<div className="form-group">
  <label>From and To</label>
         <div className="input-group">
         <TableDatePicker refName="ser_from_date" />-To-
         <TableDatePicker refName="ser_to_date"/>
             </div>
         
         </div>
</div>
:""
}

{/* 
<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div> */}

<div className="col-md-12 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div   style={{marginRight:"10px"}} onClick={()=>props.onSearchFunc("")} className="btn btn-sm btn-danger">Search</div>
                 <div  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>
</div>
{/* end of cols */}

    </div>    
        </div>
      </div>

    );

}
export default Comp_Search_Pan;