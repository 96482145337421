import {React,useEffect} from 'react'


export function Comp_Stu_Invoice_Breakdown(props){
    //alert(JSON.stringify(props.lsInvoiceBreakups));
    useEffect(() => {
        if(typeof props.childBtnBreakups==="undefined"){
            return;
        }
        let clsStuInvBreak=props.childBtnBreakups;
        clsStuInvBreak.setOnChangeEvent("fee_breakdown",clsStuInvBreak);

        clsStuInvBreak.preEventSetData=function setTotalAmount(){
           // let totalAmount=parseFloat(document.getElementById("inv_amount").value);
           let totalAmount=0;
            for(let i=0;i<clsStuInvBreak.purchaseItem.tt.length;i++){
                if(clsStuInvBreak.purchaseItem.tt[i]["add_deduct"].trim()==="+"){
                    totalAmount+=parseFloat(clsStuInvBreak.purchaseItem.tt[i]["net_amount"]);
                }else{
                    totalAmount-=parseFloat(clsStuInvBreak.purchaseItem.tt[i]["net_amount"]);
                }
                
                
            }
            document.getElementById("net_payable_amount").value=totalAmount;
        }

        if(clsStuInvBreak!==null){
            for(let i=0;i<props.lsInvoiceBreakups.length;i++){
                let exFeeBreakups = {};
                exFeeBreakups["accounts_ledger_name"] = props.lsInvoiceBreakups[i]["ledger_name"];
                exFeeBreakups["accounts_ledger_id"]=props.lsInvoiceBreakups[i]["accounts_ledger_id"];
                exFeeBreakups["add_deduct"]=props.lsInvoiceBreakups[i]["add_deduct"];
                exFeeBreakups["amount"]=props.lsInvoiceBreakups[i]["amount"];
                exFeeBreakups["per_slab"]=props.lsInvoiceBreakups[i]["per_slab"];
                exFeeBreakups["net_amount"]=props.lsInvoiceBreakups[i]["net_amount"];
                exFeeBreakups["id"]=props.lsInvoiceBreakups[i]["id"];
                clsStuInvBreak.purchaseItem.tt.push(exFeeBreakups);    
            }
           
            clsStuInvBreak.setExistingData();
            //clsStuInvBreak.preEventSetData();
        }
       
      }, [props.lsInvoiceBreakups]);
//alert(JSON.stringify(props.lsInvoiceBreakups));
    //   check other data
   

    //alert(JSON.stringify(props.lsItem));
    return(<div className="modal fade bd-example-modal-xl"  id="divStuInvBreakdown" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Student Fee Allotment</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        {/* form */}
        <div class="">
    
    {/* <style>
        .ui-menu{
            z-index:9999 !important;
        }
    </style> */}
    <section class="content">
        <div class="container-fluid">
            <form class="form-group" id="frmStuInvBreakdown" name="frmStuInvBreakdown">
                {props.insertFeeMode===true?<div>
                <input type="hidden" name="res_func" id="res_func" value="fee_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="addInvoiceBreakUps" />
                <input type="hidden" name="m_type" id="m_type" value="addInvoiceBreakUps" />
                </div>:<div>
                <input type="hidden" name="res_func" id="res_func" value="fee_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="addInvoiceBreakUps" />
                <input type="hidden" name="m_type" id="m_type" value="addInvoiceBreakUps" />
                    </div>}
                <input type="hidden" name="e_stu_invoice_id" id="e_stu_invoice_id" value={btoa(props.lsItem.stu_invoice_id)}/>
                
              

              
                <div class="row">
                    {/* <!-- left column --> */}
                    <div class="col-md-12">
                        <div class="card card-danger">

                            <div class="card-body">
                            <div class="row">
                            <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Student Name</label>
                                            <div class="input-group input-group-sm">
                                                    {props.lsItem.student_name}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Form No.</label>
                                            <div class="input-group input-group-sm">
                                                    {props.lsItem.form_no}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Scholar No.</label>
                                            <div class="input-group input-group-sm">
                                                    {props.lsItem.scholar_no}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Session Year</label>
                                            <div class="input-group input-group-sm">
                                                    <select id="session_year_id" name="session_year_id" class="form-control select2" style={{"width":"100%"}}>
                                                    <option selected value="0">-Select-</option>
                                                        {props.lsSessionYear.map((item,index)=>{
                                                            return <option selected={props.lsItem.session_year_id===item["id"]} value={item["id"]}>{item["session_name"]}</option>
                                                        })}
                                                        
                                                    </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Class Name</label>
                                            <div class="input-group input-group-sm">
                                                    <select id="class_id" name="class_id" class="form-control select2" style={{"width":"100%"}}>
                                                        <option value="0">-Select-</option>
                                                        {props.lsClassMaster.map((item,index)=>{
                                                            return <option selected={props.lsItem.class_id===item["id"]} value={item["id"]}>{item["class_name"]}</option>
                                                        })}
                                                    </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Class Fee</label>
                                            <div class="input-group input-group-sm">
                                                    <b>{props.lsItem.inv_amount}</b>
                                                    <input type="hidden" id="inv_amount" name="inv_amount" value={props.lsItem.inv_amount}/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                                <div class="row">
                                    <table class="table table-bordered table-sm">
                                        <thead>
                                            <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                <th>Accounts Ledger</th>
                                                <th>Add/Deduct</th>
                                                <th>Inst. Amount</th>
                                                <th>No of Installment</th>
                                                <th>Net Amount</th>
                                                <th style={{"width":"40px"}}></th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <select id="accounts_ledger_name" name="accounts_ledger_name" val_memeber="accounts_ledger_id" class="form-control piMember miMember">
                                                            <option value="">Select</option>
                                                          {props.lsFeeCategory.map((item,index)=>{
                                                            return <option value={item["id"]}>{item["category_name"]}</option>
                                                          })}
                                                        </select>
                                                        <input type="hidden" id="accounts_ledger_id" name="accounts_ledger_id" class="piHidMember miMember" value="0" />
                                                        <input type="hidden" id="id" name="id" class="piHidMember miMember" value="0" />
                                                    </div>
                                                </td>
                                                <td><div class="input-group input-group-sm">
                                                    
                                                    <select id="add_deduct" name="add_deduct" val_memeber11="add_deduct" class="form-control piMember miMember">
                                                        <option value={"0"}>-Add/Deduct-</option>
                                                        <option value={"+"}>Add</option>
                                                        <option value={"-"}>Deduct</option>
                                                    </select>
                                                    {/* <input type="hidden" id="add_deduct" name="add_deduct" class="piHidMember miMember" value="0" /> */}
                                                </div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="amount" name="amount" relate_control="per_slab" output_to="net_amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="per_slab" name="per_slab" relate_control="amount" output_to="net_amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                
                                                <td><div class="input-group input-group-sm"><input type="text" id="net_amount" name="net_amount" required_mg="Net amount required" class="form-control cd_input_change11 piMember miMember" /></div></td>
                                               
                                                <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miMember" cldname="piMember" cldhidname="piHidMember" resValue="p_feeCategoryList" resname="tbFeeCatList" id="_btnInvoiceBreakdown" value="add" /></td>
                                            </tr>
                                        </thead>
                                        <tbody id="tbFeeCatList">
                                        </tbody>
                                    </table>
                                    <input type="hidden" id="fctmpt" name="fctmpt" value="0" />
                                    <input type="hidden" id="p_feeCategoryList" name="p_feeCategoryList" value="" />
                                </div>
                                <div class="row">
                                    <div class="col-md-9">
                                    </div>
                                    <div class="col-md-3">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr style={{"background-color":"#FC766AFF"}}>
                                                    
                                                    <th>Net Payable</th>
                                                    
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm"><input id="net_payable_amount" readOnly={"true"} rfnode="value" name="net_payable_amount" type="text" class="form-control" defaultValue={props.lsItem.inv_net_amount} /></div></td>
                                                </tr>
                                            </thead>
                                        
                                        </table>
                                        
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" class="btn btn-primary _btnSetColor">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
                {/* <!-- /.card-body --> */}
            </form>
        </div>
    </section>
</div>

        {/* end of form */}
      </div>
      </div>
      </div>)
}

export default Comp_Stu_Invoice_Breakdown;