import React,{useEffect,useState} from 'react';
import {CallMapMethod_CBack,getAppUrl} from '../function_lib/common_lib';
export function Comp_View_Ent_Logs(props){
  const [getEntLogs,setEntLogs]=useState([]);
  useEffect(()=>{
    if(typeof props.logState.table_id!=="undefined"){
      let map=[];
      map.push({name:"curl_type",value:"getEntLogs"});
      map.push({name:"res_func",value:"callBackrrr"});
      map.push({name:"inventory_ledger_id",value:btoa("2")});
      map.push({name:"local_token",value:props.logUser[0].app_user_id});
      map.push({name:"user_type",value:props.logUser[0].type});
      map.push({name:"tmp_id",value:btoa(props.logState.table_id)});
      map.push({name:"tmp_name",value:btoa(props.logState.table_name)});
      map["res_func"]="callBackrrr";
      map["curl_type"]="getEntLogs";
      CallMapMethod_CBack(this,map,function(data,msg){
        //alert(data);
        if(data!=="0"){
          setEntLogs(JSON.parse(data));
        }
      });
    }

  },[props.logState])
  
    return(<div className="modal fade bd-example-modal-xl"  id="divViewEntryLogs" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xlll" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">View Logs</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmViewEntryLogs" name="frmViewEntryLogs">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value={btoa("okRespCreateParty")} />
                              <input type="hidden" name="curl_type" id="curl_type" value={btoa("addPartyInfo")} />
                              <input type="hidden" name="m_type" id="m_type" value={btoa("addPartyInfo")} />
                              <input type="hidden" name="log_id" id="log_id" value={props.logUser[0]["id"]}/>
                              <input type="hidden" name="log_app_id" id="log_app_id" value={props.logUser[0]["app_user_id"]}/>
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                             
                              <div className="card-body">
                                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                                  <tr>
                                    <td>Log Type</td>
                                    <td>Emp Name</td>
                                    <td>Emp Code</td>
                                    <td>Transaction Date</td>
                                  </tr>
                                  {getEntLogs.map((item,index)=>{
                                    return <tr>
                                      <td>{item["log_type"]}</td>
                                      <td>{item["contact_person"]}</td>
                                      <td>{item["account_no"]}</td>
                                      <td>{item["trans_date"]}</td>
                                    </tr>
                                  })}
                                   
                                </table>
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          {/* <button type="button" className="btn _btnSetColor btn-primary">Save</button> */}
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Comp_View_Ent_Logs;