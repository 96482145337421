import $, { extend } from 'jquery';
import React,{useEffect,useState} from 'react';
import {Comp_Auto_Complete,CompItem_Auto_Complete,CompVehicle_Auto_Complete} from '../components/comp_auto_complete';
import {TableDatePicker,ParseDate} from '../function_lib/common_lib';

import listData from '../function_lib/customeData';
import DatePicker from "react-datepicker";

//import {Comp_Auto_Complete,CompSite_Auto_Complete} from '../components/comp_auto_complete';

export function Comp_Edit_Crushing(props){
  
  const [date, setDate] = useState(null);
  // const [pItemList,setPItemList]=useState("");
  // const [pPaymentList,setPPaymentList]=useState("");
 
  
  useEffect(()=>{
    if(typeof props.clsParentListData==="undefined"){
      return;
  }
  if(props.lsInvoice.length>0){
    setDate(ParseDate(props.lsInvoice[0]["date"]));
  }
 
  //$("#date").val(props.lsInvoice[0]["date"]);
  
  // alert("i m in use effect");
  const clsEditProdList =props.clsParentListData;
    //const clsEditProdList =new listData("_btnFeeBreakdown", "hellow");;
    //alert(props.lsPurchaseProduct.length);
    clsEditProdList.purchaseItem.tt=[];
    for(let i=0;i<props.lsPurchaseProduct.length;i++){
      let exListItems={};
      //alert(JSON.stringify(props.lsPurchaseProduct));
      exListItems["ex_date"]=props.lsPurchaseProduct[i]["Date"];
      exListItems["ser_sitename"]=props.lsPurchaseProduct[i]["site_name"]
      exListItems["col1"]=props.lsPurchaseProduct[i]["col1"];
      exListItems["auto_item_name"]=props.lsPurchaseProduct[i]["Item"];
      exListItems["ser_unit_id"]=props.lsPurchaseProduct[i]["Item Unit"];
      exListItems["quantity"]=props.lsPurchaseProduct[i]["Quantity"];
      exListItems["rate"]=props.lsPurchaseProduct[i]["Rate"];
      exListItems["amount"]=props.lsPurchaseProduct[i]["Sub Total"];
      exListItems["net_amount"]=props.lsPurchaseProduct[i]["Amount"];
      exListItems["bags"]=props.lsPurchaseProduct[i]["Bags"];
      exListItems["package"]=props.lsPurchaseProduct[i]["Pack"];
      exListItems["col2"]=props.lsPurchaseProduct[i]["col2"]; //royalty no
      
      exListItems["exp"]=props.lsPurchaseProduct[i]["exp"];
      exListItems["mfg"]=props.lsPurchaseProduct[i]["mfg"];
      exListItems["make"]=props.lsPurchaseProduct[i]["make"];
      exListItems["batch"]=props.lsPurchaseProduct[i]["batch"];
      exListItems["free_goods"]=props.lsPurchaseProduct[i]["free_goods"];
      
      //hidden fields
      exListItems["item_id"]=btoa(props.lsPurchaseProduct[i]["item_id"]);
      exListItems["unit_id"]=btoa(props.lsPurchaseProduct[i]["unit_id"]);
      exListItems["site_id"]=btoa(props.lsPurchaseProduct[i]["site_id"]);
      exListItems["reference_invoice_id"]=btoa(props.lsPurchaseProduct[i]["purchase_item_id"]);
      //exListItems["ex_invoice_id"]=btoa(props.lsPurchaseProduct[i]["invoice_id"]);

      clsEditProdList.purchaseItem.tt.push(exListItems);
    }
    //setPItemList(JSON.stringify(clsEditProdList.purchaseItem));
    //alert(JSON.stringify(pItemList));
    clsEditProdList.setExistingData();

    
  },[props.lsInvoice])
  //alert(JSON.stringify(props.lsPurchaseProduct));
    return(<div className="modal fade bd-example-modal-xl"  id="divEditCrushing" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">{props.compTitle}</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmEditCrushing" name="frmEditCrushing">
       
        <div className="modal-body">
        
                  {/* <!-- left column --> */}
                      <div className="card card-danger">
                      <input type="hidden" name="res_func" id="res_func" value={btoa("okSuccEditInv")} />
                <input type="hidden" name="curl_type" id="curl_type" value={btoa("edit_invoice_led")} />
                <input type="hidden" name="m_type" id="m_type" value={btoa("edit_invoice_led")} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.logUser[0].app_user_id}/>
                <input type="hidden" name="year_id" id="year_id" value={btoa("1")}/>
                <input type="hidden" name="edit_invoice_id" id="edit_invoice_id" value={props.lsInvoice.length>0?btoa(props.lsInvoice[0]["invoice_id"]):'0'}/>
                <input type="hidden" name="edit_invoice_ledger_id" id="edit_invoice_ledger_id" value={props.lsInvoice.length>0?btoa(props.lsInvoice[0]["invoice_type_id"]):'0'}/>

                              <div className="card-body">
                              
                               <div class="row">
                               {props.lsInvoice.length>0?<>
                                {/* actutal fields */}
                               <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <DatePicker className="form-control form-control-sm" dateFormat="dd/MM/yyyy" id={"edit_date"} name={"edit_date"}   format='dd/MM/yyyy' selected={date} onChange={date =>setDate(date)} />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Customer Name</label>
                                            <div className="input-group input-group-sm">
                                            <input type="text" name="ser_edit_party_info" id="ser_edit_party_info" value={props.lsInvoice[0]["Company Name"]} readOnly={"true"} class="form-control"/>
                                              {/* <Comp_Auto_Complete comp_id={"ser_edit_party_info"} comp_name={"ser_edit_party_info"} target_obj={"ser_party_info_id"} propDefaultValue={props.lsInvoice[0]["Company Name"]} autoList={[]} placeHolder={"Customer Name"} propClass={"form-control form-control-sm required"} /> */}
                                              {/* <input type="text" id="ser_party_info" name="ser_party_info" 
                                              class="form-control _setSearchKey" target_obj="party_info_id"  placeholder="Customer Name" autoComplete='off' 
                                              /> */}
                                              <input class="required" type="hidden" id="ser_party_info_id" name="ser_party_info_id" defaultValue={btoa(props.lsInvoice[0]["party_info_id"])}  />
                                            
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Contact No</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="edit_contact_no" id="edit_contact_no" value={props.lsInvoice[0]["Contact No"]} readOnly={"true"} class="form-control"/>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Receipt/Challan No</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="edit_invoice_no" id="edit_invoice_no" value={props.lsInvoice[0]["Invoice No"]} readOnly={"true"} class="form-control"/>
                                            </div>
                                        </div>
                                  </div>
                        </>:''}
                        <div className="col-md-12">
                        <table class="table table-bordered table-sm">
                                        <thead>
                                        <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                        <th>Item</th>
                                                <th>Unit</th>
                                                <th>RST No.</th>
                                                <th>Gross Weight(Ton)</th>
                                                <th>Tare Weight(Ton)</th>
                                                <th>Net Weight(Meter)</th>
                                                <th>Net Weight(Ton)</th>
                                                <th>Vehicle No</th>
                                                {/* <th>Net Amount</th> */}
                                                <th>Vehicle Details</th>
                                                <th style={{"width":"40px"}}>
                                                  {/* <a type="button" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#divAddBlastingItem" data-target="#divAddBlastingItem" data-toggle="modal" class="btn btn-sm btn-danger float-right" id="popAddItem" value="add">Add</a> */}
                                                  </th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                    <CompItem_Auto_Complete  comp_id={"auto_item_name"} comp_name={"auto_item_name"} target_obj={"item_id"} autoList={[]} placeHolder={"Product/Item"} propClass={"form-control form-control-sm piMember miMember"} />
                                                    </div>
                                                </td>
                                                <td><div class="input-group input-group-sm">
                                                  <select id="ser_unit_id" name="ser_unit_id" val_memeber="unit_id" class="form-control piMember miMember">
                                                    <option value={btoa("3")}>TON</option>
                                                  </select>
                                                  </div>
                                                  </td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="col1" name="col1" required_mg="RST No. Required"  class="form-control  piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="bags" name="bags" required_mg="Gross Weight (Ton) Required" relate_control="package" output_to="free_goods" class="form-control cd_input_change_sub piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="package" name="package" required_mg="Tyre Weight Required" relate_control="bags" output_to="free_goods" class="form-control cd_input_change_sub piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="free_goods" name="free_goods" required_mg="Net weight Required" output_to="quantity" class="form-control cd_input_change_meter_ton piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="quantity" name="quantity" required_mg="Quantity Required" class="form-control cd_input_change11 piMember miMember" /></div></td>
                                                {/* <td><div class="input-group input-group-sm"><input type="text" id="rate" name="rate" required_mg="Rate Required" relate_control="quantity" output_to="amount" class="form-control cd_input_change piMember miMember" /></div></td> */}
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                    {/* <input type="text" id="exp" name="exp" class="form-control piMember miMember" /> */}
                                                    <CompVehicle_Auto_Complete comp_id={"exp"} comp_name={"exp"} target_obj={"vehicle_id"} autoList={[]} placeHolder={"Vehicle No"} propClass={"form-control form-control-sm piMember miMember"} />
                                                        <input type="hidden" id="vehicle_id" name="vehicle_id" />

                                                        <input type="hidden" readOnly={"true"} id="amount" name="amount" class="form-control piHidMember miMember" value={"0"} />
                                                        <input readOnly={"true"} type="hidden" id="net_amount" name="net_amount" relate_control="install_amount" output_to="total_amount" class="form-control piHidMember miMember" value={"0"} />
                                                        <input type="hidden" id="item_id" name="item_id" required_mg={"Select Item from list properly"} set_required_focus={"auto_item_name"} class="piHidMember miMember required11" value={""} />
                                                        <input type="hidden" id="unit_id" name="unit_id" class="piHidMember miMember" value="0" />
                                                    </div>
                                                </td>
                                                <td><div class="input-group input-group-sm">
                                                  <input type="text" id="mfg" name="mfg" class="form-control piMember miMember" />
                                                 
                                                  </div></td>
                                                {/* <td><div class="input-group input-group-sm"></div></td> */}

                                                <td>
                                                  <input type="button" class="btn btn-block btn-info btn-sm" clmname="miMember" cldname="piMember" cldhidname="piHidMember" resValue="p_ItemList" resname="tbItemList" id="_btnFeeBreakdown" value="add" />
                                                  </td>
                                            </tr>
                                          
                                        </thead>
                                        <tbody id="tbItemList">
                                        </tbody>
                                    </table>
                                    <input type="hidden" id="fctmpt" name="fctmpt" value="0" />
                                    <input type="hidden" id="p_ItemList" name="p_ItemList" />
                        </div>

                        


                                    

{/* end of actual fields */}
                               </div>
                               {/* payment entry */}
                                    {/* end of payment entry */}
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                                  
                              </div>
                      </div>
                  
              
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_Edit_Crushing;