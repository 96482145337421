import $, { extend } from 'jquery';
import React,{useEffect,useState} from 'react';
import {TableDatePicker,ParseDate} from '../function_lib/common_lib';
import {Comp_Auto_Complete,CompItem_Auto_Complete,Comp_Inventory_Ledger,
    Comp_Inventory_Ledger_New
} from '../components/comp_auto_complete';
import listData from '../function_lib/customeData';
import DatePicker from "react-datepicker";

//import {Comp_Auto_Complete,CompSite_Auto_Complete} from '../components/comp_auto_complete';

export const Comp_Edit_Account=(props,{dataAccounts})=>{
  
  const [date, setDate] = useState(null);
  const [stDataAccounts,setStDataAccounts]=useState({});

  useEffect(()=>{
   //alert(JSON.stringify(props.dataAccounts));
  if (typeof props.dataAccounts["Date"]!=="undefined"){
    //alert("i m in data");
   
    setDate(ParseDate(props.dataAccounts["Date"]));
  }
  setStDataAccounts(props.dataAccounts);
  },[props.dataAccounts])
  //alert(JSON.stringify(props.dataAccounts));
    return(<div className="modal fade bd-example-modal-xl"  id="divEditAccount" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">{props.compTitle}</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmEditAccount" name="frmEditAccount">
       
        <div className="modal-body">
        
                  {/* <!-- left column --> */}
                      <div className="card card-danger">
                      <input type="hidden" name="res_func" id="res_func" value={btoa("okSuccEntryLedg")} />
                <input type="hidden" name="curl_type" id="curl_type" value={btoa("edit_payments")} />
                <input type="hidden" name="m_type" id="m_type" value={btoa("edit_payments")} />
                <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.logUser[0].app_user_id}/>
                <input type="hidden" name="year_id" id="year_id" value={btoa("1")}/>
                <input type="hidden" name="edit_id" id="edit_id" value={typeof stDataAccounts["account_inventory_id"]!=="undefined"?btoa(stDataAccounts["account_inventory_id"]):"0"} />

                              <div className="card-body">
                               
                               <div class="row">
                                {/* actutal fields */}
                                {typeof stDataAccounts["party_info_id"]!=="undefined"?<>
                               <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                          {/* {stDataAccounts["Company Name"]} */}
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <DatePicker className="form-control form-control-sm" dateFormat="dd/MM/yyyy" id={"edit_date"} name={"edit_date"}   format='dd/MM/yyyy' selected={date} onChange={date =>setDate(date)} />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">A/c Ledger</label>
                                            <div className="input-group1 input-group-sm">
                                            <Comp_Inventory_Ledger_New comp_id={"edit_inventory_ledger"} comp_name={"edit_inventory_ledger"} target_obj={"edit_inventory_ledger_id"} placeHolder={"A/c Ledger"} defaultSelected={{id:stDataAccounts["inventory_ledger_id"],name:stDataAccounts["Inventory Ledger"]}} propClass={"form-control1 form-control-sm1 required"}/>
                                            </div>
                                        </div>

                </div>

                <div className="col-lg-3 col-md-3">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Customer Name</label>
                                            <div className="input-group input-group-sm">
                                            <input type="text" id={"edit_party_info"} name={"edit_party_info"} target_obj={"party_info_id"} class="form-control form-control-sm" defaultValue={stDataAccounts["Company Name"]}/>
                                             
                                              <input type="hidden" id="edit_party_info_id" name="edit_party_info_id" defaultValue={btoa(stDataAccounts["party_info_id"])}/>
                                            
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Contact No</label>
                                            <div className="input-group input-group-sm">
                                             <input readOnly={"true"} type="text" name="edit_contact_no" id="edit_contact_no" class="form-control" defaultValue={stDataAccounts["Contact No"]}/>
                                            </div>
                                        </div>
                </div>                   

                <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Trans.Type</label>
                                            <div className="input-group input-group-sm">
                                             <select id="edit_type" name="edit_type" class="form-control form-control-sm">
                                                <option selected={stDataAccounts["Type"]==="Credit"} value={"Credit"}>Credit</option>
                                                <option selected={stDataAccounts["Type"]==="Debit"} value={"Debit"}>Debit</option>
                   </select>
                                            </div>
                                        </div>
                </div>
                <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Tans. Amount</label>
                                            <div className="input-group input-group-sm">
                                            <input className="form-control required" type="number" id="edit_value" name="edit_value" defaultValue={stDataAccounts["Value"]}/>
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Tans. Mode</label>
                                            <div className="input-group input-group-sm">
                                            <select id="edit_payment_mode" name="edit_payment_mode" class="form-control form-control-sm">
                                                <option  value={"0"}>Select</option>
                                                <option selected={stDataAccounts["Payment_mode"]==="Cash"} value={"Cash"}>Cash</option>
                                                <option selected={stDataAccounts["Payment_mode"]==="Card"} value={"Card"}>Card</option>
                                                <option selected={stDataAccounts["Payment_mode"]==="Cheque"} value={"Cheque"}>Cheque</option>
                                                <option selected={stDataAccounts["Payment_mode"]==="Netbanking"} value={"Netbanking"}>Netbanking</option>
                                                <option selected={stDataAccounts["Payment_mode"]==="UPI"} value={"UPI"}>UPI</option>

                                            </select>
                                            </div>
                                        </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                        <div className="form-group form-group-sm row">
                        <label for="exampleInputEmail1">Narration</label>
                        <div className="input-group input-group-sm">
                            <textarea id="edit_narration" name="edit_narration" class="form-control form-control-sm">
                                    {stDataAccounts["Narration"]}
                            </textarea>
                            </div>
                            </div>
                        </div>              
                                </>:''} 

                               </div>
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                                  
                              </div>
                      </div>
                  
              
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_Edit_Account;